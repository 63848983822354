import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Genesis from './Components/Genesis';
import ROTA from './Components/ROTA';
import Report from './Components/Report';
import Submitted from './Components/Submitted';
import Tickets from './Components/Tickets';
import Admin from './Components/Admin';

import "./App.css"

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/genesis" element={<Genesis />} />
          <Route path="/rota" element={<ROTA />} />
          <Route path="/report" element={<Report />} />
          <Route path="/submitted" element={<Submitted />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="*" element={<Navigate to="/genesis" replace />} />
        </Routes>
      </BrowserRouter>
  );
}
export default App;