import '../App.css';

import {
  useEffect,
  useState,
  useRef
} from 'react';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';
import {
  ethers
} from 'ethers';

import {
  useAddress,
  ConnectWallet
} from "@thirdweb-dev/react";


function App() {

  const [addr, setaddr] = useState('')

  const address = useAddress();

  useEffect(() => {
    if (address) {
      setaddr(address);
    }
  }, [address]);


  const previousAddressRef = useRef();
  useEffect(() => {
    previousAddressRef.current = address;
  }, [address]);
  const previousAddress = previousAddressRef.current;

  useEffect(() => {
    if (previousAddress && !address) {
      window.location.reload();
    }
  }, [address, previousAddress]);

  const [searchParams] = useSearchParams();
  const rota = searchParams.get('token').substring(0, searchParams.get('token').indexOf("?"));
  const report = searchParams.get('token').split('=')[1];


  useEffect(() => {
    if (report[0] === null) {
      window.location.href = "/rota"
    }
    else {
      var reportNumber = Number(report[0])
      var reportNumberMargin = reportNumber + 1;
      document.getElementById("back").href = "/rota?token=" + rota;
      getWarData(rota, report[0]);
      document.getElementById("ape-number").innerHTML = "#" + rota;
      document.getElementById("report-number").innerHTML = "#" + reportNumberMargin;
      document.getElementById("ape-pfp").style.backgroundImage = "url('https://s3.us-east-1.amazonaws.com/publicassets.atsnft.io/rota/images/" + rota + ".png')";
    }
  }, []);

  function buildTwitter(tokenId, result) {
    var url = "https://twitter.com/intent/tweet?text=";
    var records = "https://www.records.atsnft.io/rota?token=" + tokenId
    var message;

    if (result === "won") {
      message = "Successfully extracted #" + tokenId + " from the War on Apes application by @ATSNFT!" + "\n\n" + "Join the fight! #ROTAbyATSNFT" + "\n\n" + records;
    }
    else {
      message = "Regrettably,  #" + tokenId + " did not survive the battle, but his valiant efforts (probably) made a significant impact." + "\n\n" + "The legacy of #" + tokenId + "'s will be documented in ApeRecords!" + "\n\n" + "#ROTAbyATSNFT" + "\n\n" + records;
    }

    var text = escape(message)

    document.getElementById("twitter-share").href = url + text;
  }


  var winArr1 = [
    "Teleporting in with a handful of daredevil apes mid-air while mounted on a motorcycle he had acquired from gambling just before the mission started, {APE}.replace => # dropped directly toward the facility at the beginning of the assault. However, realizing there was no ramp to land on, {APE}.replace => # tossed the bike into a guard on the dock before landing in the water, locking his legs and smashing directly into a roboshark near the surface, destroying the aquatic drone as well before moving to his assigned area.",
    "Hang-gliding in from a nearby cliffside, {APE}.replace => # navigated past air defense drones to reach the facility, tossing frag grenades at enemies below with deadly accuracy.",
    "Dodging gunfire as they fell from teleporting in 100 feet above the facility, {APE}.replace => # started firing just before he crash landed through the skylight of a defensive command center, raining glass on the enemies below as he fell, then unleashed a flurry of perfectly aimed shots, taking out all 5 technicians in the room before moving towards his objective.",
    "After hijacking a flying drone, {APE}.replace => # maneuvered it over to the Tempus compound, coasting lower until he saw a guard patrol running for cover. When he was right over them, he shoved his knife in the engine at the same moment he jumped away, which caused the drone to slam into the fleeing enemies and explode. {APE}.replace => # continued towards its assigned objective on foot.",
    "Having overshot the entry point by 500 feet up in the air, {APE}.replace => # managed to briefly grab onto a drone, barely managing to avoid being caught up in the propellers before flinging himself off of it, slinging himself feet first towards a Tempus agent in a full suit of Power Armor, whom he slammed into the ground face-first with enough force to shatter armor plate and ribs. 'Still stuck the landing!' he cheered as he made finger guns towards his impromptu landing pad.",
    "Having hijacked a plane from a nearby military airfield in an alternate timeline, {APE}.replace => # flew the stolen aircraft to the proper location and managed to eject just before the plane was shot down, jumping to the correct timeline right before deploying his parachute. Immediately after phasing in, he realized he was in the path of an oncoming drone swarm, and cut himself loose to jump down the last 20 feet. The drone swarm got tangled up in the parachute, and caused a midair pile-up, taking out a dozen drones at once.",
    "After disregarding the assault briefing, {APE}.replace => # decided to “ballpark” the entry point, teleporting in 4 stories above the facility before using the antennas on the roof of an air control tower to slow his descent, acrobatically landing on the roof of a nearby building.",
    "{APE}.replace => # prepared for the battle by learning to craft a wing-suit out of nanites that he then used to fly into the facility after teleporting one thousand feet",
    "{APE}.replace => # decided to jump in on a skateboard from 2000 feet above the facility; convinced that if he ollied at the last second, it would totally break his fall. His fall was broken by impacting a Tempus agent in Power Armour at terminal velocity. {APE}.replace => # managed to survive, but his skateboard was totally busted, dog.",
    "{APE}.replace => # teleported in above the Tempus facility, deploying a parachute and gliding through incoming turret fire before landing on an assault mech and using the parachute to blind the pilot before ripping him out of the cockpit and tossing him into the waters below.",
    "After teleporting in above the facility and attempting to glide in on a wing suit, one of the turrets managed a glancing hit that shredded the material on his left side, causing {APE}.replace => # to drop into a tailspin. Thinking quickly, {APE}.replace => # managed to aim for one of the flying drones, crashing into it and managing to recover in time to break his fall onto the roof of the facility.",
    "{APE}.replace => # joined the air-assault team, bringing what he thought was a parachute. When he opened it in mid-air, however, he realized he had grabbed a life raft. Using his incredible strength and agility, he managed to recover, angling it into a soft crash in the reservoir behind the facility before joining with the underwater assault team.",
    "{APE}.replace => # angled his air assault jump perfectly, teleporting in 20 feet above the rooftop before slamming into a group of Tempus agents and using the surprise to turn their weapons against them in a stunning display of calculated ferocity.",
    "{APE}.replace => # teleported in several hundred feet above the facility, accidentally managing to appear in the middle of one of the Tempus attack helicopters. The resulting effect of nanites clearing a path for his teleport-entry caused the pilot and controls of the helicopter to be shredded upon entry. Realizing the ship was falling out of the sky, {APE}.replace => # waited until the last second to jump clear onto a nearby rooftop.",
    "Loaded to the gills on weed gummies and hard liquor, {APE}.replace => # jumped into the airborne assault team without a parachute, managing to land on top of a passing assault drone. {APE}.replace => # then ripped the plasma cannon out of the damaged drone, letting loose a volley of assault fire down on Tempus agents before crashing into the top of a guard tower and rolled out the blast zone just as the drone exploded, killing fifteen Tempus agents.",
    "Starting a mile up river, {APE}.replace => # swam in to join the underwater assault group where he managed to untether a floating mine, sending it directly into a Tempus patrol boat.",
    "{APE}.replace => # missed the briefing on the assignments of the Underwater assault team and decided to just play it by ear, so he hijacked one of the patrolling jet skis and used another jetski as a ramp, launching the stolen vehicle into one of the Tempus facility’s windows, killing a dozen guards in the process.",
    "{APE}.replace => # teleported underwater and swam into the base, using one of the floating mines to blow apart one of the hydroelectric turbines to gain entry into the facility.",
    "{APE}.replace => # joined the underwater assault team, swimming with a unit of other apes as they began their attack on the hydroelectric generator. {APE}.replace => # bravely acted as a decoy and fought off a school of robosharks while his squad set up demolition charges.",
    "{APE}.replace => # dropped into the water with a scuba tank on and enough weights to let him just walk along the bottom of the river. He then started detaching floating mines like they were balloons and walked them towards the docks, leaving them in pathways to intercept some of the enemy reinforcements heading into the reservoir. His efforts caused chaos and mayhem, allowing other squads to advance a great distance.",
    "{APE}.replace => # teleported in, landing in the water where he was hemmed in by Tempus agents in scuba gear. They fired a harpoon on a chain at him, and he rolled out of the way, grabbing it and hurling it upward, spearing 2 agents passing on a jetski, which caused their vehicle to careen towards a nearby boat and smash directly into fuel tank sparking a fireball. The heavier wreckage started falling amongst the divers, forcing them to allow {APE}.replace => # to escape..",
    "Using an underwater propulsion engine, {APE}.replace => # sped through the water, dodging enemy depth charges from the surface while weaving around robosharks and mines at full power. {APE}.replace => # was instrumental to the effort to establish a foothold on the docks for infiltrating the facility.",
    "Holding his breath for so long that his even his biologically enhanced body was strained, {APE}.replace => # swam silently around the skimmer drones at the docks, planting charges on the fins as he went. Finally forced to come up for a breath of air, {APE}.replace => # pressed the trigger devices just as the guards were alerted to his presence, detonating nine skimmers and causing a distraction that saved the lives of thirteen of his fellow comrades.",
    "When {APE}.replace => # teleported in, he strapped an underwater propulsion engine to an improvised boogie board, and surfed the contraption at high speed until {APE}.replace => # crashed into a water-skimmer drone then dove beneath the surface and swam to the facility.",
    "{APE}.replace => # teleported under the water and swam to the surface only to realize he was underneath a water-skimmer drone. He grabbed onto one of the legs of the drone then climbed up to the dorsal gun turret and wrestled the cannons back and forth, causing it to fire on nearby Tempus drones and watercraft. {APE}.replace => # then removed the gun and dropped a grenade into the hole, destroying the drone.",
    "As part of the initial underwater assault team. {APE}.replace => #'s squad used stealth tactics to sneak deep into the docks facility where they silently dispatched a large number of lookouts; executing them with knives or snapping their necks before hiding their bodies beneath the dock and slipping farther into the base.",
    "{APE}.replace => # teleported in over the water with his arms and legs tucked in, yelled “CANNON BALL” and was immediately struck from behind by a crashing drone. The impact hurled him like a baseball through the window of the Tempus facility, crumpling a high-ranking technician who had been staring out the window on impact. As the nanobots healed his injuries, {APE}.replace => # stood up out of the rubble and tossed the remaining Tempus agents out the window he came in from.",
    "Thinking outside the box, {APE}.replace => # arrived early and climbed into a barrel to float down the river before the underwater assault team reached the combat area. When a pair of agents pulled up to investigate the barrel, {APE}.replace => # leapt out, tearing an arm off one Tempus agent and using it to bludgeon the other to death.",
    "{APE}.replace => # stole four torpedoes and retrofitted them onto an abandoned pontoon boat he found upstream. He, and a small crew of apes, then triggered the torpedoes to fire, propelling the boat at break-neck speeds directly at the Tempus facility, jumping clear at the last second. The resulting blast opened a hole wide enough for the apes to begin climbing inside.",
    "{APE}.replace => # teleported to the wrong timeline and landed on a guided tour boat of the river. After taking in the sights and learning a little bit about the alternate history, he hijacked the boat, offloading all the passengers and crew, and drove straight at the waterfall before teleporting to the correct timeline and phasing directly inside the Tempus building.",
    "{APE}.replace => # woke up late on invasion day and was making coffee when he realized everyone had already made the jump. He took his coffee to go and teleported in randomly, appearing in the middle of an attack helicopter as it was being piloted by another ape directly into another helicopter. He managed to jump just in time to clear the explosion and avoid being shredded by the blades, but ended up having to use his coffee mug to smash the face of a Tempus agent guarding the rooftop he landed on.",
    "{APE}.replace => # teleported in with enough weapons-grade fireworks to put a 4th of July spectacle to shame. Lighting them as he fell, smoke and flares were all anyone could see in the sky for fifteen minutes.",
    "Having jumped with the second airborne wave, {APE}.replace => # teleported into chaos. Drones collided with helicopters as the ape dodged incoming turret fire. He pulled his parachute, only to have a firework impact with it, lighting it aflame. His flaming parachute was sucked into the blades of a crashing helicopter, and he was spun around at multiple Gs before managing to cut the chute, which launched him directly at a Tempus agent attempting to recapture a turret. All according to plan.",
    "{APE}.replace => # teleported in at an altitude of fifteen hundred feet above the facility with six bandoliers of live grenades, launching them in all directions toward the base as he fell, pulling his parachute at the last second to land just after the carpet bomb explosions that heralded his arrival.",
    "{APE}.replace => # teleported in just above the facility. Chaos had already broken out and a Tempus agent wearing a jetpack shot out of the sky on a collision course with him. {APE}.replace => # grabbed the agent, and angled the jetpack down into the rotor of an attack helicopter as it was about to take off; jumping clear a second before the blades shredded and the aircraft crashed.",
    "{APE}.replace => # jumped in with the second wave of the airborne assault team only to collide with a passing mutant albatross. The bird started freaking out, pecking at him as they fell. They both fell into the open side of an attack helicopter that was angling for an attack run on other apes. The resulting collision took out the heli’s gunner in the process. The bird then started panicking and attacking the pilot who mistakenly angled the helicopter into a control tower. {APE}.replace => # shrugged and watched it happen for a second before jumping clear onto a nearby rooftop.",
    "{APE}.replace => # teleported 30 feet above the facility as a Tempus agent was launched into the air by an ape that landed with one of the first assault teams. He managed to grab the enemy mid-air, spiking him into a drone before landing on a rooftop. It was a 3 point shot, easy.",
    "{APE}.replace => # teleported directly into the top of a helicopter as it was taking off. The nanobots clearing his path ate through the drive shaft and arms of the propeller blades, sending them shooting through an assault mech, a turret, an attack drone, and a very unlucky Tempus agent who was not paying attention.",
    "Launching with the second airborne assault team, {APE}.replace => # glided in with a wing-suit, diving and rising underneath a helicopter and grabbed the landing strut, using his momentum to flip into the open bay door. Using the surprise to his advantage, he grabbed the side arm of the pilot and disposed of the passengers and copilot, {APE}.replace => # then held the gun to the side of the pilot’s head and forced him circle the facility while {APE}.replace => # aimed the heli’s minigun towards drone swarms to lay down cover fire.",
    "Misunderstanding his assignment, {APE}.replace => # geared up for the cliff ascent team but was instead deployed with the second wave of the airborne team. When he jumped to the provided coordinates, he began to panic while falling from 80 feet up. However, he managed to lasso his climbing gear onto the cockpit of a piloted assault mech suit, and pulled himself as hard as he could toward the lumbering machine while twirling his rope to make another knot around it before landing. {APE}.replace => # began jumping around, using his reinforced climbing rope to tie up the mech as the pilot attempted to pull him off. In the resulting confusion, the mech stumbled over the side of the facility, plunging into the water where it was dragged into the waterfall.",
    "Starting at the base of the waterfall, {APE}.replace => # joined the cliffside assault crew to stealthily infiltrate the Tempus facility. With gymnastic skills that would have dropped Olympian jaws, {APE}.replace => #'s squad rapidly scaled the face of the rocks underneath the waterfall. Once in position, they turned back at the edge of the cliffside entrance and started laying down cover fire against the swarming drones trying to impede the apes who were still climbing.",
    "{APE}.replace => # teleported in, half-way up the waterfall, managing to grab hold of the slippery rocks and free climb against the torrential downpour to reach the facility’s waterfall cave entrance. Once at the top, {APE}.replace => # dropped into a crouch, hiding behind a crate to avoid direction. As a guard walked past, {APE}.replace => # grabbed hold of his side arm, pistol-whipping the man so hard in the back of his head the sound of bones crunching could almost be heard above the waterfall. After hiding the body, {APE}.replace => # found a computer terminal where he was able to hack in and shut the lights off. It was time to go dark.",
    "{APE}.replace => #, one of the first in, scaled the cliffs, strategically placing bags of back-up gear and ammo to assist the main infiltration crew up the side of the waterfall, while managing to sabotage sensors and cameras on the way up.",
    "As the cliffside infiltration team made their ascent, {APE}.replace => # managed to avoid constant weapons fire from the patrolling drones long enough to sabotage the communication system, buying time for other apes to take down the drones harassing them.",
    "{APE}.replace => # bounded recklessly up the waterfall just ahead of the cliff ascent team. In an effort to ease the passage of those who followed, {APE}.replace => # placed a series of pitons and ropes through dangerous areas by driving the pitons into the rock with each jump while looping a rope around the previous piton with his feet as he jumped past.",
    "{APE}.replace => # used the established rope system laid by previous apes to climb up the waterfall and draw drone fire away in a series of acrobatic moves that resulted in {APE}.replace => # jumping outside the waterfall to kick one drone into another before jumping back to safety behind the waterfall.",
    "Climbing up the side of the waterfall with the cliff ascent team, {APE}.replace => # realized a helicopter was hovering just on the other side of the waterfall and preparing to fire. {APE}.replace => # pushed out from the cliff with all his strength and leapt through the torrential waterfall into the door of the helicopter, narrowly avoiding the propeller blades in order to assault the crew, forcing a crash landing before climbing back up to his squad.",
    "{APE}.replace => # followed the cliff ascent team. Having procured weapons and ammo from crashed ships, he spent his time setting up a series of defense positions along the cliff face where weapons could be stored with ammo in order to defend against passing drones. This created a more protected path to secure the other apes' climbs.",
    "Soaked to the bone and irritated from climbing up the side of the waterfall to infiltrate the facility’s waterfall cave entrance, {APE}.replace => # came upon a guard platform and immediately took his frustrations out on the surprised guards. He casually tossed one out to crash on the rocks below before crushing another’s head into a communications panel, preventing any alarms from being raised.",
    "{APE}.replace => # climbed up with the cliff ascent team, free soloing up against the brutal torrent of water to climb behind the automated turrets and disassemble them one by one, clearing a path for the apes that followed behind him.",
    "{APE}.replace => # joined the second underwater team to cover the assault from the Tempus reinforcements. He hijacked a boat just after some agents jumped in the water, whipping the engines to full reverse, causing the motor’s propeller blades to shred the pair of Tempus divers before they had time to react.",
    "{APE}.replace => # ported into the air above the reservoir waters just as the battle was in full swing, only to realize that Tempus had released robotic sharks into the water. A helicopter crashed into the water a few moments before him. {APE}.replace => # watched as one of the robosharks barreled forward and tore a familiar ape to shreds before heading towards him. As the helicopter began to sink to the depths, {APE}.replace => # dove down, mounted the minigun and began unloading on the charging robot, completely destroying it.",
    "Joining with the underwater team, {APE}.replace => # weighed himself down with a plasma gun salvaged from one of the destroyed assault mechs that had fallen into the river. He used it to lay covering fire for the underwater assault team, incinerating robotic sharks and sawing Tempus agents in half with white-hot blasts of focused starfire.",
    "{APE}.replace => # teleported with the underwater support team, looking up to the surface just in time to see Tempus release robotic sharks into the water. As one of the mechanical monsters sliced through the water towards him, {APE}.replace => # jammed his trusty knife deep in the head of the shark and used it like a joystick to steer the beast directly into spinning hydroelectric turbines, damaging the facility’s power structure.",
    "{APE}.replace => # swam in the river a ways out from the facility, surfacing to call out instructions over the radio to coordinate the assault. When he was discovered by a water-skimmer drone, he was forced to dive beneath the surface where he found a nearby floating mine. {APE}.replace => # snapped the chain holding the mine, which then breached the surface of the water and made contact with the drone, destroying the autonomous vehicle completely.",
    "{APE}.replace => # joined the underwater support team, helping to pull unconscious apes out of the water. When Tempus released the robotic sharks, managed to bait one into attacking him first, rotating at the last second in order to climb on the back of the roboting shark, tearing its fins off while redirecting the machine to send it hurtling towards one of the many tethered mines.",
    "{APE}.replace => # strapped on water skis and let another ape pull him from a hijacked boat. When Tempus agents released robotic sharks into the water, {APE}.replace => # was forced to use a crashed helicopter as a ramp to vault over them. In another timeline, this was the inspiration for an event in that timeline’s television history that can widely be pointed to as the downfall of the sitcom Happy Days.",
    "{APE}.replace => # swam with the underwater assault team, but was rocked by a depth charge from one of the water-skimmer drones. Irritated, and not one shrug off an offense, {APE}.replace => # swam over to the drone, climbed up one of the limbs, broke the weapons storage on it, and began flinging depth charges at every Tempus boat that passed. After avoiding gunfire from Tempus agents, and sinking 5 of their boats, the drone’s weapons ran dry. {APE}.replace => # then allowed himself to fall back into the water, leaving the drone useless.",
    "Stationed on the dock, {APE}.replace => # shot a hole in the fuel tank of one of the boats as it sped around. Tempus agents nearby began firing on the underwater apes while {APE}.replace => # laid down covering fire against the water skimmer drones. When a Tempus agent caught him off guard, pointing a gun at his head and saying “Any last words?” {APE}.replace => # responded “Got a light?” before tossing his lit joint into the water, causing a river of fire to rapidly snake along the surface of the water until it reached the ruptured tank and ignited it. The explosion sent shrapnel flying, shredding the head and body of the Tempus agent threatening him with shrapnel.",
    "{APE}.replace => # teleported in with the rooftop assault team, but slipped on a dead Tempus agent, and fell off into the water, getting sucked into a hole that had been torn in a submerged portion of the wall. He was thrown bodily onto a walkway rail just inside the facility while a torrent of water began flooding the section of the hydroelectric power station. A surprised engineer, who had been standing on the walkway to assess the damage, tried to hit him with a wrench but {APE}.replace => #, using incredible upper body strength, pulled himself up with so much force that he knocked the Tempus lackey out with a swift headbutt before his feet hit the ground.",
    "{APE}.replace => # and his squad teleported to the shore of the reservoir and quickly set up a system of improvised slingshots using ropes woven around trunks of the gnarly trees along the shoreline. Two other apes worked together to pull the rope back so the ape could sit in the middle and aim himself towards the wall. He shouted 'RELEASE ME' and flew towards the wall in a shallow arc, guns blazing, taking out a passing patrol as he landed.",
    "{APE}.replace => # used one of the makeshift slingshots to hurl himself at a reinforced sniper’s nest. Immediately after landing on the roof, {APE}.replace => # swung down into the enclosure and smashed the agents inside before yeeting them out the back door. He then took up one of the large rifles and provided fire support for the rest of his squad.",
    "{APE}.replace => # joined the forest assault team, utilizing one of the makeshift slingshots to launch himself at a patrolling helicopter. He pulled the gunner out of the bay door, flung him wildly into the propeller blades, painting the windshield with blood before he dropped a live grenade in the pilot’s lap and jumped towards the facility.",
    "Having teleported in with the slingshot team, {APE}.replace => # launched himself onto the roof of the facility, coming down on a Tempus agent with an atomic elbow that crumpled the guard, snapping his spine as he folded in half from the impact.",
    "After arriving near the slingshot team, {APE}.replace => # gathered fallen pieces of debris from the air battle above. After dragging the wreckage parts to the shoreline he used it as ammunition for the makeshift slingshots; taking out several nearby drones, and one distant guard who was so far away he couldn’t even hear {APE}.replace => # shouted “From downtown!” just before a projectile knocked the Tempus agent off the roof. When he ran out of ammo, he launched himself in the general direction of his objective.",
    "{APE}.replace => # managed to recover a rocket from a crashed helicopter. Using the slingshot system the other apes had rigged up, he launched himself in the air with the missile, dropping it directly onto one of the water-skimmer drones below as he passed overhead.",
    "Having landed a little short of the facility and being separated from his air assault team, {APE}.replace => # pulled a sword off a dead Tempus agent, walked over to the makeshift slingshots, and had the apes launch him at one of the rooftop mechs. {APE}.replace => # then opened his wingsuit and glided forward at lightning fast speed to jam the weapon into the power cell of a large and heavily armed mech, causing a gout of flame to burst out, raining molten fire onto Tempus reinforcements. As the mech’s pierced reactor overloaded and exploded behind him, {APE}.replace => # charged farther forward into the facility.",
    "As part of the slingshot assault team, {APE}.replace => # made sure to bring enough snacks and refreshments for his fellow apes. Assaulting a tyrannical organization hell bent on eradicating you from the face of existence is hungry work. After the snacks were distributed, {APE}.replace => # launched himself onto a rooftop where he tore an agent’s face off and crushed another agent’s head with his bare hands.",
    "{APE}.replace => # used one of the makeshift slingshots to launch himself into the air, grabbing a drone then using his momentum to hurl it like a giant frisbee into the blades of a patrolling helicopter as the aircraft was descending towards the docks.",
    "Just after arriving, {APE}.replace => # managed to ambush a squad of patrolling Tempus agents, incapacitating them and dragging their bodies into the woods where a makeshift slingshot was set up. He then carefully loaded the agents up and flung their bodies one by one at patrolling hovercrafts and boats in an effort to demoralize them. Once the human ammo was exhausted, the ape launched himself towards one of the guard towers and wreaked havoc on the soldiers inside.",
    "As part of the reinforcements, {APE}.replace => # ported into the waterfall’s cave entrance where he was confronted by a cyborg ape standing over the body of one of his fallen comrades. The cyborg ape threw a knife into the torso of {APE}.replace => # before unsheathing a second blade and rushing at him. {APE}.replace => # pulled the first knife free from his chest just in time to deflect the cyborg’s attack. The resulting knife fight was brutal, with {APE}.replace => # attempting to dodge slashes long enough for his wounds to heal. The sound of an explosion further inside the base caused the cyborg to hesitate just long enough for {APE}.replace => # to jam the knife in its throat while pulling the creature’s arm, tearing the biomechanical head from the torso.",
    "Arriving with the docks reinforcement team, {APE}.replace => # managed to avoid the nearby automated defense turret by swimming through the debris around an exploded dock. After reaching the base of the pillar holding the gun assembly aloft, he planted a demolition charge before climbing out and detonating it while leaping towards the facility, toppling the concrete tower like a snapped toothpick.",
    "Teleporting in with the reinforcements, {APE}.replace => # arrived just in time to reach out and save an ape from falling down the cliff. The two of them climbed the rest of the way to the entrance of the facility.",
    "{APE}.replace => # teleported in with a squad of reinforcements, arriving in the bombed out remains of the first shield generator just before catching and returning a live grenade to a team of approaching Tempus agents. The sonic wave cut a line straight through their armored bodies, tearing a gouge in the walls of the hallway around them. {APE}.replace => # checked in with his team and began pushing towards his objective.",
    "As part of the reinforcement team, {APE}.replace => # teleported in the middle of a pitched battle on the rooftop. A Tempus agent held a gun out, and {APE}.replace => # reflexively covered the barrel with his palm, angling it away from his face as the agent fired. {APE}.replace => # then pushed the barrel of the gun through the hole in his hand, using the nanites trying to heal his wound to jam the weapon as he used his free hand to crush the windpipe of the attacking agent.",
    "Teleporting in with the reinforcement group, {APE}.replace => # charged a bottle neck of Tempus agents attempting to barricade a doorway. Taking several bullet wounds like they were nothing, he managed to inspire his fellow apes to renew the ferocity of their assault. {APE}.replace => # smashed through the barricade before he continued his assault, leaving a trail of blood chasing him forward as the nanites patched wounds.",
    "{APE}.replace => # joined the reinforcement team, leading a reinvigorated charge on the surface of the facility by leaping over a blockade into a platoon of Tempus agents as he unleashed all the weapons at his disposal to create a distraction long enough for his fellow apes to overrun the Tempus agents.",
    "Joining the chaos in the reinforcement team, {APE}.replace => # looked up to see a helicopter explode overhead, sending an ape flying in over the side of the waterfall. {APE}.replace => # managed to reach out and grab him just in time, holding on to the edge of the roof with only one foot.",
    "Seeing an ape parachuting in directly in range of a rampaging mech, {APE}.replace => # leaped off the side of the bridge and into the air. Grabbing hold of the ape’s foot to act as a branch, they work in sync to help swing {APE}.replace => # at the attacking mech. As he climbed up the giant robot, it distracted the pilot long enough for the parachuting ape to land and fire into the cockpit, killing the pilot as {APE}.replace => # jumped clear of the fire.",
    "Joining the rest of the reinforcements, {APE}.replace => # appeared on the rooftop where he covered incoming apes from the air team until they reached the ground. Together, these apes then rushed one of the rooftop hatches before the Tempus agents could close it, securing an entry point.",
    "{APE}.replace => # arrived with a reinforcement team on the rooftop just as a construction mech’s claw shot out, catching and crushing a fellow ape just in front of him, spraying him with his squadmate’s blood. {APE}.replace => # ran up the outstretched arm of the mech and began furiously pulling at exposed tubes and wires as the mech attempted to grab hold of him. He managed to damage the mech enough that the machine stumbled sideways and toppled over the edge of the roof, hurtling down into the rocky water far beneath the waterfalls.",
    "{APE}.replace => # appeared near the edge of the facility with a reinforcement team. Immediately after completing the jump, his squadmate tosses him a rope that they then use to closeline a charging capture squad of Tempus soldiers, while the rest of the apes surged forward and threw the erstwhile enemies off the rooftop rapidly.",
    "{APE}.replace => # arrived with a rapid reinforcement team directly behind a Tempus agent in an exo-suit. Seeing another ape running directly at him, {APE}.replace => # slid behind the soldier’s armored legs while his teammate delivered a flying kick to the agent’s face, flipping the agent fully onto its own neck with a satisfying crunch.",
    "{APE}.replace => # moved in with a rooftop reinforcement team as cyborg apes, commanded by Tempus, flooded out of a doorway on the other side of the building. Moving with incredible synchronization, the cyborgs decimated a nearby trio of apes with frightening ease. After pulling the pins off the smoke grenades attached to a fallen Tempus agent, {APE}.replace => # threw the body into the cyborgs while he ran forward, using the projectile as a smoking shield, he dove at the group; creating a window of opportunity for his team to deliver a savage and devastating counter strike against the monstrous machines and seal the door.",
    "Teleporting in on the rooftop while all hell broke loose, {APE}.replace => # observes another ape, armed with a cyber katana, get lifted by the wrist as an eerily familiar cyborg attempts to crush the throat of his teammate with his other hand. {APE}.replace => # saw his teammate drop the sword and leapt to grab it, catching the weapon and spinning it around to slam up through the jaw of the distracted cyborg, killing it instantly and freeing his teammate.",
    "{APE}.replace => # arrived with one of the later reinforcement teams. Under heavy fire, he and his squad began to gather wounded apes and drag them to safety so they could heal and continue fighting.",
    "{APE}.replace => # ported in with the early assault reinforcements and, with the assistance of another ape, launched a shoulder-check into the chest of a mech as other apes jammed weapons into the back of the knees of the hulking machine, which toppled backward as the squad swarmed over it. In a matter of seconds, the squad had crumpled the exterior with dozens of blows, leaving it leaking and immobile as they moved to secure the area.",
    "{APE}.replace => #, part of the rapid reinforcement squad, ported in and launched himself towards a preoccupied nearby agent in an exo-suit. Snagging the exoskeleton by the neck, {APE}.replace => # lifted the agent off his feet while the ape that was fighting the agent seized the opportunity to grab the ankles of his armored assailant, then both apes savagely slammed their enemy’s back into the ground with enough force to shatter the floor and crack open the back-mounted energy cell, sending boiling plasma bursting out from under the suit as they rolled away.",
    "Joining the rapid reinforcement team, {APE}.replace => # teleported in just as a Tempus agent forced an ape to the ground, leveling an anti-nanite weapon at his skull. Thinking quickly, {APE}.replace => # jerked the agent’s aiming arm skyward from behind, lifting the lightly armored soldier over his head before snatching the combatant’s leg with the other hand then slamming the agent down over his knee, snapping his spine and tossing him off the roof into the waters below.",
    "Joining one of the reinforcement teams, {APE}.replace => # arrived just behind the back wall of a turret tower, ripping the gunner out from the roof hatch and pile driving him off the tower from thirty feet in the air.",
    "{APE}.replace => # lands on a rooftop with a reinforcement team only to be confronted by a Tempus agent leveling an anti-nanite rifle at his head. Reacting reflexively, {APE}.replace => # dropped to the floor before flinging his legs in the air to disarming the agent with his feet before recovering the rifle and emptying the rest of the magazine on the other guards, shredding two cyborg apes in the process, before coming to his feet using the empty weapon to smash the agent in front of him.",
    "{APE}.replace => # joined a reinforcement squad, porting into the rooftop in time to see his fellow apes restrain a smaller assault mech. Seizing the moment, {APE}.replace => # jumped onto the cockpit, shattering it with a direct hit from his hand cannon before pulling out the remains of the pilot and climbing in himself. After figuring out the targeting controls, {APE}.replace => # blasted holes in the rooftop to make entry points for the other arriving apes before unloading the rest of the mech’s ammunition on the surrounding guard and communications towers.",
    "After teleporting in with one of the reinforcement teams, {APE}.replace => # phased through the leg of a larger assault mech. As the nanites shredded the reinforced appendage to make way for {APE}.replace => #'s entry, the mech tottered and fell over. Acting on instinct, {APE}.replace => # grabbed an exposed piece of rebar from nearby rubble, wrenched it free and drove it forcefully into the cockpit, killing the pilot.",
    "Appearing side by side with another member of the reinforcement team, the two apes lept into action, assisting each other in taking down a team of Tempus agents. {APE}.replace => # charged toward one of the cyborgs, sliding under its weapon fire, as his partner leapt into the air, kicking the cyborg in the chest and toppling him as {APE}.replace => # kicked his legs out from under him.",
    "Joining a reinforcement team, {APE}.replace => # teleported in after much of the rooftop battle had settled down. He walked toward one of the holes blown in the rooftop and found the body of a Tempus agent still holding a grenade launcher. Picking it up and checking to see if it was empty, {APE}.replace => # gave the trigger a few test-squeezes just as Tempus reinforcements teleported in. The launcher fired the rest of its payload at the enemy troops, sending limbs flying in all directions.",
    "Teleporting onto a launchpad on top of one of buildings with the rest of the reinforcements, {APE}.replace => # heard, then saw, a length of dragging chain tangled around a nearby mech’s foot. After taking stock of his surroundings, {APE}.replace => # also identified a helicopter that was about to take off. Moving with lightning speed he grasped the end of the chain and tossed it over the tail boom, then wrapped the chain in a knot to fasten it and jumped away. From there it was just a matter of finding a good spot to watch as the helicopter attempted to take off, only to trip the mech and jerk wildly, snapping a propeller on the landing pad and tumbling off the edge of the roof, dragging the mech with it.",
    "{APE}.replace => # jumped with the reinforcements, but ended up landing three miles away at a security outpost. Finding an advanced weather balloon to be the only vehicle available, {APE}.replace => # spent a short time learning how it worked before floating toward the Tempus facility, dropping grenades before ultimately getting shot out of the sky. He crashed the rapidly deflating aircraft into one of the Tempus patrol boats, fouling the watercraft as he dove into the water.",
    "Having jumped in with the reinforcement crew, {APE}.replace => # scaled along the reservoir side of the building, stealthily grabbing Tempus agents near the ledge before flinging them mercilessly to their deaths below.",
    "{APE}.replace => # joined with a reinforcement squad and ported into mayhem as an explosion immediately threw them off their feet. Moving from cover to cover, {APE}.replace => # managed to dispatch Tempus agents, ripping away their firearms and returning fire while zig-zagging toward their objective.",
    "While the Tempus agents were attempting to redeploy, {APE}.replace => # joined with a squad raiding an arms locker, grabbing ammo and tossing it to his fellow crew members as they turned the agents’ weapons against them. When cornered by a Tempus agent, {APE}.replace => # slung the last thing he picked up, which happened to be a leaking flamethrower. The resulting fireball blew five agents into the air, and knocked {APE}.replace => # down through a hole left by a prior ordinance impact on the surface of the facility."
  ];
  var winArr2 = [
    "{APE}.replace => # made a bee-line towards his assignment: assaulting and clearing one of the major anti-aircraft gun emplacements turrets. After completing his task, {APE}.replace => # took control of the gun turret and began sending massive volleys of armor piercing rounds towards enemy drone swarms; using the weapons system to shave dozens of drones off of each cluster before the turret overheated and the barrels melted.",
    "As another ape took over a Tempus anti-air gun battery, {APE}.replace => # stood behind him, covering his back with an energy weapon picked up off a downed agent. As a platoon of Tempus agents surrounded the apes, a rogue EMP grenade was lobbed from one of the other battles, landing in the midst of the enemy unit and short circuiting every weapon but the turret. What followed was, by all accounts, a textbook example of an 'ass kicking,' as the platoon of agents attempted to rush the raised platform while {APE}.replace => # held the high ground; bringing all his ferocity to bear against the attackers to ensure his team was protected. The enemies were torn apart like mice in a blender. He had the high ground, man.",
    "Making his way over to one of the raised sniper towers, {APE}.replace => # used a piton from the climbing team to wedge the exit door shut before dropping live grenades into the slit windows, blowing up the Tempus agents inside and then removing the wedge from the door, letting the smoke clear, and taking up an advantaged position with two sniper rifles.",
    "Rushing down the battlements, {APE}.replace => # dodged incoming fire from one of the Tempus agents. Leaping over him, {APE}.replace => # grabbed the agent by the head, flipping him bodily over in front of him, and tossing the agent at a cluster of Tempus reinforcements before leaping into the confused group, crushing the throat of one man and smashing solar plexus of another before wrenching an automatic weapon from one of their hands, rolling away, and firing on them before they could try to recover.",
    "As {APE}.replace => # ran toward one of the barracks towers, a Tempus agent appeared in the doorway, trying to run out. Sliding from a full sprint, {APE}.replace => # grabbed the agent’s ankle with one foot, broke his kneecap with the other, and used his remaining momentum to flip the agent over by stiffening one arm while grabbing his service pistol with his free hand. {APE}.replace => # transitioned into a crouch then down to one knee as he fired one perfect shot at each of the other agents inside the hallway. The hallway was clear before the first enemy hit the floor.",
    "Making his way to the first energy shield generator, {APE}.replace => # began taking heavy enemy fire. Lunging forward before bringing his legs around, he slid on his back under a torrent of enemy fire, coming to a halt next to a downed agent wearing heavy armor. Using the body as a bulwark, {APE}.replace => # seized the agent's weapon and began laying down suppressing fire, which allowed the squads of apes behind him to swarm forward and, within the span of 3 terrified human heartbeats, eradicate every one of the enemies that had just taken cover, barely slowing before continuing toward the primary shield facility.",
    "Racing forward, {APE}.replace => # dropped into a hole that had been blown in the roof of the building containing the first shield generator and squeezed in between the gap walls where he slid to the floor before leaping out of the drywall and savaging a squad of incredibly surprised Tempus agents. {APE}.replace => # darted deeper into the wing of the facility while leaving a trail of wanton and enraged destruction in his wake, forcing Tempus to send some forces out to search for him, further reducing the strength of the main defense force.",
    "While assisting in the assault on the first jumpshield generator, {APE}.replace => # climbed up to the roof where he observed a previously unseen Tempus agent taking position to fire on the squad of apes setting plasma charges on the main blast door. Grabbing the only thing he could find to attack with, which just so happened to be a loose piece of masonry, {APE}.replace => # put all his might into a perfectly aimed side-arm throw before triumphantly yelling “Do you like having your teeth knocked out, because it looks like you just got bricked up!”",
    "Continuing his approach, {APE}.replace => # saw one of their teammates walking out of a small building overloaded with weapons, bandoleers, and carrying a long crate under one arm. Reaching into the box, the newly-nicknamed “Bearer of Boom” blessed {APE}.replace => # with a claymore mine and remote trigger. As {APE}.replace => # pushed further toward the shield generator, he was intercepted by a Tempus agent in exo-armor carrying a heavy belt-fed machine gun. Using agility and the rubble strewn terrain to his advantage, {APE}.replace => # avoided incoming fire long enough to jump onto the agent’s chest, slip the mine under the collar of the attacker’s armor. {APE}.replace => # leapt away and pressed the detonator, blowing the agent's shredded heart and lungs out through his ammunition-filled backpack, leaving the body looking like a sloppy impressionist’s version of an ancient viking “blood eagle.” In place of a scream, the agent died with a loud, wet pop, followed by the staccato of live ammunition cooked off by the blast.",
    "Grabbing every weapon he could find along the way, {APE}.replace => # made his way to the shield generator. Seeing a team of agents attempting to flank his squad members, {APE}.replace => # rolled into a slide, then used his hands and feet to wildly fire the four cyber pistols simultaneously as he came low around a corner unleashing a fiery fusillade of laser fire and dropped several exposed soldiers.",
    "Using a piece of shattered rotor blade like a bastard sword, {APE}.replace => # cut his way into a security checkpoint inside the first shield generator building, carving through camera displays and cutting down guards as they attempted to stem the tide of apes.",
    "After making his way into the shield generator defense control room, {APE}.replace => # began placing charges around specific control panels while avoiding gunfire from Tempus agents. When he was finished, he snuck out of a hole in the ceiling before detonating the charges with the Tempus agents still inside.",
    "In an attempt to make his way to the shield generator, {APE}.replace => # finds himself in one of the office break rooms. I mean, since he was there he might as well see what they have going on in the fridge. {APE}.replace => # found a delicious looking burrito in a tupperware marked “Jeremy’s lunch. Do not touch.” Get wrecked, Jeremy. That burrito was delicious.",
    "Progressing hastily toward the shield generator, {APE}.replace => # was stopped by a Tempus agent who got the drop on him and pressed a gun to the side of the ape’s head. The agent began to shout a command but was suddenly cut off by the roar of a detonation on the floor above, which knocked a piece of the ceiling loose. Exploiting the agent’s momentary distraction, {APE}.replace => # deftly plucked a falling ceiling tile out of the air then spun and smashed its edge into the human’s face, cracking teeth and dislocating his jaw. Before the agent began to recoil, {APE}.replace => # had already reversed the agent's weapon and fired it twice in the chest and once more in the head of its owner. {APE}.replace => # scavenged a clip from the downed soldier, then moved on.",
    "After making his way to the first energy shield generator, {APE}.replace => # saw one of his teammates gunned down in front of him. Ripping one of the generator’s electrical wires out of the wall, he shoved the arcing cable into the spine of the Tempus agent until smoke began billowing from under their helmet.",
    "After making his way to the shield generator with a crew of other apes, {APE}.replace => # was picked up from behind and hurled into a wall by one of the cyborg apes. As the other apes lept into action, the cyborg systematically dispatched them each with a single anti-nanite round to the chest. As he watched his squad members gunned down, {APE}.replace => # rolled to his feet and leapt on the cyborg's back while he was distracted. Using nothing but his teeth to bite through the circuits attached to the creature’s neck, he then pulled out the wired brainstem with his bare hands, killing the cyborg.",
    "Frantically dodging plasma fire rounds while being chased, {APE}.replace => # ran down the halls of the Tempus facility, then slid into one of the shield generator rooms. Recognizing his target, {APE}.replace => # lobbed a bandolier of live incendiary grenades at one of the server nodes before leaping through a fissured section of the ceiling, narrowly avoiding being caught in the rolling flames as the explosion ignited.",
    "Making his way to the second shield generator, {APE}.replace => # stealthily entered through the air ducts, placing charges and sneaking out right under the noses of an entire squad of Tempus agents. By the time they realized that a charge had been placed, the ape was clear of the room and pressed the detonator, destroying the room along with all the guards inside.",
    "While attempting to sabotage one of the shield generators, {APE}.replace => # was confronted by a Tempus agent wielding a sword. As the agent pointed the sword at the ape, an explosion knocked a piece of rebar down from the ceiling. With a large amount of concrete still attached to the bar, {APE}.replace => # picked it up, wielding it like a warhammer against the attacking Tempus agent, crushing them easily with an overhand swing.",
    "{APE}.replace => #'s squad made their way around the top of the first shield generator planting charges on the focusing crystals while dodging drones and hovercopter fire. After planting the last charge, they landed directly over the entrance, pounded their chests and bellowed ferociously in defiance of their enemies, then detonated, causing the temporal shield to destabilize, and allowed reinforcements to teleport closer in.",
    "Taking a moment to stop in what he thought was a bathroom, {APE}.replace => # took a leak on one of the facility’s server towers, shorting the electronics out and starting a small electrical fire. He politely hung an 'Out of Order' sign on the door before leaving as the fire spread.",
    "After infiltrating the facility, {APE}.replace => # managed to find one of the network rooms after attacking one of the Tempus agents and retrieving a control room key. Once inside, he managed to turn the automated turret systems against all Tempus personnel for a few moments, causing massive damage to the defenders of the first temporal shield generator.",
    "Once inside the facility, {APE}.replace => # managed to quickly field strip the components off of a downed Tempus agent’s exo-suit. This allowed him to retrieve a powerful magnet from one of the joints. Using a scanner, he then located and breached one of the backup server rooms, then strapped three sonic grenades around the magnet. {APE}.replace => # pulled the pins on his improvised device and tossed it in the middle of the room. The explosions disintegrated the magnet to dust, spreading electronic death on server after server, which resulted in a total loss of local data backups and bricked all of the failsafe systems for that building.",
    "Finding one of the control rooms after infiltrating the facility, {APE}.replace => # managed to hack into the security network, unlocking all doors and preventing them from being remotely locked. He then found a candy bar in one of the drawers and ate it while watching the monitors as his fellow apes rained down chaos further into the facility. Interrupting his break, a Tempus agent walked into the room. As he went to scream, {APE}.replace => # jammed the candy bar into the man’s mouth and uppercut him. The agent’s eye went wide and he fell to the floor; desperately trying to fish the candy out of his mouth in between panicked wheezes. {APE}.replace => # looked at him in confusion as the agent fished an epipen out of a pocket. The ape grabbed the pen out of the agent’s hand and held it out at him like a weapon. The agent died while pointing to his leg. Humans are so weird. Who doesn’t like peanut butter in a candy bar?",
    "Upon infiltrating and overpowering the technicians in one of the control towers, {APE}.replace => # began inputting commands to some of the drones to have them hover in place and act as a bridge so that reinforcements were able to easily move across the massive Tempus superstructure.",
    "After he cleared a sniper’s nest, {APE}.replace => # successfully set up a critical signal jammer to impede the efforts of Tempus forces attempting to reorganize on the western wing of the facility.",
    "Finding an area at the top of a stairwell where he could work, {APE}.replace => # quickly set up a powerful comms jammer to hamper Tempus reinforcements beginning to arrive near the core of the facility, then began heading to an extraction point.",
    "Using the still-smoking hollowed out remnants of a destroyed helicopter, {APE}.replace => # quietly set up a signal jammer to frustrate the desperate Tempus leaders trying to consolidate forces in the right wing of the facility after the first shield generator was taken down.",
    "After making it all the way to the first shield generator’s control room, {APE}.replace => # and his squad took the Tempus scientists hostage, forcing them to initiate an immediate shutdown of the temporal shield projector. This cleared the way for ape reinforcements to arrive closer to the core of the facility. The squad shoved the nerds into some nearby uniform lockers, where they belonged, before beginning to exfiltrate.",
    "Next, {APE}.replace => # blitzed deep into the first temporal shield generator, and after a member of his crew ensured the projector was down, {APE}.replace => # began sabotaging the power controls to ensure that it couldn’t be easily repaired. This ensured that {APE}.replace => # reinforcements could continue to teleport directly into the facility.",
    "{APE}.replace => #, moving through the corridors of the first shield generator unit, snuck up behind a wandering guard patrol before jumping into their midst. He used the shotgun on the lead agent’s back to fire a blast down into the unfortunate human’s leg. As the enemy started falling down, the ape flipped the shotgun free while yanking the injured combatant around as a shield to absorb the short volley of return fire from the other agents while he dispatched them one at a time, then dropped the empty weapon and moved to cover apes heading back out to the main engagement.",
    "Stealthing into the first shield generator room, {APE}.replace => # hung from the ceiling, tossing down smoke grenades with his feet before dropping to the floor of the obscured room and taking the guards down one-at-a-time.",
    "{APE}.replace => # made his way to the first shield generator only to find that his fellow apes had already shut it down and successfully sabotaged the machinery from being powered up again. Ever the opportunist, {APE}.replace => # began harvesting components and advanced hardware from the device rapidly, in order to bring it back for replication at the ape’s primary base. Hearing Tempus agents running in to secure the location, {APE}.replace => # dropped his bandolier of grenades after activating one. As he ran to deliver the hardware, {APE}.replace => # turned to watch the unwitting agents as they were blown apart upon entering the room.",
    "Loaded head to toe with bandoliers of explosives, {APE}.replace => # could barely see where he was going, but still managed to stumble into the room housing the controls to the main blast door of the second shield generator. When he was discovered by a guard, the Tempus agent was forced to hold his fire for a split second to prevent potentially catastrophic damage to the blast door controls. {APE}.replace => #, having no such concern, produced a pistol and shot the Tempus agent in the blink of an eye before passing out grenade belts as apes surged in the building.",
    "Quickly making his way through the facility to the second temporal shield generator, {APE}.replace => # kicked in a door and burst in a room, weapons drawn. The force of his kick tore the door from the hinges, launching it into a Tempus scientist who slipped backward and fell against a control panel, causing a chain reaction that triggered the automated defense systems to reboot. This briefly allowed more ape reinforcements to rush into the facility. {APE}.replace => # holstered his weapons and shrugged. Job well done.",
    "{APE}.replace => # then went on to rush the second temporal shield generator only to find the hallway blocked by 2 cyborgs laying waste to a small army of other apes. {APE}.replace => # managed to pull several of his crew to safety, saving the lives of 5 apes in the process, though the lives of many others were lost.",
    "Moving through the facility to the second shield generator, {APE}.replace => # infiltrated through the air ducts. Finding a cyborg guarding the main projector, {APE}.replace => # lobbed a grenade at the generator from the ceiling, but the reflexes of the biomechanical ape were so fast that the enemy managed to hear the grenade fall, pick it up and hurl it back at {APE}.replace => # before it exploded. This caused {APE}.replace => # to drop from the ceiling in a scramble as the cyborg opened fire on him. He managed to avoid getting hit while drawing fire towards many of the scientists in the room before escaping with minor injuries.",
    "Making his way to the second temporal shield generator, {APE}.replace => # walked through a closed hanger. As he rushed to close the distance on his objective, an assault mech fell through the hangar ceiling, crashing in front of him, and firing off a volley of rockets while {APE}.replace => # scrambled to avoid them. He managed to take cover as the mech began to right itself, but realized he was hiding behind an idling empty mech with an open cockpit. {APE}.replace => # jumped in and engaged the controls, fighting toe to toe with the robotic titan, until he was able to circle around and rip a steel beam off of the wall before using it to impale the opposing pilot. He then climbed up through the gaping hole in the roof to assist reinforcements until his mech had soaked up so much damage that he was forced to abandon it.",
    "Posting up in a position behind the apes, {APE}.replace => # set up a small barricade for the apes to move through, and rigged a turret in a defensible position. As Tempus agents rounded the corner, they were gunned down one after the other. A few other apes had to go on ammo runs for him just to keep up with the carnage.",
    "{APE}.replace => # followed behind one of the infiltration crews, setting up traps around every corner, rigging home-made explosives and tripwires to dissuade pursuit. By the time a Tempus agent made it to the last door he was guarding, {APE}.replace => # had rigged a bucket of water to the top of a partially opened door. When the agent stepped through, the bucket fell, splashing water onto the exposed livewire at his feet, cooking him instantly.",
    "{APE}.replace => # helped his comrades clear rooms before setting up a choke point in the facility to hinder enemies attempting to flank the main force, allowing the other apes to move freely. With the assistance of a spotter, {APE}.replace => # hung upside down from a removed ceiling panel, and used a sniper rifle to pick off agents as they attempted to clear the barricade.",
    "Backing up a crew of apes as they moved forward to the first shield generator, {APE}.replace => # set up a hallway that was slicked with oil and gas, waiting until the corridor was full of Tempus agents before rounding a corner, lighting a match and saying “Did someone leave the stove on?” before tossing it onto the floor, engulfing the agents in flame. He did that several more times while attempting to workshop a better one liner.",
    "Having watch Home Alone the night before, {APE}.replace => # rigged up a number of increasingly ridiculous trapps to cover his squad members as they moved through the facility, but found ultimately that it was easier to just shoot a Tempus agent in the back of the head as they were walking through a door.",
    "{APE}.replace => # then turned a corner into one of the executive board rooms where Tempus agents were holding an emergency meeting. After killing everyone in the room, {APE}.replace => # sat down to look at a report and, after running some quick numbers, found out that the facility had been wildly inefficient last quarter due to Greg in accounting failing to file the proper documents. Heads will roll. Greg is absolutely getting fired.",
    "Managing to find an empty, fortifiable room, {APE}.replace => # worked to move wounded apes into an improvised medical bay within the halls of the Tempus facility. He managed to keep his fellow group members safe and defended their position until his compatriots had healed enough to get back in the fight.",
    "While clearing a building, an assault mech burst through a wall in front of {APE}.replace => #, who bolted back down the hallway and around the nearest corner. {APE}.replace => # noticed a hole in the ceiling, where he hid and waited for the armored machine to pass before he jumped down and stuck a thermal explosive on the back before leaping around the corner again. “Caught him looking!” {APE}.replace => # exclaimed.",
    "Bursting into one of the drone control towers, {APE}.replace => # quickly dispatched the operators, using their incapacitated bodies to barricade the door from entry while he redirected drones towards helicopters and attacked other Tempus forces.",
    "After dispatching a drone control tower, {APE}.replace => # stood on top of it with a baseball bat, several crates of grenades, and a six pack of beer raided from the officer’s quarters. He then spent no small amount of time activating each grenade before slugging it at the passing Tempus agents below in an attempt to invent a new sport he called “Ultimate One-Strike.”",
    "{APE}.replace => # then headed to one of the drone control towers where he was forced to fight in hand to hand combat while ascending a flight of stairs. Utilizing the rails to his advantage, he sent Tempus soldiers plummeting 60 feet down the center of the stairwell, impacting the ground below with a sickening squelch.",
    "Climbing along the outside of the drone control tower, {APE}.replace => # managed to clamber onto a balcony burst in through the observation window and used the broken shards like throwing stars to drop the operators before smashing the equipment and charging down the interior stairwell.",
    "{APE}.replace => # made his way to one of the drone control towers, scaling it from the outside, and locking all the windows and exits before making his way back in through the ventilation system, where he set 4 pounds of weed on fire, letting the smoke billow into the control room. By the time he got up to the control tower via the stairs, the operators were asking each other questions like “Man, why do they call it Cheeze Whiz? Is there, like, a cheese wizard? Is there a wizard of cheese, man?” and “If there is a cheese wizard, I want to eat him, man.” {APE}.replace => # then just sat down talking with them and flying drones into their agents for like 30 minutes until they passed out.",
    "Finding an excellent vantage point on the roof of a tall building, {APE}.replace => # stationed himself in an overwatch position, allowing reinforcements to safely teleport in under a comforting barrage of covering fire as they made their way into the facility.",
    "Together with some of the other apes, {APE}.replace => # reinforced a position at the bottom of a stairwell, giving the assault teams a safe entrance from the waterline, then defended the area while helping other apes escape the dangerous waters and patrolling guards.",
    "Finding his way into the drone and robotics wing of the facility, {APE}.replace => # flooded the area, releasing the robotic sharks after reprogramming them to attack their human creators.",
    "{APE}.replace => # doubled back to the ruins of the first temporal shield generator where he helped reinforce entry points for other apes to safely portal in without risk of being immediately fired upon.",
    "Along with a crew of other apes, {APE}.replace => # made his way into the warehouse building to sabotage the enemy’s supplies while salvaging any potentially useful supplies for other apes to achieve objectives. He then distributed the appropriated materiel out to squads as more and more reinforcements jumped in.",
    "{APE}.replace => # managed to secure a well stocked cafeteria with a team of other apes, who all pitched in to make dank-ass sandwiches while he tirelessly rolled enough blunts to share with reinforcements.",
    "Laying down cover fire for other apes, {APE}.replace => # helped shepherd more of his teammates into the facility. When they were clear, he helped the squad set up rubble and machine shrapnel as a barricade cover in order for further reinforcements to get to cover quicker.",
    "{APE}.replace => # discovered that one of the holes blown in the roof led to a small utility corridor just beneath the main surface of the base. He then helped map it out for other apes so that his squad members could easily avoid Tempus forces engaging above.",
    "With the help of other apes, {APE}.replace => # dragged a heavily damaged helicopter across a bridge to set up cover with the helicopter’s minigun aimed directly at the Tempus facility barracks, ensuring that any enemy reinforcements from that location were greeted with blistering gunfire immediately after emerging.",
    "Seeing other apes in danger of an assault mech armed with a flamethrower, {APE}.replace => # lept into action, using the bay door from a downed helicopter to act as a shield between the mech and the apes. Suffering grievous third degree burns, {APE}.replace => # managed to hold the barrier long enough for one of the apes in a nearby command tower to send a clutch of drones crashing into it. {APE}.replace => # was then pulled behind a more solid barrier where he was able to let the nanomachines heal his body enough to get back in the fight.",
    "Moving into the facility, {APE}.replace => # began raiding every room, pulling out desks and flipping them over into the hallways to act as movement barriers for apes to use against the Tempus agents. When caught by a cyborg working for Tempus, 6 apes leapt to his defense, giving {APE}.replace => # an opening to grab the creature’s weapon and turn it against him, utterly destroying one of the Tempus organization’s most horrifying weapons.",
    "Working with other apes, {APE}.replace => # began tearing out the ventilation grates to add a secondary mode of transportation for his crew. Using the vents, they were able to divert an entire assault platoon of Tempus agents by leading them on a chase and then flanking around behind them.",
    "Moving further into the facility, {APE}.replace => # began lobbing smoke grenades over his back as a way to cover his team’s assault. When a Tempus agent flanked him from an open doorway, {APE}.replace => # disarmed him, broke the agent’s hand, and shoved the smoke grenade into the guard's mouth as he tried to scream. As more agents rushed in, {APE}.replace => # uppercut his combatant, breaking his jaw, and sending him flying backwards into the room trailing a heavy cloud of smoke. As the other agents began coughing, {APE}.replace => # could be heard saying. “Y’ know, I know it looks cool, but that stuff will kill you.”",
    "Teleporting into hell, {APE}.replace => # sees another ape, his friend, gunned down in front of him by a team of Tempus agents. Losing all self control and sense of self preservation, {APE}.replace => # leapt forward, grabbed his friend’s guitar and used it to break the neck of the first agent he reached before bringing the improvised weapon down on the skull of another guard. {APE}.replace => # continued fighting like this until there were enough dead Tempus forces to stack into a decent barricade. Other apes surged forward and laid down heavy suppressing fire as the blood of his enemies rained down. Heavy Metal.",
    "Teaming up with an ape wielding an aztec spear, {APE}.replace => # led an assault on the air traffic control tower, grappling Tempus agents and holding them for his partner to skewer. When his partner was gunned down a cyborg, {APE}.replace => # picked up the ape’s weapon and launched it at the creature, spearing him through the chest. The cyborg casually grabbed the weapon out of his chest, snapping it in two as {APE}.replace => # ran at him. At the same time that the cyborg dropped the two halves of the weapon, {APE}.replace => # vaulted into the air, grabbing them as they fell. The ape landed a flying knee on the cyborg’s chin, and drove both halves of the weapon into the cyborg’s visor, driving the pieces into its mechanical brain and killing it.",
    "Having taken control of one of the manned rooftop turrets, {APE}.replace => # turned his ire on the air traffic control tower, peppering it with armor piercing rounds until so many chunks of concrete had been sent flying in all directions that the structure collapsed under its own weight.",
    "Joining an assault on the air control tower, {APE}.replace => # was joined by another ape with a cyber wired bat who kept talking his ear off about folk music. “Devil Makes Three is a solid starting place,” he said, mauling a man with the empowered bat. “Trampled By Turtles is a bit more traditional, but if you hit something like Days N’ Daze, you’ve strayed into folk-punk.” He then ripped a man's arm off and took a bite out of it. In between chews he said. “Not that there is anything wrong with folk-punk as a genre, I’m just talking about traditional folk music, which should.not be confused with that mainstream country stuff you hear on the radio.” {APE}.replace => # never asked about music, but he was glad this psycho was on his side.",
    "Climbing up the air control tower to get a better vantage point, {APE}.replace => # met another ape with a bow who was launching arrows at the passing drones. {APE}.replace => # had brought a sniper rifle, so they took turns seeing who could create the most havoc using a single shot. After trading weapons a few times, {APE}.replace => # managed to use the bow to shoot a helicopter pilot through the glass of the cockpit, hitting him in the neck and causing him to impact with another helicopter. The resulting explosion took out 6 drones, a patrol boat and a mech unlucky enough to be standing on the docks under the airships as they fell.",
    "{APE}.replace => # then led an assault against the air control tower, entering from the base level with his squad. They quickly dispatched a lobby full of Tempus agents before confiscating the enemy weapons and climbing into the elevator to the control room. When they arrived, loaded to teeth and prepared for a massive firefight, he instead encountered a room full of unarmed operators and one distracted Tempus soldier with a pistol screaming into a headset that he was still waiting on reinforcements. After sending the agent tumbling through the observation window, he ordered the operators file into the elevator, then tossed a handful of live flashbangs in after them just as the door slid shut. They are probably fine.",
    "Making his way forward, {APE}.replace => # joined the air support team as they cleared the way for entry outside the air control facility. Using an ape with a shield for cover, {APE}.replace => # grabbed one of the plasma rifles off a downed Tempus agent. The two moved forward in unison. One ape pushed back against melee attacks with his shield, buying {APE}.replace => # time to reload the weapon and gun down waves of enemies as they attacked. Together, the two took out more than thirty Tempus agents before the humans retaliated with overwhelming force and the two were separated.",
    "After climbing up the air control tower to launch an assault with nothing but a bow and arrows he found, {APE}.replace => # was eventually joined by another ape who brought a cyber rifle to join him. They took turns seeing who could create the most havoc using a single shot. After trading weapons a few times, {APE}.replace => # managed to use the cyber rifle to gun down a mech pilot, causing the machine to fall forward onto a bent piece of steel laying over a pile of rubble. Just before the mech crashed, a cyborg reinforcement teleported in over the steel shrapnel. When the mech landed, the steel beam acted like a catapult, flinging the cyborg into the air, head-first into the propeller blades of a passing hovercopter, which then crashed into one of the sides of the barracks; killing at least twenty additional Tempus agents.",
    "Continuing on to the roof of the facility, {APE}.replace => # encountered an assault mech wielding the tail fin of a crashed helicopter like a short sword, cleaving into small groups of apes and sending them careening out over the waterfall. Acting quickly, {APE}.replace => # called out for some of the approaching apes to get to cover while others created a distraction, drawing the mech further out onto the bridge. When the mech took the bait, {APE}.replace => # engaged the controls of one of the captured defense turrets, aiming it directly at the cockpit of the mech and unleashing a torrent of bullets directly into the torso, killing the pilot and pulverizing the giant robot.",
    "On his way through the facility’s interior halls, the ceiling crashed down on {APE}.replace => # as he was found by a construction mech pilot and snatched up onto the roof. Just before he could be crushed, an ape with a cyber sword jumped in, slicing cleanly through the hydraulic lines of the left arm, causing the mech to release its grip. As the mech stomped on the sword-wielder, the other ape tossed the cyber sword over to {APE}.replace => #, who caught it and lunged upward with all his might, piercing the chassis and slaying the pilot before the man could eject.",
    "Climbing into the cockpit of a downed mech, {APE}.replace => # realized that the controls were basically fried. The downed mech was only able to move its heavy machine gun arm with limited range. Thinking quickly, he called out to a nearby ape wielding a laser scepter, directing him to attack the ankle of a nearby mech. {APE}.replace => # proceeded to unload the entirety of the ammo supply of the titanic machine gun by holding down the trigger for a full minute. The rounds hit the Tempus pilot indirectly, but it was enough to send the mech teeter tottering backwards over the side of the bridge before it could regain balance.",
    "Shortly after emptying all the rounds from the cyber rifle that {APE}.replace => # had picked up from a fallen comrade, he was confronted by the thunderous sound of a mech sprinting across a rooftop bridge toward a group of ape reinforcements. Using only the cyber bayonet attached to his rifle, {APE}.replace => # managed to leap out from his position and hamstring the hydraulics powering the right leg of the mech. This caused the large machine to fall forward on its own momentum. It crashed to the ground just as {APE}.replace => # climbed on the back of it and punched the bayonet through the power cell of the mech, releasing a spray of burning liquid metal out of both sides. As he jumped clear, he could hear the screams of the pilot being cooked alive.",
    "While running forward on the bridge with a group of ape reinforcements, they were shaken by a mech landing behind them and opening fire directly on their location. A spray of concrete and limbs knocked {APE}.replace => # from his feet. In the chaos, a fellow ape landed on top of him, shielding him from the second volley of fire. As he died, the ape pressed a cyber staff into {APE}.replace => #'s grip. As ape stood up, the mech reloaded for a final spray of heavy machine gun fire. {APE}.replace => # ran forward, screaming at the top of his lungs, and threw the staff as hard as he could. In a one in a million shot, {APE}.replace => # managed to pierce the mech’s cockpit, driving the staff directly into the heart of the pilot.",
    "Cornered on a large rooftop, {APE}.replace => # stood back to back with an ape wielding a chainsaw. A damn chainsaw. Using pistols from downed Tempus agents to cover them from ranged advances, {APE}.replace => # watched his teammate rip and tear through wave after wave of melee combatants. When a mech began climbing up from below, one of the machine’s outstretched hands landed directly on the ape in front of him, sending the chainsaw flying. {APE}.replace => # darted to grab the weapon, spinning back around to carve through the rising cockpit of the mech as it lifted itself back up onto the bridge.The spinning chain sent shards of glass flying while gouts of the pilot’s blood splattered the interior of the cockpit before the suddenly immobile machine fell backwards over the waterfall.",
    "Moving over to one of the turret towers, {APE}.replace => # climbed up with a bandolier of grenades, arming them and tossing them up to hook on the barrels of the turret before leaping clear of the explosion, narrowly avoiding getting knocked to the reservoir below.",
    "{APE}.replace => # infiltrated the barracks silently, armed only with 7 bandoliers of grenades. Staying out of sight of the Tempus agents, he began affixing the pins of the grenades to everything; weapons inside of the weapons rack, the inside of lockers, the underside of toilet seats, all of the exits, even the shutter blinds. When he was done, he waited for the outside team to begin sewing chaos. As the attack kicked into higher gear, another alarm was raised, and agents began running to arm themselves. {APE}.replace => # snuck over to a corner of the barracks that was well clear of the blast zones and lay beneath a bunk bed, eating chips that one of the agents had left out, and trying to suppress his giggles every time another explosion went off next door.",
    "Reacting instinctively to save the life of a nearby grievously wounded ally, {APE}.replace => # jumped out of cover to shove the already injured ape away from an airborne grenade. He was far enough away that it was survivable, but some of the shrapnel hit him directly in the eyes, blinding him just as he saw a squad of Tempus soldiers rush forward. After {APE}.replace => # realized he was the only capable fighter, he charged forward, relying on call outs from his more grievously wounded companion to avoid damage long enough for a Tempus agent to get close enough to grab. By the time the nanomachines in his body had repaired his eyesight, he had killed 5 Tempus agents, and immediately helped the other ape find a place to safely heal.",
    "Rounding a corner into the biological research wing, {APE}.replace => # was horrified to witness a cyborg pulling the head off of one of his fellow apes. The cyborg watched as the nanites futilely tried to reattach it to the body. As the dead ape relaxed his grip, he dropped an enchanted axe. Wasting no time, {APE}.replace => # ran forward, grabbing the weapon off the ground and burying it through the top of the cyborg’s head, cleaving it in half to the clavicle.",
    "Making his way through the interior of the facility, {APE}.replace => # heard the din of battle coming from the bombed out remains for the first shield generator. As he entered the door, he saw seven dead apes at the feet of a single cyborg ape who was trying to finish attacking three other apes trying to flee. Using the distraction, {APE}.replace => # grabbed a studded club from one of his fallen companions and used it to cave in the skull of the killing machine that eerily resembled a version of himself.",
    "While fighting on the bridge, {APE}.replace => # and his team were assaulted by a cyborg ape. It displayed a faster reaction speed and an enhanced strength by comparison, but one of {APE}.replace => #'s teammates managed to bury a machete in the creature’s chest before the cyborg crushed his skull with a single hand. The cyborg then lifted {APE}.replace => # up in the air with the other hand before a rogue rocket blasted it off of the bridge and into the waters below. Without letting go of {APE}.replace => #, the cyborg began to sink like a stone; pressing his other hand to the top of {APE}.replace => #'s head and beginning to squeeze. In an act of desperation, {APE}.replace => # pulled the machete free of the cyborg's chest, stabbing over and over again until managing to sever the spine of the creature through its neck. As the cyborg released him, {APE}.replace => # was able to kick free and swim to the surface.",
    "Seeing two Tempus agents in exosuits planting a teleport beacon on a restrained ape, {APE}.replace => # rushed forward. The incapacitated ape on the ground was tangled in an electro net. Leaping over the downed ape, {APE}.replace => # used his momentum to dropkick one exo-suited guard into the other. Grabbing the net and pulling his friend free, {APE}.replace => # bore the pain of the electric net in order to throw it over the two Tempus agents, shutting down their exo-suits and immobilizing them.",
    "As he made his way through the facility, {APE}.replace => # noticed some of the Tempus reinforcements in exo-suits. They carried large net-launchers. {APE}.replace => # watched as they attempted to capture the apes for an unknown purpose. Waiting around the corner, an ape handed him a weapon to help with the surprise attack; a bat that had been lit on fire. {APE}.replace => # waited until the first agent walked through the door, cracking the man’s face open with the flaming bat and sending him crumpled to the floor. As a follow up, his fellow ape rolled forward, grabbed the agent’s weapon and fired it; launching an electrified net at the second agent whose exo-suit seized and locked up as it short-circuited.",
    "After securing a position on the rooftops, {APE}.replace => # noticed that a Tempus agent in an exo-suit was attaching some kind of a beacon to one of the downed apes three hundred feet away. As the Tempus agent stood up and walked away, the captured ape vanished in a portal, along with the beacon. {APE}.replace => # called this out to his team to relay the strange event before picking up a sniper rifle and dropping the enemy as it ran.",
    "Moving through to the roof over the second shield generator, {APE}.replace => # noticed two Tempus agents setting up a teleporter bacon over a downed ape. Cartwheeling forward, {APE}.replace => # managed to throw the downed ape clear before slapping the beacon back between the Tempus agents. The portal that opened left a small five foot crater where half of each agent lay cauterized and smoldering at the bottom.",
    "Rounding a corner after making his way into the facility, {APE}.replace => # noticed two Tempus agents in exo-suits setting up a teleporter beacon next to an ape who had been pinned to a wall with two spikes. The spikes appeared to be delivering intermittent pulses of energy strong enough to char the ape around his wounds. Moving swiftly, {APE}.replace => # shouldered one of the Tempus soldiers out of the way, pulling a spike free of the incapacitated ape and plunging it into the other agent’s chest. As the first agent recovered, {APE}.replace => # managed to pull the other electro spike free, releasing the downed ape before being knocked into a wall by the agent, causing him to drop the weapon. As the electro spike fell, {APE}.replace => # caught it with his foot. When the Tempus agent moved to pin him against the wall, {APE}.replace => # cartwheeled backwards, bringing the spike up and slamming it deep into the agent’s head.",
    "Moving forward with a team to establish a foothold near the main power plant, {APE}.replace => # laid down covering fire to suppress the Tempus forces, driving them back and buying his squad members time to rapidly fortify a position to stage the assault.",
    "{APE}.replace => # led his team forward; pressing the assault on the power plant. When a group of Tempus cyborgs arrived to repel the intruders, the apes met the cybernetic abominations with overwhelming force, driving Tempus agents further back into the facility.",
    "Taking advantage of the covering fire and melee assistance of his squad, {APE}.replace => # hacked into the power plant’s main doors, unlocking them and allowing his group to move forward, pressing the assault.",
    "Upon entering the main power plant with the first wave assault team, {APE}.replace => # leapt at one of the ceiling turrets as it gunned down a member of his team. Ripping the gun assembly out of its foundation, {APE}.replace => # felt it begin to rapidly overheat, and tossed it behind a group of approaching Tempus agents as he fell back to the ground. In the blink of an eye, the power cell exploded, engulfing the enemies in a brief burst of incandescent blue flame, clearing the assault team to advance further.",
    "As the apes pressed the attack on the power plant, {APE}.replace => # noticed some of the Tempus agents hiding behind polarized kinetic barriers that allowed them to fire projectiles at the apes through the shielding, but would reflect any projectiles the apes used to return fire. Moving stealthily through side corridors to get behind the enemy’s lines, {APE}.replace => # tracked down the computer operator responsible for the shields, and quietly snapped his neck before reversing the polarity on the shields, causing the Tempus agent’s fire to reflect inwards at them from point blank range while allowing the apes to continue their assault unimpeded.",
    "After finding a relatively undamaged mech with a dead pilot inside, {APE}.replace => # spent most of the battle tinkering with the onboard computer and figuring out how to engage autopilot. Rigging explosives to the power core, {APE}.replace => # sent the mech careening into the guardhouse command center, obliterating the doors and charging inside before detonating in a massive explosion that rocked the facility.",
    "Making a high-risk early play for the prison facility, {APE}.replace => # moved underneath the covered bridge, swinging along exposed beams. When he arrived at the other side of the bridge, he began placing mines on the exterior of the facility, blowing a hole in the side of it to gain entry. Before he could make it inside, however, he was discovered by a drone and had to double back, avoiding fire from both of the prison guards as well as the drone before managing to regain his footing on top of the bridge, dispatching a guard, and using his rifle to disable the drone’s engine system with a well aimed shot.",
    "After joining the guardhouse assault raid, {APE}.replace => # stumbled upon a weapons storage locker that had been remotely unlocked by one of the apes who had infiltrated the control rooms. Seizing the advantage, he began distributing weapons and ammunition to every ape he could find. Finally, when the storage locker was empty he realized he hadn’t gotten a weapon for himself. As {APE}.replace => # took one final look, he uncovered a crate holding a rocket launcher with a few rounds of ammo. It was time to make that everyone’s problem.",
    "On the far end of a bridge, {APE}.replace => # helped cover the invading forces as they raided the guardhouse. As Tempus agents sent drones to flank the ape invaders, {APE}.replace => # stood back with a sniper rifle, picking off dozens from a hidden vantage point. By the time the Tempus agents gave up on their counter attack and located his position, his ammunition had run out and he was on to the next assignment.",
    "Although it was not the same as the one in the compound he had come from, {APE}.replace => # recognized the heavy security and fractal helmet designs on the elite soldiers guarding the Looking Glass Facility. He quickly relayed his position to a team of apes, organizing an assault by first hacking into the ceiling, wall, and floor-mounted turrets; turning them against the guards.",
    "Leading the charge on the Looking Glass Facility, {APE}.replace => # hurtled forward as recently hacked turrets laid waste to the elite soldiers guarding the doors. By the time Tempus agents managed to shut down the turrets, apes were already in melee range, over-running them and laying explosive breach charges on the doors."
  ];

  var winArr3 = [
    "Finally, as his mission was nearing completion, {APE}.replace => #'s position was swarmed by Tempus reinforcements. As members of his unit were gunned down around him, {APE}.replace => # fired his weapon until his ammunition ran out. He then grabbed the weapons from his fallen comrades and continued to return fire. As the waves of agents continued to press his position, he switched to hand to hand combat, buying enough time for others to retreat. When the dust settled, {APE}.replace => # stood alone in a bottlenecked hallway, surrounded by the remains of fallen foes. Gulping in air while covered in burns and blood, he ported home.",
    "With a shield generator destroyed, apes could begin teleporting directly into the facility, but so could Tempus agents. Elite squads of capture teams began flooding in as {APE}.replace => # set up positions to counteract and maintain supremacy over their position. Raining hot lead like he was a fire truck at a protest against his right to live, {APE}.replace => # gunned down the enemy, making sure his fellow apes could extract themselves before abandoning the overrun position to return to base.",
    "While Ape was fighting his way through the Tempus facility, a shockwave caused the entire building’s structure to tremble. Recovering his footing, {APE}.replace => # looked up through one of the holes in the ceiling and saw that a massive transport ship had appeared overhead. Tempus agents began to deploy overhead as {APE}.replace => # climbed up through the rubble to reach a vantage point. Reaching one of the abandoned anti-aircraft turrets, {APE}.replace => # started blasting Tempus agents out of the sky as they were deploying. Ape mowed down the reinforcements until other apes were able to get into a defensible position. Once his team had reached relative safety, {APE}.replace => # exited the combat area.",
    "After the first shield generator was dispatched, {APE}.replace => # began to make his way through the facility, only to have 2 Tempus agents in exo-suits teleport in front of them. One began throwing up as the other held on to a wall to support himself. These idiots acted like they’ve never reality-jumped before. {APE}.replace => # ran up, jumping on the first agent, crushing his face into the pool of vomit before delivering a whirlwind of blows to the knee, ribs, throat and nose of the other. As they struggled to recover, {APE}.replace => # took one of their sidearms away, casually checking the heft and sights before firing several shots to their chests and heads, then casually discarded the pistol in favor of a larger rifle before activating his nanobanana to return to base.",
    "Finally, finding themselves in the bombed out remains of a guard tower, {APE}.replace => # stood side by side with the only remaining member of his team as they laid waste to an endless tide of Tempus reinforcements. As the bodies stacked up around them and their ammo ran dry, the two apes looked at each other. Knuckling up for a final stand, they leaped from their barricade, tag-teaming agents as they dropped from a ship overhead. By the time there was a break in the action, {APE}.replace => # counted a trail of 69 corpses in just his wake alone. Nice. The two apes made it safely to an extraction point and escaped.",
    "As other apes ran for cover against Tempus reinforcements, {APE}.replace => # ran forward. With bullets tearing through his flesh, he leapt at the first agent. Using his feet for grip and balance, {APE}.replace => # used his hands to pull the pins on the grenades affixed to the agents belts before repeating the process; swinging rapidly from one agent to the next, crawling over armor plated torsos to dodge what gunfire he could. After being struck several more times by unavoidable ballistics, {APE}.replace => # finally jumped clear as twenty Tempus agents exploded like popcorn. As he laid on the ground, spitting blood from his lungs, one of his fellow apes activated {APE}.replace => #'s nanobanana for him, allowing him to teleport back to the relative safety of the apes' compound.",
    "Upon receiving reports of the failed assault on the second shield generator, {APE}.replace => # raced to provide reinforcement and cover for the ape’s retreat. As he ran, {APE}.replace => # encountered, an ape pinned down in a hallway by 2 Tempus agents in exosuits, wielding energy shields and plasma rifles with cyber bayonets. They had advanced with a line of other agents behind them from a wing in shield gen 2. When he looked at their feet, he saw a trail of dead apes and fallen tempus agents. {APE}.replace => # pulled the pin on two shock grenades and called to the ape in front of him to retreat. The two desperately lept down hall, avoiding gunfire. As the grenades went off, the agent’s shields faltered and their exo-suits locked up. The retreating ape was able to fire off several rounds of his sidearm, killing the agents in front. The following row of agents then lit their powershields, stepping over the paralyzed units and marching forward after escaping apes. The two used the moment to race to the extraction point and portal away.",
    "Taking a moment to catch his breath and heal from a previous battle, {APE}.replace => # made his way into a large empty room. He saw several empty grenade crates, along with a handful of abandoned sonic grenades. As the nanites stitched his wounds closed, he had time to survey his surroundings and noticed that the structural integrity of this entire area was almost completely compromised, with way too much weight being supported by a single cracked support pillar. Just before he could relocate, a large mixed group of Tempus forces, including agents, several cyborgs and a dozen elite guards in power armor funneled into the room, intending to secure a forward base of operations. They never noticed {APE}.replace => # as he quietly snuck over to the support beam and wedged one of the live grenades into a fissure on the surface, then pulled the pin as he dove over the heads of the enemies back through the door, kicking it closed as he passed a moment before the resulting explosion collapsed 6 tons of rubble onto the enemies below. With a smirk, {APE}.replace => # sauntered to an extraction point.",
    "{APE}.replace => # was knocked from his feet as a displacement shockwave blasted out from where a teleporting skyship entered the fight just above the facility. Taking a second to recover, {APE}.replace => # looked on in horror as drones began pouring out of the sides of the ship. Taking swift action, {APE}.replace => # ran across the roof, hijacked an empty helicopter and started pressing buttons and pulling levers until the vehicle was in the air. {APE}.replace => # flew the helicopter directly into the side of the drone carrier, unleashing the full complement of the aircraft’s weapons before grabbing a parachute and jumping to safety at the last seconds. As the helicopter impacted with the ship’s drone port, a chain of detonations tore into the surrounding swarms of drones while {APE}.replace => # escaped and activated his nanobanana as soon as he reached the extraction area outside the remaining jumpshield.",
    "Pursued by cyborgs, {APE}.replace => # noticed a familiar ape collapse, too injured to continue evading. Doubling back, {APE}.replace => # dove on top of his ally, using himself as a shield while activating the wounded ape’s nanobanana, taking several hits while they teleported to safety. {APE}.replace => # woke up hours later in an infirmary.",
    "With his entire squad captured and restrained by cyborg apes, {APE}.replace => # could do nothing but scream as some of his restrained comrades were dragged off and dismembered; their bodies collected and teleported away for unknown reasons by Tempus agents. Shortly after this began, a blinding flash and shockwave rolled down from above the facility, forcing the Tempus agents to stagger. One dropped a monofilament blade that smoothly glided through the ape’s side, piercing him but fortuitously cutting through his wrist restraints. Finally unbound, {APE}.replace => # pulled the sword free from his wound. He then turned the blade on his enemies, slicing easily through two of the cyborgs on either side of him before dispatching the rest of the agents in a wild flourish. Wounded and bleeding, {APE}.replace => # freed his remaining squad members and together, they made it to extraction.",
    "While running with his team to the next objective zone, they were hit with a blast from a shock cannon as a Tempus aircraft teleported in overhead. The blast sent apes flying in all directions. As he tumbled over the bridge towards the rocks beneath the waterfall, {APE}.replace => # spotted an unconscious ape in free-fall near him. Angling his rapid descent to get closer to the doomed ape, {APE}.replace => # managed to reach out and activate his nanobanana just before they both impacted; safely extracting himself and the other member of his squad.",
    "While falling back to the extraction point, {APE}.replace => # was hit through the back by a nanite disruptor stake that detonated at the exact instance the teleportation process activated. This caused the nanite shield to deploy incorrectly, leaving him exposed for an instant. {APE}.replace => # experienced every possible reality of his life with lower back pain as the nanites in his lower spine were obliterated. When he arrived at the ape base, he was taken to the infirmary where he spent the next 8 months recovering and had to relearn how to walk. Due to his raw exposure to uncountable timelines, {APE}.replace => # was permanently left uncertain if this was the true reality or just another figment of the infinite alternatives he experienced during the jump.",
    "In a desperate attempt to escape a pursuing cyborg equipped with wicked-looking knuckle-claws, {APE}.replace => # pushed one of his teammates toward the extraction point before being stabbed through the back with the anti-nanite talons. As {APE}.replace => # looked down, he noticed his blood begin sizzling on the ends of the blades, seeming to interfere with his nanite healing functions. Before the cyborg could bring its other arm around to restrain {APE}.replace => #, his squadmate managed to snatch {APE}.replace => #'s wrist and yank him forward, off of the blade and into the extraction zone. His rescuer, returning the favor, immediately activated his nanobanana. The pair of them safely teleported away with {APE}.replace => # still brutally injured, but decidedly alive.",
    "Running full-tilt toward the extraction point, {APE}.replace => # was intercepted by a cyborg ape who rounded the corner on him, swinging a cyber bat directly at his head. Reflexively raising his hands at the last moment, {APE}.replace => # was still clotheslined by the swing and his right arm fractured when it deflected the antinanite weapon. As {APE}.replace => # recovered his breath from the impact with the ground, the cyborg began closing in for a finishing blow before it was shot through the chest by one of the apes guarding the extraction team. Leaping up from a prone position with his unbroken arm, {APE}.replace => # tore the bat away from the cyborg, delivering a skull-crushing blow before limping back to the extraction area.",
    "While leaping over a firing line of Tempus agents as they took aim at apes running to the extraction point, {APE}.replace => # took multiple hits through the back. Adrenalin blocked out most of the pain as he hurled other downed apes across the line to safety. By the time he made it to the extraction point himself, {APE}.replace => # looked down only to realize his wounds weren’t healing. Blacking out as he teleported to safety, {APE}.replace => # woke up in recovery at the infirmary where he was informed he took 12 anti-nanite bullets to save 13 apes. “Hah.” he said, moments before passing back out. “I dodged one.”",
    "Upon fulfilling his mission and heading to extraction, {APE}.replace => # was speared through the calf with an anti-nanite lance. When he put his weight on the leg during his next stride, the bone splintered, tearing through muscle and skin as he collapsed. Just as a Tempus agent ran up to deliver a killing blow, {APE}.replace => # threw his own severed foot at the soldier’s head with enough force to break the man’s neck. From there, he was dragged by other apes to the extraction point, passing out before waking up in a bed with his newly reformed limb in a cast, forced to heal slowly.",
    "Relying on his incredible healing, {APE}.replace => # took multiple wounds as he bullied his way through a Tempus agent barricade on his way to extraction. When he stood up, he found that a knife had been buried in his back. Upon pulling the knife out, he recognized it as one of the anti nanite weapons belonging to the cyborgs. His head swam as he continued to run, stumbling and ultimately collapsing near the extraction point as his wounds were prevented from healing. Rolling to his back, he watched as one of the cyborg apes lept at him, aiming to come down with a knee on his skull. A moment after he closed his eyes and thought of happier times, he felt a nanobanana slide into his hands from one of the ape extraction teams. As {APE}.replace => # activated the nanobanana, narrowly avoiding death, he resolved then and there that he would make even happier times, with black jack and hookers.",
    "While surfing on a bucking drone and shooting grenades at the Tempus agents below, {APE}.replace => # was blown out of the air with an anti-nanite round that exploded his drone and sent him careening down to the facility. Though he had just suffered a near-fatal gunshot wound and a bad landing, {APE}.replace => # was overheard saying, “That has to be some kind of rodeo record,” before teleporting to safety.",
    "Lunging towards a spear tossed by a cyborg, {APE}.replace => # managed to grab it by the blade before it reached the head of an unwitting ape. In one smooth motion, {APE}.replace => # spun and hurled it back at the monstrosity, spearing it through the chest, and causing it to topple into another Tempus agent. When he examined his hand, he noticed that the nanites trying to mend his wounds were dissolving into a fine mist, rendering his hand useless for the fight. Rather than risk a senseless death, {APE}.replace => # returned to base.",
    "Fighting to get his crew out to the safety of the extraction zone, {APE}.replace => # witnessed two cyborgs overpowering an ape next to the edge of the bridge overlooking the waterfall. After hearing detonations in the first jumpshield generator, {APE}.replace => # saw his chance. He shouted “GO” to his team, clasped his nano banana with his foot, then leapt forward grabbing both cyborgs and falling over the railing with them. Kicking off of them on the way down, he had time enough to rip his vape before teleporting to safety. When he appeared back at the ape base, he exhaled a sweet, cottony cloud of victory.",
    "Before heading out, {APE}.replace => # spent time threading a thin wire through the pins of fifty grenades before gently placing them in a backpack and rapidly departing. While inbound to the extraction area, he found a group of Tempus agents and cyborgs assaulting the retreating apes. Leaping over one of the cyborgs, {APE}.replace => # tossed the loops of the backpack around the creature’s neck, pulling the wire, and bringing all fifty pins with him. Utilizing the resultant chaos of the action to escape, {APE}.replace => # made it across the extraction safety line just in time to turn around and watch as a massive explosion vaporized the entangled cyborg and melted the limbs of six other foes. Seeing the sizable hole in the pursuing Tempus forces, {APE}.replace => # went home.",
    "After procuring an advanced assault rifle from the Tempus armory, {APE}.replace => # headed toward the extraction zone. With a full magazine of ammunition, {APE}.replace => # aimed at several Tempus agents, only to watch them get gunned down by other apes as they made their escape attempt. Moving patiently, in no rush to waste a single shot, {APE}.replace => # made his way closer to the extraction where he was approached by a cyborg. {APE}.replace => # excitedly released four blasts, evaporating the cyborg's legs below the knees and blowing off its left arm, then followed up with a round directly in the chest of the creature. As he turned to look for more combatants, {APE}.replace => # realized that his crew had already secured the area. Suddenly, the first temporal shield generator flickered and failed just before he felt the cyborg’s remaining hand close around his ankle. Filled with resignation, {APE}.replace => # unloaded the remainder of the weapon into the skull of the cyborg, then commanded his team to evacuate.",
    "# attempted to reach a safe place to teleport by dashing across a bridge. As he did, a shockwave nearly knocked him off his feet as a Tempus airship teleported above the facility, sending troops of reinforcements into the battle. Before he could break into a run, a cyborg impacted in front of him, raising up a solid two heads higher before raising his fists to attack. {APE}.replace => # raised his own fists. The two ran at each other as fire rained from the sky around them. {APE}.replace => # launched a flurry of blows that was met with almost inconsequential recognition from the cyborg who dropped an elbow that knocked {APE}.replace => # into the floor so hard, he felt three molars crack. Launching up from a prone position, {APE}.replace => # headbutted the cyborg in the chin before reaching up and gouging out his eye-lenses with his thumbs. As the cyborg swung blindly, {APE}.replace => # jumped on to its back, sinking his teeth into the creature’s brainstem before ripping it out in a spray of foul blood and machine oil. As the cyborg fell dead, {APE}.replace => # activated his nanobanana, spitting out bits of metal and bone.",
    "After raiding the barracks, {APE}.replace => # found a plasma grenade, a sock, ultra polyurethane glue, and a bottle of scotch. He spent a few moments coating the sock in glue before heading out towards the extraction point. On his way, he found a cyborg blocking his path. Activating the grenade and dropping it into the sock and tying it off with a knot, {APE}.replace => # slid under the cyborg’s legs before slapping the sock in the middle of the evil creature’s back. As the cyborg attempted to remove the impromptu sticky grenade, {APE}.replace => # twisted the cap off the bottle of scotch, taking a sip as he ported out, never bothering to look at the colorful explosion that came from the obliterated cyborg.",
    "Making his way through the facility, {APE}.replace => # was confronted by a cyborg in the hallway. Throwing a punch at the cyborg’s chin, the ape felt his knuckled break on impact as the cyborg lifted him up by the chest and slammed him through a wall into the hydro electric facility as it flooded with water. Cutting his losses, {APE}.replace => # managed to hold his breath with one collapsed lung long enough to swim out into the reservoir and teleport home.",
    "After climbing into a mech and piloting it to great effect, a shockwave rocked the bridge {APE}.replace => # stood on. He looked up from the cockpit to see a Tempus airship had ported in above the facility, sending reinforcement raining down. Working to regain his momentum, {APE}.replace => # fired on the Tempus agents as they landed. In retaliation, he was struck by a sonic blast that overheated the mech’s power cell and caused it to explode, spraying gouts of liquid flame in all directions. Piloting the giant robot toward the extraction point, {APE}.replace => # left a river of burning carnage in his wake before being blown clear of the mech, though the cockpit and into the extraction point. Still smoking and covered in chemical burns, he teleported to safety.",
    "Having accomplished his task, {APE}.replace => # headed back into the battle to help others escape. After rescuing 26 apes, a shockwave from the appearance of a Tempus airship collapsed the rooftop he was running on, burying him under a pile of rubble. Unable to move or escape, {APE}.replace => # activated his nanobanana and ported to safety.",
    "After clearing apes from rubble resulting from a shockwave of a Tempus airship delivering reinforcements, {APE}.replace => # decided to make a run to safety. Hearing an explosion overhead, he looked up to see a helicopter falling out of the sky directly at him. Upon feeling something impact his foot, he looked down to see a nanobanana and grabbed it, sparing a glance to see an ape he had previously saved returning the favor, allowing him to portal out just in time.",
    "Deciding to head back into the chaos instead of leaving immediately, {APE}.replace => # managed to cause enough of a distraction to let a small squad of ape slip past to extraction. As a result, he was scooped up by a construction mech and tossed eighty feet in the air as a volley of rockets careened toward him. {APE}.replace => # managed to portal out just in time to avoid a fiery death.",
    "After running out of ammunition, {APE}.replace => # made his way to the extraction point. As he reached for his nanobanana, he noticed an incoming grenade and lept to block it, slapping it back into a group of Tempus agents before wagging his finger and saying “No, not in my house!” He paused to watch the explosion before teleporting to safety.",
    "Rushing towards the extraction line, {APE}.replace => # raced along the edge of the bridge, pausing only to grab a plasma gun and fire on waves of Tempus reinforcements until the barrel melted. {APE}.replace => # managed to make his exit relatively unscathed.",
    "Stopping for a moment to catch his breath before continuing, {APE}.replace => # walked into the restroom to wash his face and freshen up. Taking a look at himself in the mirror, {APE}.replace => # was forced to confront the reality of his place within the cycle of violence. As he turned to leave, he stepped on a nano banana, slipping on and activating it, causing him to return early.",
    "Finding a shower room within the facility, {APE}.replace => # discovered a Tempus uniform, putting it on and walking around to explore the compound. Noticing an elevator, {APE}.replace => # walked inside it only to have six Tempus agents follow behind him. As the doors closed, they looked at each other, then at him, then at each other again. Clearing his throat to break the tension, {APE}.replace => # said “Oh are you all going down? I missed my floor.” He then ripped a knife off the belt of the nearest Tempus agent and jumped through the elevator’s ceiling hatch before slicing the cable and engaging the nanobanana as the elevator plummeted.",
    "{APE}.replace => # then worked his way into a building containing repair bays until he heard the distinctive sound that heralded the arrival of Tempus reinforcements nearby. Pausing at a nearby control terminal, {APE}.replace => # hacked into the facility’s bay doors. Moments later he forced them to slam shut suddenly, crushing an emerging mech and jamming the locking beyond repair. Satisfied, {APE}.replace => # returned to base.",
    "Finally, as an act of petty chaos, {APE}.replace => # broke into the commissary wing and changed all the labels on the salt and sugar containers before heading back to base.",
    "Moving to the extraction point, {APE}.replace => # got the drop on a Tempus agent wielding a shield. In an effort to save time, {APE}.replace => # just ripped the man’s arm off, taking the shield with it in order to protect himself from gunfire until he could reach the safe zone and portal out.",
    "{APE}.replace => #, attempting to accomplish a bonus objective, managed to infiltrate the biological testing facility. Sneaking through until he located an unlocked terminal, {APE}.replace => # was able to easily hack into the researcher’s database and steal an obscene amount of encrypted data. “Good thing Tempus never uses Wallet Guard,” joked the {APE}.replace => # while he unplugged the hard drive, logged out, and escaped.",
    "Confronted by an elite agent wielding an unfamiliar weapon, {APE}.replace => # took a moment to register that it was attached to a pressurized tank strapped to the agent's back. As the agent pulled the trigger, some kind of liquid nitrogen-like chemical gushed out in a stream that froze everything it touched. Doing quick math to assess the situation, {APE}.replace => # fired a round at the tank, porting to safety just in time to avoid an explosion that froze everything in the hallway.",
    "Pinned down in a series of hallways as a cyborg attempted to tear him apart, {APE}.replace => # leapt from wall to wall; avoiding the attacks while baiting the cyborg into swinging wildly. When a Tempus agent rounded the hallway to back up the cyborg, {APE}.replace => # jumped on him, flipping off the cyborg who threw a devastating punch in their direction just as the ape teleported to safety.",
    "Charging ahead to continue the fight on the roof, a shockwave rattled the battlefield. Above the facility, a massive Tempus airship teleported into view; carrying new waves of reinforcements. {APE}.replace => # resolutely stood his ground, killing many foes as he exacted a blood toll for every step they took, but he was ultimately forced to retreat when his position was overrun by the surging wave of enemies.",
    "As a shockwave rattled the facility, a Tempus airship teleported into view of the rooftops. From his position at the drone control station, {APE}.replace => # had one of his squad members load a drone with EMP grenades before flying it directly at the ship just as six mechs began descending to the rooftop below. When the EMP went off, the drone was disabled, but so were the mechs, who consequently fell to earth, unable to trigger their inertial dampening jet thrusters. The impact shattered, then demolished an entire section of bridge where Tempus agents were rallying. Noticing one of the airships weapons swinging toward his position, {APE}.replace => # decided it was time to portal to safety.",
    "Standing at the outside of a central control room and laying down suppressive fire, {APE}.replace => # held off a capture squad while his team downloaded crucial information and prevented internal kill switches from being triggered. Once the download was complete and his team was clear, they ported to safety as a unit, as if they were never even there. Well… save for the scorch marks, bullet holes, bodies and rubble found in the hallway.",
    "Running for his life from a gaggle of elite soldiers, exo-suits agents and cyborgs, {APE}.replace => # doubled back to shake his pursuers and stumbled through a holographic wall hiding a long hallway. When he reached the end, he darted through a slowly closing door that sealed behind him. Inside, he realized he had discovered a panic room reserved for the top brass, and just happened to make it here as they locked themselves inside… with him. Nine hours after he was supposed to extract, he ported back to safety. When he arrived, blood-soaked and tired, he had a look in his eyes that made some of the other apes a little nervous to ask him what took him so long.",
    "After saving all the apes he could, {APE}.replace => # spent the rest of his time moving among the battlefield, recovering the dead and collecting the ID tags from those who had fallen to Tempus forces. The last dog tag he recovered had his own name on it, matching identically with the one around his own neck, but with a little more wear and tear. Terrified and refusing to attempt to comprehend the paradoxical possibilities, {APE}.replace => # shouted “NOOOOPE” and immediately teleported back to base.",
    "After clearing an entire floor of Tempus forces while deep within the facility, {APE}.replace => # moved to the elevator, forced the doors open and climbed up a couple of stories before hearing the cable snap above. As the elevator screeched downward towards him, {APE}.replace => # managed to portal out just before impact.",
    "After diving into a janitorial closet to avoid gunfire, {APE}.replace => # looked around, scratched his chin for a second before realizing within moments that the chemicals and supplies could easily be combined to create an improvised gas bomb. By the time the agents advanced down the corridor to his location, he pulled a grenade pin and opened the door as he activated his nanobanana. As he disappeared, the device detonated, flooding the area with a cloud of caustic death.",
    "{APE}.replace => # rounded the corner and was immediately confronted by an ape that looked identical to him. After staring at each other for a moment the other ape said “Oh, I remember this. Hey, you need to get to the extraction zone now.” The ape then clapped him on the shoulder, gave a knowing wink, and continued further into the facility. Figuring that he had accomplished enough for one battle, {APE}.replace => # teleported to safety, deciding to put that weirdness behind him.",
    "Gaining a momentary reprieve, {APE}.replace => # decided to explore the bioengineering lab. Looking over the different items, he found an interesting vial of liquid with all sorts of hazard labeling within a cold locker. When a cyborg walked in on him {APE}.replace => # said “Think fast, robo-dick,” before lobbing the concoction at him. As the container impacted and the chemical was exposed to air, it immediately bubbled up and expanded rapidly into a viscous foam, restraining the cyborg and producing a heat so intense that it caused {APE}.replace => # major discomfort from twenty yards away. The cyborg began smoking before catching on fire, stagnant blood and machine oil boiling out of its ears, eyes, nose, and mouth before baking in the heat. After pausing to consider the state of the scene, {APE}.replace => # decided he was done playing in the lab and just ported out.",
    "In the unpredictable chaos of battle, deep within the facility, {APE}.replace => # encountered a strange ape he hadn’t met before. “Oh! Hey! You’re the guy who saved my life!” The ape then handed off a dented shield to him before continuing on. Carrying the shield with him, {APE}.replace => # later rushed to the extraction point where he saw the ape from before. Seeing the glint from a sniper scope, {APE}.replace => # tackled the familiar ape. In doing this, the sniper round impacted the shield in the exact same spot as the dent, further buckling the shield. As the familiar ape thanked him, it became clear that he didn’t recognize {APE}.replace => #. Using the opportunity to extract, they ported to safety. {APE}.replace => # would have trouble sleeping for the next year; thinking about where the first dent in that shield came from and whether or not to continue the paradox by giving it back to the ape who hadn’t yet given it to him in the first place. It was all a bit confusing.",
    "{APE}.replace => # managed to infiltrate the back-up strategic command center during a camera blackout. While inside, he began downloading classified information from a computer while avoiding Tempus agent foot-traffic. While the download was completing, {APE}.replace => # overheard a woman speaking. “Their goal isn’t the prison. The fact of the matter is that they are playing our hand. Either we give up one of their leaders and lose a potential insight into their plans, or we risk our quantum entanglement generator getting stolen. I’ve read about this day in history books, and I don’t intend to make the same mistakes from previous timelines.” The sound of the woman's heels clacking on the tile floor sent shivers up {APE}.replace => #'s spine as he recognized the voice. Commander Whiplash was here in the facility with them. Once the data finished downloading, {APE}.replace => # teleported to safety to relay what he had overheard.",
    "After climbing onto the top of the control tower, {APE}.replace => # began using a golf club to fire off live grenades at the Tempus reinforcements; inventing a game he called “Ultimate Stroke Play”",
    "“Stop right- '' Was all an unsuspecting agent got out before {APE}.replace => # leapt over a barrier, grabbed him by the face and slammed his skull into the ground several times before silently continuing to an extraction zone, having saved the ape who was almost taken by surprise.",
    "On his way to support an assault on another building, {APE}.replace => # became pinned by sniper fire after blowing up a Tempus capture team with a rocket launcher. Seeing an ape next to him, he shrugged nonchalantly. “First time?” The ape asked. {APE}.replace => # nodded. In response, his fellow ape remarked “Don’t worry, brother. I’m groundhogging it.” As if in response to {APE}.replace => #'s visible confusion, the ape said, “I’m reliving the same day over and over. I’m stuck in a time-loop because of a failed jump. Just follow my lead and do as I do. The sniper just ran out of ammo, so we should be good to go.” As the ape stood up, his head was summarily blown off by a sniper round. {APE}.replace => # decided to just portal out rather than risk running for it.",
    "Distracted by the excitement of completing the upload of a computer virus into a secured network, {APE}.replace => # was surprised when a cyborg smashed the terminal he was jacked into. Rolling out of the way, {APE}.replace => # managed to dodge a series of follow up attacks. After cornering {APE}.replace => #, the cyborg ripped the laptop out of his hands, tossing it through one of the windows nearby. Not wanting to lose what he came for, {APE}.replace => # threw himself after the laptop, crashing through the window and managing to grab the laptop before teleporting to safety.",
    "Leaping from a rooftop, {APE}.replace => # snagged one of the helicopters, managing to scamper toward the back before porting out and removing a 3 foot section of the tail boom. The suddenly rudderless aircraft began to spin out of control, caroming off of a tower before impacting the side of the guardhouse facility. The explosion that followed was strong enough to send chunks of reinforced concrete raining down upon the Tempus forces arrayed around an entrance to the building.",
    "{APE}.replace => # ran through the facility, managing to race through to the extraction zone before being confronted by two Tempus agents in exo-suit armor. One of the agents fired an electrified net at him, but {APE}.replace => # was fortunate enough to activate his nanobanana and escape to safety just before it made contact, teleporting himself back to base while painfully ensnared, preventing himself from being captured.",
    "{APE}.replace => # managed to climb back around to the prison facility, stealthily placing demolition charges to assist the second wave before deciding to take a well earned break to smoke a joint and watch the battle for a while. As he relaxed, a shell-shocked Tempus agent staggered out next to him. The man was bleeding from the ears, and had scorch marks on his face. Seeing the poor bastard in such a state, {APE}.replace => # offered him a puff of the joint. As the concussed agent reached for it, {APE}.replace => # pulled it back, saying, “Psych!” {APE}.replace => # then pulled out a revolver, and blew the man’s head off before he could react. {APE}.replace => # then smoked the remainder of the joint alone and eventually teleported out.",
    "Returning to the reservoir, {APE}.replace => # led a crew aboard a hijacked Tempus boat. They began singing sea shanties as they gunned down drones and shot helicopters out of the sky. When it was time to go, {APE}.replace => # ramped the boat into a fuel station before porting out with his crew.",
    "Unable to achieve any further objectives after being pinned down by a blistering crossfire, {APE}.replace => # managed to portal away just before being crushed by a rapidly advancing assault mech.",
    "Utilizing advantageous positioning, {APE}.replace => # managed to suppress further Tempus reinforcements from engaging his allies as they fled from the second temporal shield generator towards the extraction zone. Once his ammunition was depleted, he joined his teammates in escaping.",
    "{APE}.replace => # hid on a helicopter’s landing gear, hanging on by his feet and firing relentlessly at Tempus agents until his ride was shot down by an assault mech aiming at him. Fortunately, {APE}.replace => # was able to portal out before being injured.",
    "Stumbling upon a drug locker, {APE}.replace => # uncovered an entire duffle bag of cocaine. Later, when rushed by Tempus agents, {APE}.replace => # tossed the duffle at the enemy then remotely detonated a mine he had hidden in the bag, creating a cocaine smokescreen to cover his escape. As he teleported to safety, he realized Cocaine Smokescreen would make a good band name.",
    "After hijacking a mech and exhausting all the ammunition on Tempus reinforcements, {APE}.replace => # guided his vehicle directly between two other mechs. Instead of attacking, he coaxed the machine’s maniple claw around and behind itself, then crushed his own vehicle’s power cell and teleported to safety. A mere moment later, the reactor violently erupted in a powerful explosion, completely engulfing the nearby enemy armored units.",
    "As he made it to the extraction zone, {APE}.replace => # turned around to see one of his crewmates get gunned down by a sniper. Running back into enemy fire, {APE}.replace => # recovered the fallen soldier and carried him on his back while moving evasively until he had advanced far enough to teleport them both back to base.",
    "After being pinned down in a crossfire for nearly 30 minutes, {APE}.replace => # got bored and decided to try being more productive. He tried to pass the time defending his position by journaling movie pitch ideas in his head, but could only come up with “What if Megatron was sexy?” Listen, not every battle is won with firepower. Truly, it is the artists, who grip the heart of our culture, that must wage war on the battlefield of our minds by asking the poignant questions. After a few minutes, {APE}.replace => # realized no one was shooting directly at him anymore. There were several dozen downed enemies in almost every direction, and the sounds of battle were coming from other parts of the megastructure. Deciding to circle back on the thought later, {APE}.replace => # patted himself on the back and returned to base.",
    "While locked in combat with a Tempus agent wearing exo-suit armor, {APE}.replace => # positioned the man in the line of crossfire. The agent was struck through the back, causing him to topple onto {APE}.replace => #. While trapped under the weight of the dying man, {APE}.replace => # noticed the power cell of the armor had been damaged and was sparking; soon to explode. Shoving the body off of him and towards an onrushing Tempus soldier, {APE}.replace => # managed to portal away just before the suit exploded.",
    "Watching as a grenade fell nearby, {APE}.replace => # tackled a heavily armored Tempus agent, turning so his enemy landed chest down directly on it. He then leapt onto the agent’s back, thinking “Surely when this explodes, I will be able to surf the body up to the helicopter passing overhead.” That was the last thing he remembered thinking before waking up with ears ringing as he was dragged to the extraction zone and teleported out.",
    "After managing to digitally penetrate the infrastructure control server, {APE}.replace => # was able to recover and download sensitive information regarding the main power plant. {APE}.replace => # created loops on several cameras, managed to avoid setting off any alarms, and even cut a man in half by cutting the brakes on an elevator as an agent was stepping off. After wrapping up his cables, {APE}.replace => # snuck to the extraction zone and teleported the precious data back to base to avoid any risk of losing it.",
    "Upon managing to access and hack into the facility-wide PA system, {APE}.replace => # hooked up an electric guitar and began shredding from a secluded area, puting on an entire performance to bolster morale. When agents finally tracked down his location, they ran in, triggering sonic weapons that had been rigged to fire upon the door opening. The concussive blast vaporized the agents, shattered every window on the floor, and fried the PA system. Taking a final bow, {APE}.replace => # portaled to safety.",
    "After dispatching three agents guarding a terminal, {APE}.replace => # managed to hack into the computer database and uncover many important logistical documents as well as a list of the agent’s personal accounts. He hacked into the personal accounts while the documents were downloading and started posting anti-Tempus propaganda. He also deleted all of their family photos. Feeling satisfied that he contributed to the psychological aspect of warfare, {APE}.replace => # ported away.",
    "Seeing a fellow ape under assault by a cyborg, {APE}.replace => # ran in to provide back-up. He hopped on the creature's back, pounding at the cyborg’s metal-plated skull to give his squad member time to reach an extraction area. As a result, the cyborg reached behind itself and grabbed {APE}.replace => # by the leg. Before either ape could react, the cyborg ripped {APE}.replace => # off his back, cracking him like a whip and releasing him against the ground with enough force to break the concrete beneath. With his vision blurred and ears ringing, the impact managed to bounce {APE}.replace => # a few feet away, just out of range of one of the temporal shields. {APE}.replace => # teleported away just before the cyborg could land a fatal follow up stomp.",
    "After finding a janitorial station housing dozens of robotic cleaners, {APE}.replace => # hacked into the local network to download their route programming and gather blueprint data of the facility. Before leaving, he placed timed mines on all the cleaning drones and sent them on a mission to clean the areas that typically require the most attention; targeting the rooms most frequented by foot traffic.",
    "Crawling through the air vents, {APE}.replace => # discovered the primary strategic control room. There, wearing a strange headset while issuing orders to send in a reinforcement airship every single hour until directed otherwise, was none other than Director Whiplash herself. Knowing that the room was too heavily guarded for him to successfully launch a direct assault, he rigged the computer stations to explode before doubling back to make a report and warn his fellow apes.",
    "Doubling back to reinforce the power plant assault team, {APE}.replace => # helped drag barricades into position before killing a Tempus capture team. He then turned his attention to rescuing wounded apes who needed help with extraction. He saved eight apes from being captured.",
    "Noticing drone swarms diving under one of the bridges to the prison facility, {APE}.replace => # picked up two rifles and ran to get a better view. After seeing a fellow ape in danger of being gunned down while placing demolition charges, {APE}.replace => # leapt in the direction of the bridge, firing both weapons and eliminating great swaths of drones as he fell past. At the last second, {APE}.replace => # managed to portal away just before crashing against the rocks below.",
    "After snapping the neck of a Tempus agent with a headset on, he investigated the body and found a tablet. With a bit of work, he realized that the tablet controlled a small swarm of drones that were attacking different locations throughout the facility. Upon calling the drones over to his position, he was confronted by a cyborg. The cyborg leveled the barrel of a rifle at {APE}.replace => #'s head. As {APE}.replace => # stared down the abomination in the face of death, he heard the distinct click of an empty weapon. With a smile, {APE}.replace => # pressed a button on the tablet, porting out as the drone swarm unloaded on his position, turning the cyborg into pink mist and destroying the tablet controlling them.",
    "Using parkour to move around the top of a roof while being chased by a small swarm of drones, {APE}.replace => # dodged fire by hopping in and out of damaged sections of roof. While sliding through a broken air vent and swinging inside the facility to escape, {APE}.replace => # caused multiple drones to crash in their high speed pursuit. He eventually made it inside an extraction zone before porting to safety.",
    "Watching an ape get shot through the spine by an anti-nanite round and thrown over the bridge by a cyborg, {APE}.replace => # leapt out, pulling the cyborg with him. Scrambling quickly, {APE}.replace => # pushed off the cyborg, firing himself toward the falling comrade. {APE}.replace => # barely managing to grab the unconscious ape before teleporting them both back to base.",
    "Racing forward to save an ape from being cut down by a cyborg, {APE}.replace => # grabbed a cyber sword from a fallen squad member before leaping to cut off the hand of the cyborg holding a similar weapon. Reaching out and catching the cyborg’s sword as the hand fell away, {APE}.replace => # twisted into a spin with the blades, slicing the cyborg in half from skull to groin. As he landed, the cyborg fell into two even pieces, showering the apes in a spray of stagnant blood and machine oil. Seeing how Tempus reinforcements were about to converge on them, {APE}.replace => # grabbed his teammate and ported to safety.",
    "After dispatching a member of a Tempus capture team, {APE}.replace => # picked up his electro-spike launcher, reloading it with a clip found on the body. When Tempus reinforcements appeared in front of him, he sent all seven rounds hurtling at them, punching holes cleanly through them, pinning them to walls and each other while he ported to safety.",
    "Leaping over a tripwire laid out in the facility, {APE}.replace => # was intercepted by a member of a Tempus capture team who launched an electro-stake through his shoulder. Pulling it free as it impacted, {APE}.replace => # tossed the spike back through the agent’s head. After disassembling the Tempus trap, {APE}.replace => # ported back to base for more assignments.",
    "As he rounded a corner of the facility, {APE}.replace => # was gripped by the leg and flung bodily into a wall. The impact broke his leg in 3 places. Seeing that his attacker was a Tempus agent in an exo-suit, {APE}.replace => # used his good leg to strike at his attacker’s torso. It’s hard to use power armor with your arm ripped out of its socket. {APE}.replace => # crushed the agent’s shoulder with his kick, then used a knife to take out the agent’s knee caps, leaving him crippled inside his power suit before teleporting away.",
    "As {APE}.replace => # ran to extraction with a friend, his fellow ape suddenly went rigid. Falling over with a knife in his spine, his companion lost consciousness. {APE}.replace => # pulled the knife out, tossing it back through the eye of the cyborg who had thrown it before grabbing his friend and porting to safety.",
    "Swimming through the flooded chamber of the hydro-electric facility, {APE}.replace => # managed to recover six of his unconscious team members from the jaws of the robotic shark. After destroying the machine and ripping it’s jaws out as a trophy, {APE}.replace => # swam his fallen brothers to safety before porting out with them.",
    "Upon seeing five cyborgs standing over the body of a brutalized ape while a Tempus capture team moved in to recover it, {APE}.replace => # requested a drone strike on his location. Rushing forward, he slid in between the cyborgs, grabbing the body of the downed ape and porting out as hellfire rained down on the Tempus forces.",
    "After fighting an unwinnable battle, with Tempus reinforcements filing in by the dozens, {APE}.replace => # looked around to see that he was still in range of the temporal shields and could not extract. Out of ammunition and thinking quickly, {APE}.replace => # grabbed a wrench and knocked over an acetylene tank and held on with his feet. Using the wrench to break the valve, the ape caused a spark that turned the tank into an improvised rocket. {APE}.replace => # took off like he was fired from a gun, slamming through the head and upper torso of a cyborg before losing control of the tank and landing inside the extraction zone. Looking back to watch the tank explode, {APE}.replace => # managed just enough time to flip off the Tempus agents before porting to safety.",
    "Ever the opportunistic entrepreneur, {APE}.replace => # walked back through the wake of carnage, gathering up new and interesting weapons, loading himself up until he could barely reach the extraction point. When a Tempus agent tried to get the drop on him, rather than waste any of the precious ammo, {APE}.replace => # simply set down what he was holding, beat the man to death with his fists, and picked his spoils back up; managing to teleport away with quite an interesting and expensive-looking haul.",
    "Catching one of the Tempus agents by surprise, {APE}.replace => # ripped the human out of his exo-suit before dragging him kicking and screaming over to the extraction zone. If Tempus was employing capture teams, then it might be a good idea to take a prisoner of their own to find out why.",
    "Stealing a temporal beacon, {APE}.replace => # activated it and lobbed it at the feet of a group of cyborgs, causing it to trigger in their midst. In the 10 foot crater that was left lay cleanly cauterized chunks of cyborgs who were standing just outside the blast zone. {APE}.replace => # continued to the extraction zone.",
    "Watching a shock cannon vaporize members of his squad as they tried to escape, {APE}.replace => # jumped on one of the larger drones and had one of the piloting apes fly him up to the Tempus airship as it appeared overhead, raining destruction and reinforcements down onto the facility. When he was in range of the shock cannon on the ship, {APE}.replace => # jumped up and used all his might to rip it out of the ship. Grabbing it as he fell, {APE}.replace => # ported to safety with the strange new weapon.",
    "While climbing through the air ducts of the facility, {APE}.replace => # overheard agents talking about taking a utility corridor to reinforce the second shield generator. He followed them to an entrance nearby and discovered the location wasn’t on the map one of the hacker apes had downloaded. Dropping a frag grenade from above them, {APE}.replace => # then ran to the extraction point to make his report.",
    "Climbing around the outside of the second shield generator, {APE}.replace => # placed demolition charges to create an entry point for the second wave of attackers. On his way out, he was spotted by an agent. As the agent put a radio to his mouth to call in what he saw, {APE}.replace => # hurled a grenade, banking it off a passing drone in order to land at the agent’s feet before blowing the man’s body in 5 different directions.",
    "Finding a break room, {APE}.replace => # discovered a revolver and a deck of cards. Sitting down to take a well earned rest and heal his wounds, ape began shuffling the cards as a squad of six Tempus agents ran in. Seeing the ape, they stopped and brandished their weapons at him. {APE}.replace => # kept shuffling, dealing out cards to each of them. Confused by this, one of the agents spoke up. “What kind of game are you playing?” Without missing a beat, {APE}.replace => # grabbed the revolver and fanned the hammer, firing a single round into each of their skulls. Before porting to extraction, he looked at the dead agent and said “Draw.”",
    "Cornered inside a server room, eight agents closed in on {APE}.replace => #. Thinking quickly, he pulled the fire alarm, rushing out and barring the exit door as halon gas flooded the room. Ape then ported to extraction with the data he had recovered.",
    "Cornered by an elite Tempus agent, {APE}.replace => #'s attacker brandishes a sword with a well-practiced flourish, a sure sign of skill honed over decades. {APE}.replace => # looked down at the plasma rifle he was carrying before emptying it into the agent. After taking the enemy sword as a trophy, {APE}.replace => # continued heading on to an extraction area where he ported back to base, already prepared to take another mission.",
    "Laying down sniper fire for the extraction team, {APE}.replace => # sent shot after shot into Tempus agents as they attempted to prevent the apes from recovering their wounded. After his team was clear, {APE}.replace => # ported home from an extraction area.",
    "Racing to recover the bodies of downed and dying apes, {APE}.replace => # rushed forward. As he picked up the bodies of his companions, Tempus agents ran to stop him before being systematically cut down by rounds from an ape in a sniper tower. After clearing as many bodies as he could find, {APE}.replace => # ported the remains of his allies back to base.",
    "Suffering a full-on psychotic break, {APE}.replace => # decides that his time is best utilized collecting ears from soldiers on the battlefield. When he exhausted the supply of dead bodies to pull them from, he decided to kill more people in order to meet his self-imposed ear quota. When there were no more bodies with ears to be found, {APE}.replace => # ported away to safety.",
    "Turning to leave for extraction, {APE}.replace => # noticed a cyborg struggling to stand back up. After looking to his left, then to his right, {APE}.replace => # climbed up on a large piece of rubble before elbow dropping the cyborg while screaming “Watch out! Watch out! Watch out!” Upon impacting and crushing the head of the cyborg, {APE}.replace => # jumped back to his feet, simulating the sound of a roaring crowd before porting out."
  ]

  var loseArr1 = [
    "{APE}.replace => # timed his air assault jump perfectly, teleporting in twenty feet above a rooftop before slamming into a group of Tempus agents. {APE}.replace => # capitalized on the surprise by turning their weapons against them in a stunning display of calculated ferocity. Unfortunately, his actions caught the attention of one of the armored heavy mechs, which unloaded a burst of fire in {APE}.replace => #'s direction, causing damage to many of his internal organs and breaking a few bones before {APE}.replace => # managed to crawl to cover and heal.",
    "After teleporting in above the facility, {APE}.replace => # attempted to glide in on a wing suit. However, one of the flak cannons managed a glancing hit that tore through the taut fabric, causing {APE}.replace => # to drop like a stone. Slamming full force into the side of a guard tower, {APE}.replace => # broke his jaw, fractured three ribs and dislocated his shoulder before plummeting back into the waters below.",
    "{APE}.replace => # joined the air assault team, bringing what they thought was a parachute. Regrettably, {APE}.replace => # did not realize that what he had grabbed was, in fact, a child’s backpack. When he opened it in mid-air, three binders, a trapper-keeper, and a cartoon lunchbox spilled out into the air around him as he hit the water at terminal velocity, breaking both his legs and driving the air from his lungs. He had to be fished out by one of his fellow apes.",
    "Loaded to the gills on weed gummies and hard liquor, {APE}.replace => # jumped into the airborne assault team without a parachute. After critically missing his attempt to grab a passing drone, {APE}.replace => # hit the roof hard enough to break through the cement, shattering more than a dozen bones on impact. He managed to crawl around a nearby wall to give himself time to heal.",
    "Jumping in with the airborne division, {APE}.replace => # was grazed by an unfortunately accurate anti-aircraft flak round that obliterated his parachute, dropping him from over one hundred feet in the air which caused his ankle to shatter upon landing. {APE}.replace => # had to hobble to find cover and heal behind a crushed and burning assault mech.",
    "{APE}.replace => # teleported underwater directly in front of one of the floating mines. As his nanites almost instantaneously displaced the water to open a space for his arrival, the mine detonated. The resulting blast tossed him clear of the water and onto a dock, where he was dragged behind cover by one of his squadmates in order to heal.",
    "Landing with the underwater team, {APE}.replace => # was shot through the lung by a harpoon. He managed to rip the harpoon out of his chest and kill the diver who attacked him, but he was forced to momentarily retreat and heal.",
    "{APE}.replace => # moved in with the underwater team, but was caught by a monofilament trawling net. After being dragged up to the surface of the water, a pair of Tempus agents on a boat stabbed him and shot him a few times before {APE}.replace => # was rescued by a squadmate cutting the net with a knife. {APE}.replace => # retreated to the depths to recover, then moved on.",
    "As part of the underwater assault team, {APE}.replace => # swam toward the facility. As he navigated the treacherous reservoir, Tempus agents in boats began lobbing grenades into the lake. Upon taking a hit by one of these improvised depth charges, {APE}.replace => # was rendered momentarily unconscious and woke up minutes later on land with one of his fellow apes pulling a piece of shrapnel out of his head.",
    "Joining the underwater assault team, {APE}.replace => # was gunned down by a water-skimmer drone as he managed to overpower one of the Tempus agents on a boat. He woke up minutes later, still in the same boat, as the nanites knitted his wounds back together, and immediately headed for the facility.",
    "{APE}.replace => # teleported in with enough weapons-grade fireworks to put a Fourth of July spectacle to shame. Intending to keep lighting them throughout his flight, they all went off at once, sending a shower of explosions in all directions and sending the ape hurling to earth in a ball of flame before impacting into the river and having to be fished out by a teammate.",
    "Deploying with the second wave of the airborne assault team, {APE}.replace => # was hit by friendly fire moments before landing as an enraged ape hucked grenades wildly in all directions. The blast knocked {APE}.replace => # away from his intended landing zone causing him to crash into a low wall. He woke up minutes later as the nanites snapped his spinal column back into place.",
    "Joining the second wave of airborne troops, {APE}.replace => # immediately impacted with an airship after he teleported in. As his vision cleared he saw that he was sixty feet off the ground on the bow of an open airship loaded with cyborgs. As the robotic crew leapt off the airborne vessel to join the fight below, one of the cyborgs in a tricorn pirate hat turned to {APE}.replace => # and pointed him out, snarling and marking him with a curse. Before the creature could draw a weapon and fire, {APE}.replace => # leapt away towards a nearby guard tower.",
    "Having jumped in with a squad from the second wave airborne assault team, {APE}.replace => # deployed his parachute as a blur flew past him. A cyborg, riding a drone and wielding a sword, zipped past him, cutting down apes who were parachuting in. As other apes fell to their death, {APE}.replace => # managed to grab hold of another passing drone just long enough to break his fall.",
    "After joining the second wave airborne group, {APE}.replace => # jumped in and was immediately blinded by smoke and fire from another {APE}.replace => # setting off an ungodly amount of fireworks. The distraction caused him to impact with another parachuting ape and the two became entangled, colliding with the side of a guard tower. {APE}.replace => # looked up to see his parachute hanging by a thread. When he looked across the great distance to the water below, he saw robotic sharks swimming in the reservoir underneath them. As the other aped moved to free himself, the parachute holding them both began to tear. Faced with an impossible choice, {APE}.replace => # chose to cut the line tangling the other ape to himself; allowing the other ape to fall into the dangerous waters so that {APE}.replace => # would be able to carefully climb up and continue the mission.",
    "During his attempt to sabotage the hydroelectric turbines, {APE}.replace => # was attacked by one of the robotic sharks. The shark proceeded to bite down on his leg with bone breaking force, lacerating skin and sinew with its razor sharp teeth, before a rogue untethered mine exploded nearby, damaging the shark enough for the ape to pull himself free. Climbing onto the dock nearby, {APE}.replace => # detonated the charges he had placed while the nanites restored him.",
    "Once the hydro electric turbines had been disassembled, {APE}.replace => # swam through the pipes, emerging from a service hatch into the hydro electric plant. As he did, a shotgun slug ripped through his shoulder; fired by a Tempus agent who had been hiding behind a nearby control station. {APE}.replace => # managed to rush forward and disarm the agent with one hand before turning the weapon on his attacker and firing. {APE}.replace => # had no choice but to find cover and give his body time to repair.",
    "Upon joining the underwater team, {APE}.replace => # worked to dismantle the floating mine that impacted some of the other apes. As he got close to one of the mines, a Tempus agent fired on him, causing the mine to explode, taking off half of {APE}.replace => # ‘s face in the process. He was later fished from the river and allowed a few moments to heal before continuing on.",
    "After placing charges on the hydroelectric turbines as part of the underwater sabotage team, {APE}.replace => # was pulled away from his mission, literally, when a robotic shark clamped down on his hand and started swimming away. As he was dragged through the water, {APE}.replace => # pulled out his knife, stabbing at the robot multiple times before pulling his hand free and making it to the docks just before the concussion of the explosive charges reached him. Momentarily favoring his other hand as he continued forward, he noticed that the pinky and ring finger of his left hand were missing. They never grew back.",
    "Never agreeing with the phrase “Less is more,” {APE}.replace => # placed three full payloads of explosives around one of the hydroelectric turbines. When the charges were triggered, the blast sent him flying out of the water and onto land where he was recovered by fellow apes before Tempus forces could capture him. After healing up, {APE}.replace => # headed back to the battle.",
    "After joining the slingshot team, {APE}.replace => # catapulted himself onto the roof of one of the many buildings of the Tempus facility, where he was immediately fired upon by an assault mech. Though the shots only grazed him, {APE}.replace => # was seriously wounded. As he pulled himself towards cover, the mech was shot from behind by a turret one of the other apes had hijacked. {APE}.replace => # used the distraction to hide behind some structural debris so he could heal his wounds.",
    "Convinced it would look cool, {APE}.replace => # joined the slingshot team. As he went to catapult himself, he pulled the pins on two grenades. Just as he was about to release, a familiar ape stopped him. It was that annoying guy from the cafeteria who always tried to sit with his groups. “We should jump together!” he yelled excitedly. As the other ape ran up, {APE}.replace => #'s slingshot released, sending him flying into the nuisance attempting to establish camaraderie. {APE}.replace => # clipped the hapless irritant with his knees, which sent {APE}.replace => # spinning uncontrollably into the side of the Tempus facility, barely managing to drop the grenades away from himself at the last second.",
    "Using one of the plasma rifles, {APE}.replace => # joined the slingshot team to hurl himself up to the Tempus facility roof. As he flew through the air, he took aim at several drones, managing to clip three and down one. The surviving drones lasered in on his location and sent volleys of small guided rockets at the ape as he landed, causing him to land inside an inferno. {APE}.replace => # managed to survive, but had to roll away to hide beneath rubble to heal.",
    "After joining the slingshot team, {APE}.replace => # launched himself at the roof of a larger building in the Tempus compound, arcing perfectly toward the roof before a cyborg clotheslined him to the ground so hard that he fell through the ceiling as the impact expanded a hole that had previously been blown in the roof.",
    "Peppered by drone fire as he slingshot himself onto the roof, {APE}.replace => # ducked into a roll to avoid incoming fire, grabbing a fallen agent’s plasma rifle as he did. Racing forward with no time to heal, {APE}.replace => # fired into the circling swarm, taking down a few while he continued into the facility.",
    "After climbing up the waterfall with the cliff ascent team and reaching the waterfall cave entrance, {APE}.replace => # was knocked prone by a Tempus agent wielding a stun baton. As another agent moved in for the kill, {APE}.replace => # was saved by another ape, joining the fight and attacking both agents. {APE}.replace => # then leveraged the baton away from the initial attacker, bringing it about to defend his rescuer. After defeating their opponents, they both continued on.",
    "Being one of the first through the waterfall cave entrance, {APE}.replace => # sustained multiple injuries while sabotaging the automated turrets. Though wounded and forced to take time to heal, his brave actions saved the lives of multiple apes.",
    "After reaching the top of the waterfall cave entrance, {APE}.replace => # was met by a cyborg who booted him backwards, all the way to the rocky waters below. As he moved to climb back up, drones got into position to prevent his climb. Harrassed by the gunfire of drones, tired, wounded, and with everything in his body telling him to give up, {APE}.replace => # chose to persevere, leaping from piton to piton as he restarted his ascent. When he got to the top, he used stealth to sneak in along the roof of the waterfall entrance. Grabbing hold of an overhang, {APE}.replace => # used his feet to latch onto the cyborg before flinging him out of the waterfall where it landed headfirst on a jagged rock.",
    "Irritated by the drones shooting as he attempted to join the cliff ascent team, {APE}.replace => #, found a solid handhold on the rocks where he was able to start picking off drones. He manages to fire through the magazine before a cyborg, falling after being thrown from the bridge above, used his last moment on earth to hurl an axe at {APE}.replace => # in a last act of defiance. Though he was able to maintain his grip on the rock, {APE}.replace => # was forced to drop his gun and focus on climbing to the top of the waterfall cave entrance with the axe still in his arm before he could remove it.",
    "After successfully infiltrating the waterfall cave entrance, {APE}.replace => # was discovered by two cyborgs. The first cyborg grabbed him by the chest, crushing ribs in his grip before smashing {APE}.replace => # into a wall. Before he could recover, the second cyborg stepped on his head until the skull made a popping sound. Laughing to themselves, they left the ape in the hallway to die. However, due to his nanite-derived rapid healing, {APE}.replace => # was able to repair himself sufficiently enough to regain motor function. As the ape recovered, an explosion rocked the area, sending a length of steel beam clattering to the ground next to him. The cyborgs were too distracted by the ongoing combat at the mouth of the huge cave entrance to notice {APE}.replace => # rushing forward at full tilt with the beam. Launching the beam in the cyborgs’ direction, {APE}.replace => # slid the long beam between the legs of the first ape while jumping up to drop kick the second. When the beam slid into the waterfall, the far end was forced down by the water, lifting the other side up under the first ape and flinging him out. The second ape followed swiftly behind. {APE}.replace => # took a moment to catch his breath before continuing on.",
    "Joining the reinforcements, {APE}.replace => # teleported onto a rooftop where he noticed a helicopter preparing to take off. He ran forward and leapt into the bay doors, savagely attacking everyone inside. {APE}.replace => # then realized he had no idea how to pilot a helicopter, but asked himself, “How hard could it be?” Before he could even figure out how to take off, the helicopter was picked up by one of the mechs and tossed nose over end across the bridge connecting to the barracks. As he rattled around the cockpit, he managed to arm the weapons system, sending rockets and a spray of heavy machine gun fire in all directions as the vehicle tumbled. {APE}.replace => # suffered several broken ribs, a fractured tibia, a broken femur, and serious burns before escaping the wreckage.",
    "Portalling in to reinforce the cliffside entrance, the ape found a squad of Tempus agents positioned in a turtle phalanx with tridents and lances sticking out, braced for impact. Leaping off of a mech, {APE}.replace => # landed directly onto a shield after taking a glancing blow from one of the lances. Scrambling over top, {APE}.replace => # used his superior strength to pry one of the shields free of a Tempus agent with the agent’s arm still intact. Jumping into the hole he created, {APE}.replace => # was then able to create enough chaos to rout their positioning and give his teammates an opportunity to prune their numbers, destroying their phalanx and causing them to retreat and regroup. {APE}.replace => # suffered dozens of slashing and puncture wounds that he healed before moving on.",
    "Watching as another ape single-handedly routed a squad of Tempus agents, {APE}.replace => # circled around the bridge to head them off, tossing six grenades into the escaping soldiers. While doing this, {APE}.replace => # was gunned down by a sniper and forced to retreat in order to heal the wound.",
    "Reinforcing the cliffside waterfall entrance, {APE}.replace => # noticed a number of cyborgs beginning to spill out into the hallway. After grabbing an entire bandolier of sonic grenades from a downed Tempus agent, {APE}.replace => # tossed them into the wave of enemies. Unfortunately, {APE}.replace => # forgot to account for the use of sonic weapons in an enclosed space. The soundblast obliterated the cyborgs, but the concussive wave ripped down the hall, shredding off the top layer of exposed skin on the ape while sending shrapnel flying in all directions. {APE}.replace => # had to stop and heal before continuing on.",
    "Porting in to push the fight after the destruction of the first shield generator, {APE}.replace => # ran through the hallway in time to catch a cyborg sneaking up on a group of apes who were getting in position. Though he was able to shout a warning, the cyborg spun on him, shooting him through the floating rib before being gunned down by the apes. As the nanites healed his wound, he was assisted to his feet by two other apes who thanked him for saving their lives.",
    "On his way to support the assault on the first jumpshield generator, {APE}.replace => # was assaulted with drone fire that knocked him into a propane tank. Before he could get clear, another line of drone fire connected with the tank, triggering a massive explosion that knocked {APE}.replace => # off the bridge and into the waters below. Though he survived with burns and fractured bones, {APE}.replace => # had to hide under the dock in order to heal.",
    "While inbound to reinforce the barracks assault, {APE}.replace => # was gunned down by a sniper from a hidden location. The sniper shot struck him through the lower spine, causing him to collapse. In an effort to save him, another ape ran up and was also shot immediately through the head. Realizing that he was being used to bait out the other apes, {APE}.replace => # crawled to cover while taking hits from three more sniper rounds, including one to the jaw, before curling up behind a barrier and healing alone. When he recovered the function of his mouth, he was able to call in the sniper’s location.",
    "Rushing to reinforce the roof team, {APE}.replace => # managed to kill an entire capture team before being hamstrung by a cyborg. Rolling out of the way, {APE}.replace => # grabbed one of the Tempus agent’s weapons and fired desperately. The weapon launched a net at the cyborg's face that got sucked into the engine of a low-flying drone. The drone’s momentum ripped the entangled cyborg’s head out with the spine still attached before it crashed nearby, sending shrapnel into {APE}.replace => #‘s back, puncturing his lung and causing him to cough blood until the nanites could repair his wounds.",
    "After seeing another ape get snatched up by a mech, {APE}.replace => # fired a rocket launcher at the robot, blowing the arm holding the ape clear and injuring the pilot who used the mech’s free arm to hurl the mangled nose of a downed helicopter at {APE}.replace => #. Unable to scramble completely out of the path of the projectile, {APE}.replace => # suffered several lacerations from passing debris.",
    "Joining some of the reinforcements on a rooftop, {APE}.replace => # saw Tempus agents firing electrified nets into some of his fellow apes in an attempt to capture them. Though he was able to dispatch the Tempus agents quickly, he was badly burned from his effort to free the three apes from the nets. He noticed that these burns took longer to heal than normal.",
    "Upon joining the reinforcements to take down the first generator, {APE}.replace => # was immediately impacted by a slug round meant for an ape in front of him. Looking behind him, {APE}.replace => # saw a Tempus agent wielding a pump-action shotgun. Before the human was able to finish chambering another round, {APE}.replace => # leapt on him, gouging his eyes out with his thumbs and riding him to the ground where he cracked the man’s head open on the concrete like it was a frustratingly difficult ostrich egg, and he was starving for an ostrich omelet.",
    "Upon teleporting in with orange slices and bottled water for the troops, {APE}.replace => # is kicked by a rushing mech. The impact sent both the ape and his snacks flying into a wall so hard the ape’s body cracked the concrete. Worse, the snacks were ruined. Grabbing a nearby rocket launcher, {APE}.replace => # fired a round at the mech’s power cell, causing it to explode, cooking the pilot inside.",
    "Leading his team into the facility, a small group of cyborgs rushed around the corner, instantly killing one ape by shoulder checking him into a wall before unloading plasma rounds down the hallway. Wounded in pride and body, {APE}.replace => # was forced to call a retreat to save the lives of his squad.",
    "Upon landing on the roof to join the reinforcements, {APE}.replace => # began clearing battlements, pulling Tempus agents out of bunkers, and grenading turrets. During his hasty assault, {APE}.replace => # ran directly into a killbox set up by Tempus agents. Bullets ripped through him and he was forced to huddle behind a burned out helicopter chassis and return fire so he could heal enough to make an escape.",
    "Porting directly onto the dock, {APE}.replace => # began pulling other apes out of the water so that they could make their way into the facility. In his distraction, he didn’t notice a Tempus agent riding a jetski ramp onto the dock. The vehicle collided with him, knocking him into the water. As he landed, he managed to grab the Tempus agent as well, pulling him under the water and drowning him before climbing back onto the dock to let his wounds heal.",
    "After joining the reinforcements, {APE}.replace => # posted up in a hallway, allowing apes to retreat past him while laying covering fire. When a cyborg rounded the corner with a cyber sword, {APE}.replace => # unloaded a full magazine into it before the creature barreled into him. In a reflexive motion, {APE}.replace => # threw his hand up to stop the incoming stab and was speared through the hand by the sword, drawing the weapon away from his head. By the time {APE}.replace => # realized what had happened, the cyborg had succumbed to his injuries, leaving the blade impaled in his hand. The wound healed very slowly.",
    "Seeing another ape about to get executed by flamethrower, {APE}.replace => # ran forward, grabbed an agent and used him as a human shield while lunging forward at the man holding the flamethrower. Upon impact, the agent holding the flamethrower staggered into the spinning blade of a helicopter’s tail rotor, causing an intense explosion that blew both apes clear of the roof and into the river below.",
    "After porting in, {APE}.replace => # received the location of a sniper that had been single-handedly destroying dozens of apes. Upon making his way to the sniper’s reported location, {APE}.replace => # found a tower filled with Tempus agents. After hyping himself up, {APE}.replace => # burst through the tower’s bottom door, leaping on the first agent he saw and biting a chunk out of his neck before leaping to the next. {APE}.replace => # managed to take out three agents before they realized what was happening. As they finally began firing at him, ape tanked the hits, ascending the stairwell while dispatching his foes before leaping to the next level and continuing on. By the time he got to the sniper’s nest, he was riddled with semi-healed stab and bullet wounds. When he opened the door, he accidentally triggered a well placed directional mine. The explosion blew {APE}.replace => # back down the stairwell. When he healed enough to regain consciousness, the sniper was nowhere to be found.",
    "Alone in a hallway, {APE}.replace => # was confronted by a cyborg who pulled out a knife. {APE}.replace => # looked at the weapon before smiling and reaching for his machete. “That’s not a knife,” he said. “This-” Cutting him off, the cyborg leapt forward, stabbing him many times in the abdomen before {APE}.replace => # was able to react and put the machete through the skull of his attacker. Crumpled over in pain, {APE}.replace => # tried to keep from bleeding out faster than he could heal. As his wounds recovered, he pulled the machete from the cyborg’s skull. “This is a knife,” he wheezed.",
    "Joining the reinforcements and teleporting onto the roof, {APE}.replace => # noticed a helicopter taking off. He reached for the largest piece of rubble he could find and tossed it into the blades, shattering one. This caused the helicopter blades to begin to disintegrate, sending a shower of shrapnel back in his own direction as the aircraft tilted to the side and disintegrating the rest of the rotor as it touched the bridge. The spray of fire, stone and metal hit {APE}.replace => #, forcing him to sit down and dig pieces of concrete out of his head and chest."
  ];

  var loseArr2 = [
    "Nursing his wounds and continuing on, {APE}.replace => # began to set up defensive barriers for the other apes to use. While out in the open, {APE}.replace => # was shot through the neck and chest with sniper fire. Though they were not fatal hits, {APE}.replace => # noticed that his wounds were not healing quite as quickly as before. Taking care to stay crouched, {APE}.replace => # finished placing the barriers.",
    "Using the defensive emplacements to his advantage, {APE}.replace => # moved quickly between objects providing cover, stealthily flanking and attacking agents as they tried to mount a counteroffensive. In a desperate effort to stop his progress, one of the enemy controlled flak cannons fired at the surface, hitting both {APE}.replace => # and the group of Tempus agents near him. As he was bowled over by the explosion, {APE}.replace => # noticed that his abdomen had been torn open in the blast, and found himself unable to see out of one eye. Crawling out of the open while holding his intestines in while his stomach healed, {APE}.replace => # noticed that repairing an injury this grave was putting a severe strain on his nanomachines. {APE}.replace => # didn’t have time to wait for his eyesight to completely recover, and fell back towards his squad while the skin on his torso continued to knit itself together.",
    "After continuing further into the underbelly of the second shield generator, {APE}.replace => # came across defensive emplacements that had been erected to create chokepoints and deny access to ape forces. At the end of the hallway, there was a minigun being set up to annihilate anyone attempting moving forward. Knowing that time was, ironically, not a friend of the apes in this instance, {APE}.replace => # rushed forward, crashing into the Tempus barricades and drawing the majority of enemy fire. As their bullets tore through his chest and legs, he managed to slay his way to the agent arming the heavy weapon. {APE}.replace => # then broke the human’s leg, pulling him to the ground where he quickly overpowered and suffocated the agent before passing out himself due to massive trauma. When he regained consciousness a few minutes later, his wounds were still struggling to mend as other apes were working frantically to hold the new position.",
    "After fighting his way through the corridors just below the surface of the Tempus facility, {APE}.replace => # decided to help set up defensive emplacements to support his fellow apes and provide them with some cover. After setting everything up perfectly, a helicopter crashed above, shredding the ceiling and sending debris and shrapnel in all directions. The leaking chassis fell through, blocking the entire hallway and ruining his hard work. The pilot, having survived the crash, looked out through the broken windshield to see {APE}.replace => # standing there, covered in shrapnel wounds and seething with rage. {APE}.replace => # slowly walked over to the pilot who was desperately trying to free himself from a jammed seatbelt. The ape climbed inside and spent the next 15 minutes punching the Tempus agent, slapping him awake when the man fell unconscious, and continuing this process until the agent stopped waking up.",
    "{APE}.replace => # then ran to the first shield generator to support his team, but was shredded by automated turret fire upon rounding the corner. After crawling, bloody and battered, into an air duct to escape, {APE}.replace => # discovered a way into a nearby control room where he was able to hack in and reprogram the nearby turrets to fire only on Tempus agents.",
    "After taking a moment to recover, {APE}.replace => # ran to assist in the assault on the first temporal shield generator. As he moved through the facility, he encountered a Tempus agent in power armor who was stationed at a bottleneck with a minigun. Thinking quickly, {APE}.replace => # doubled back and slid into the air ducts to make his way behind the agent. When he dropped down on the agent from a vent, {APE}.replace => # slammed his knife into the reactor unit on the back of the armor, piercing it, and caused caustic acid to spew in all directions, burning himself and the agent. Though severely wounded by acid burns to the hands and face, {APE}.replace => # managed to recover. The agent at his feet was not so lucky. Having fallen over, the agent laid trapped in its power armor as the potent chemicals began dissolving the armored suit, bubbling out around the lower back and spilling into smoking puddles near the enemy’s midsection. As {APE}.replace => # redirected the minigun to help support his troops, they stared in shock. When he caught his reflection in a polished surface and noticed the burns were barely healing, he guessed that some of the chemical remained on his blistered skin, directly impeding the restorative efforts of his nanites. Judging that the injury had not compromised his ability to fight, {APE}.replace => # endured the pain as he sallied forth.",
    "{APE}.replace => # hustled toward the first shield generator, but was snared in a trap. A looped wire hanging from a ceiling lowered around his neck and was pulled tight by one Tempus agent, lifting his feet from the floor, as another pair moved in with stun rods, electrocuting him from out of reach as he hung powerlessly. Losing consciousness and in a panic, {APE}.replace => # grabbed the cord with both of his arms to release the pressure on his windpipe while he grabbed one of the stun batons with his feet, jerking it into another Tempus agent before ripping it out of the other’s grip and slugging the second agent as well. The final agent, still leaning against the wire, pulled a sidearm out and managed to shoot {APE}.replace => # several times in the chest before the ape was able furiously launch the weapon at him. The impact caused the soldier to lose his grip, allowing {APE}.replace => # to seize the moment and lunge forward to break the agent’s arms. Without missing a beat, {APE}.replace => # put the wire around the agent’s own throat and yanked with all his might, then tied the slack around the downed aggressors, creating a brutal visual warning for the apes who followed. As he moved forward, {APE}.replace => # noted the holes in his chest were healing slower than he expected, but the mission had to continue.",
    "Making his way to the first shield generator, {APE}.replace => # encountered an agent attempting to seal off an entryway. Pulling a grenade, {APE}.replace => # raced forward, throwing his arm out to catch the door. As the agent slammed the door closed, {APE}.replace => # managed to just fit his arm through, dropping the grenade on the other side. The detonation slammed the door on the ape’s arm with enough force to cause a spiral fracture, rendering it momentarily useless. However, {APE}.replace => #‘s actions kept the door from fully closing long enough for his squad to add their strength to his before shoving through the entryway and descending upon the survivors just getting up inside. Painfully straightening his mangled arm, {APE}.replace => # waited as the nano machines coursing through his blood worked, resisting a powerful itching sensation originating from the regenerative process taking place through the damaged areas of the bone. Though they healed his arm back to a functional state, he had a feeling he was severely overtaxing a limited resource, but pushed onward.",
    "Breaking into the first shield generator’s focusing array room from above, {APE}.replace => # found a team of cyborgs devastating the ape attack force attempting to infiltrate. Leaping down from the ceiling, {APE}.replace => # immediately grabbed one of the cyborg’s weapons to create a distraction long enough for the apes to run in. The cyborg he had just disarmed then kicked {APE}.replace => #'s feet out from under him and followed up with a powerful stomp, caving in {APE}.replace => #'s ribs before wrenching the weapon back under his control. Before the cyborg could bring the gun around for a lethal shot at {APE}.replace => #, one of the other apes leapt onto the cyborg’s head, stabbing him multiple times in the neck and skull so that {APE}.replace => # could roll away. However, instead of moving, {APE}.replace => # caught the cyborg’s weapon again and sent a spray of plasma bolts into the other nearby cybernetic horrors before the first opponent stopped moving.",
    "In the mayhem of the attack on the core of the first shield generator, {APE}.replace => # pulled two grenades out before being kicked by a cyborg directly into the machine. Though the impact broke the ape’s spine, it also dented the housing of a critical part of the jumpshield generator. The cyborg reached down to acquire a discarded pistol, clearly intending to finish {APE}.replace => # off, and the last thing he saw was the two live incendiary grenades at his feet. The insanely hot fireball forced everyone nearby to stagger, providing the rest of the apes enough of a distraction to drag {APE}.replace => # past a blast door just before the other demolition charges in the room detonated.",
    "{APE}.replace => # managed to save three unconscious apes from a capture team. He leapt onto the agents as they were preparing a teleportation beacon. Though he received multiple bullet wounds, {APE}.replace => # managed to kick one agent into the beacon. This activated the device and caused a ten foot wide crater to appear where the agents had just been standing, leaving only pieces of melted weapons and half of a scorched severed foot behind. Though he was severely wounded, {APE}.replace => # managed to get the bodies of his team all the way to the extraction area. Amped on adrenaline, he turned to run back in before one of the evacuating apes pointed out that there was an electro spike sticking out of his right pectoral muscle. He casually pulled it out and tossed it aside before running back in.",
    "Racing to sabotage the first shield generator, {APE}.replace => # saw the ape in charge of demolitions get gunned down by automated turret fire. While pulling the demolition specialist’s body towards cover, {APE}.replace => # was shot through the thigh and hip. Rolling himself back to cover as well, he inspected his wounds briefly before taking the remote detonator from the other ape, only to discover it had been shot during the struggle, rendering it inoperable. Relaying this information to the other apes as he pulled his teammate to safety, the apes realized part of the shield generator would now have to be destroyed manually.",
    "Moving to support the attack on the first shield generator, {APE}.replace => # encountered a Tempus agent using a modified flamethrower to spray an extreme cooling agent on apes, freezing them almost instantly. Dodging through a hallway of apes-turned-ice-sculptures, {APE}.replace => # ran and leapt at the agent, using one of his outstretched hands like a shield before deflecting the nozzle of the weapon as the agent pulled the trigger. Barely touching the ground, {APE}.replace => # spun around to bring a solid spinning backfist to the face of the gas-masked agent. As his fist connected, he heard a sickening crunch, and {APE}.replace => # realized he had cracked and nearly shattered his almost frozen hand. The agent was lying still and out cold on the floor, but {APE}.replace => # confirmed the kill before instructing his squad to help him to recover as many of the frozen apes as he could.",
    "Working with other apes, {APE}.replace => # raced to help destroy one of the jumpshield extender nodes nearby. When he saw a Tempus capture team setting up a teleportation beacon around the corner, {APE}.replace => # swooped in and snatched it, then hurled the beacon at the temporal shield node. When the beacon activated so close to the node, it triggered an unstable anomalous time paradox, reverting the concrete of the bridge, the metal contained in nearby weapons, and the structure’s crystalline components to revert back to their raw forms, causing a blast of dust, rock, and metal to erupt out in all directions. The elemental blast pushed the spray out with such force that the particles scoured through the paint, then the metal of the wrecked hovercopter that {APE}.replace => # made a futile attempt to hide behind. The cutting wind flayed ape down to the bone along his head, back, and arms. The agents, who were not able to react in time, were turned into a pink mist of blood and viscera as the weaponized paradox collapsed back into itself.",
    "Shortly after scooping up an incapacitated ape as he ran, {APE}.replace => # was shotgunned through the back by a cyborg. {APE}.replace => # stumbled, but refused to give up, counting on his nanites to heal him as he ran. Once he reached the extraction point, {APE}.replace => # was handed a cyber staff that he then launched like a javelin at the rapidly approaching cyborg, spearing him in place against a wall long enough for another ape to run up and dispatch the corrupted biomechanical creature with a point blank plasma round to the head.",
    "Scrambling backwards on all four limbs to get away from a burning heavy assault mech, {APE}.replace => # was swept off the bridge by the force of the blast when the dying machine’s munitions cooked off all at once. Reaching out, he managed to get a hand around one of the crystal obelisks projecting out from a temporal shield node. His momentum was such that halting himself midflight caused the node to crack and break, causing a brief effect that warped time nearby, turning the ape briefly into a child, an old ape, a skeleton, then back to his original self in the blink of an eye. With eyes wide and hair standing on end, {APE}.replace => # checked to ensure the shield node was damaged beyond repair. It wasn’t his assignment, which meant someone owed him a beer later for doing their job.",
    "Finally cracking the code on the mech hanger, {APE}.replace => # watched, stunned, as the large bay doors pulled back to reveal eight heavy mech pilots arrayed in an assault formation as they prepared to deploy. A devastating volley of coordinated heavy machine gun fire shredded through the first line of apes that charged in. The hailstorm of armor piercing rounds tore into the doors, damaging the counterweight mechanisms and preventing the doors from being shut. Cutting their losses, the apes are forced to recover what bodies they can and withdraw to regroup.",
    "As the large doors open on the mech hangar, {APE}.replace => # looks up to see eight mechs standing ready to repel their assault. Ducking instinctively, {APE}.replace => # heard the rough growl of rapidly rotating bullet hoses sprayed hot lead into the apes running next to him. {APE}.replace => # watched helplessly as the rounds impacted other apes around him, sawing some completely in half, and dismembering many others. As the bodies fell around him, {APE}.replace => # was forced to lay among the dead as the rest of the unit retreated, and firmly resolved to wait until the mechs completely vacated the area. Later, after the mechs moved away and the coast was finally clear, {APE}.replace => # managed to recover as many bodies as he could carry and dragged them to an extraction zone.",
    "Standing at the back of the assault line prepared to storm the mech hangar, {APE}.replace => # watched the doors open only to see eight piloted mechs open fire on the apes. Grabbing the two apes nearest him, he ducked low and out of the way as an impossible volley of hot lead ripped through ape after ape before they had taken two steps forward. As quickly as he was able, he and the surviving apes pulled some of their comrades out of the line of fire, bringing the injured and mortally wounded apes to the extraction zone before digging in to defend the area.",
    "Joining a squad tasked with the assault of a mech hangar, {APE}.replace => # was able to react quickly when the doors retracted enough to reveal eight towering mechs waiting on them. As the waiting enemies unleashed hell, he jumped inside, racing forward, desperately leaping onto loading equipment and drawing fire from some of the armored hulks. {APE}.replace => # climbed up to the rafters where he swung away to safety. When he circled back around, he found dozens of members of his team had been cut down like wheat before the blade of a sickle, their unmoving remains concentrated near the main entry point. Not willing to let such evil go unpunished, {APE}.replace => # began stealthing his way over to one of the cargo loaders behind the still stationary enemies. {APE}.replace => # strapped acetylene tanks to the forklift with live grenades on top and gunned it, slamming one of the forks into the leg of a mech. The resulting explosion maimed one of the mechs, severely wounding the ape and forcing him to retreat, but the distraction allowed a scattered handful of apes he had believed dead to rise up and escape through the bay doors.",
    "Moving through the facility, {APE}.replace => # noticed a team of apes line up to assault the mech hangar nearby. As soon as the doors opened, he watched as a wall of gunfire began obliterating the assault team. The Tempus forces had laid a deadly trap. Moving as swiftly as he could, {APE}.replace => # loaded a grenade launcher with smoke canisters, stood on the lip of a bridge, and fired them inside the hangar to obscure the mech pilot’s vision and give the surviving apes a bit of cover to escape. Unfortunately, this made {APE}.replace => # a target for drone fire, and he was shot several times by a drone passing overhead, causing him to tumble from the bridge. He was fortunately able to catch himself before plummeting, and managed to climb to safety under the lip of the bridge to heal his wounds.",
    "Hacking into a doorway to clear a path toward the second temporal generator, {APE}.replace => # looked up to see a cyborg step into the archway as the pocket door slid open. As one of his teammates marched forward, the cyborg grabbed him by the chest mid-jump, pressing its clawed fingers behind the ape’s collar bones with one hand. Before the ape could begin to struggle, the cyborg grabbed him by the head with the other hand, ripping down and tearing out the victim's ribcage in one motion. {APE}.replace => # finally managed to overclock the door, closing it violently on the cyborg, splitting the creature in half and pinning him inside the doorframe. {APE}.replace => # then ran to find another path through.",
    "As his team members ran to the objective at the second temporal generator, {APE}.replace => # stood back to bottleneck the door. As Tempus agents followed behind, he unloaded his weapon on them until cyborgs were tripping over the dead to try and break his defense. Drawing a sword when he ran out of ammunition, {APE}.replace => # held off the flanking enemies for another twenty minutes before his wounds made him give out. Just as a cyborg stood over him, preparing to deliver the killing blow, a plasma round tore through its head. One of the apes had doubled back, and continued to hold the position long enough for {APE}.replace => # to heal.",
    "With the assistance of another ape, {APE}.replace => # used wire conduit to entangle an assault mech, causing him to crash, blocking a doorway and preventing Tempus agents from reinforcing their troops. Before the mech could recover, {APE}.replace => # launched a piece of rebar into the cockpit, killing the pilot. In retaliation, one of the Tempus snipers fired on the Mech’s power cell, causing it to explode, sending liquid fire in all directions and blowing a hole large enough for the reinforcements to get through once the fire had cleared. The blast knocked {APE}.replace => # into the side of the bridge where he was badly burned but managed to get away from the sniper fire with the assistance of his teammate.",
    "When charging back to the extraction point, {APE}.replace => # was impacted by electro bolas that wrapped around his ankles and pinned his arms to his sides. Tempus agents then ran up, set up a teleporter beacon and in a flash, he was gone. That was the last time anyone saw {APE}.replace => # alive.",
    "While running through the Tempus facility, {APE}.replace => # took the time to track down every restroom he could find, rigging hidden explosives to toilet flush-handles and seats before continuing on. Throughout the remainder of the battle, everytime he heard an explosion followed by a bursting pipe somewhere in the facility, he would grin from ear to ear.",
    "While pushing to the second shield generator, {APE}.replace => # was grabbed by the fist of a mech who squeezed the hydraulic cargo claw down on him. {APE}.replace => # felt his ribs and legs break before feeling the sickening pop of one of his internal organs. Just before he could be fully crushed, the mech’s arm was blown off by a rocket, sending the limb crashing into the river below. As the arm dragged him deeper and deeper into the water, {APE}.replace => # pushed with all of his remaining strength to pry open the fingers of his prison, then escaped to the dock. Even though he was severely wounded, {APE}.replace => # decided to press on, rather than wait to heal.",
    "While crawling through the air ducts of the facility, {APE}.replace => # overheard two Tempus scientists arguing. “Laser bolas? Isn’t that a bit hat-on-a-hat?” one asked. “What do you mean?” Said the other. Clarifying, the first scientist said, “I mean, the entire point of a bola is to incapacitate your quarry, right? What you are talking about would slice someone in half. I’ll save you 6 million in R&D right now and tell you that grenades and .50 cals already exist. Just kill them if you want to kill them. The fancy gadgetry just seems like a gratuitous mismanagement of resources.” Agreeing with their point, {APE}.replace => # dropped out of the ceiling, slamming the first scientist teeth-first into the countertop, snapping his neck as he landed. Before the other could react, {APE}.replace => # snatched the prototype and activated it before slinging the molten-hot cord whirling around the scientist’s head, slicing the man’s skull into 5 separate pieces. To the late scientist’s credit, it was a pretty cool weapon.",
    "While leading his team through the hallways of the Tempus facility, one of the pocket doors slammed shut behind him as he scouted ahead. As he tried futilely to pull it open, {APE}.replace => # was forced to watch through a small window while noxious gas canisters were fired in from the hallway behind the crew. The doors were then shut on the other side and {APE}.replace => # was forced to watch as his entire team tore themselves apart trying to escape the gas. By the time the gas cleared, only the still dissolving bodies of the apes remained. Hearing a team of cyborgs running down the hallway toward his position, {APE}.replace => # was forced to retreat in order to report the new tactic deployed against his team.",
    "After discovering a pathway between the walls, {APE}.replace => # heard the sounds of cyborgs defending a chokepoint to cut off ape reinforcements. {APE}.replace => # punched through the stone wall to attempt to grab one of the cyborgs, managing only to grab his rifle. The only thing the apes pinned down on the other side were able to see was a furry arm reaching out of the wall and trying to pull a rifle back through. As the other cyborg fired on the wall, the apes in the hallway heard muffled cries of pain followed by a brief silence. When the cyborg who lost its rifle went to retrieve the weapon, {APE}.replace => # pulled it back through the wall and began firing wildly, buying enough time for reinforcements to push forward and overrun the cyborgs’ position. When {APE}.replace => # rejoined them, it was clear he had sustained heavy damage, but bore the consequential wounds gladly, happy that his actions saved the lives of many other apes.",
    "{APE}.replace => # then walked into a supply closet. After finding various parts and repair materials, he covered himself in futuristic gear, attempting to camouflage himself as a cyborg. It must have worked because when he walked up to a Tempus checkpoint popping and locking while saying “Beep-boop! I sure love me some ape murder! Don’t mind me. Just a brainwashed, walking war crime coming through!” It confused the Tempus agents long enough for {APE}.replace => # to pull a revolver and fan the hammer rapidly at close range, killing the agents and securing the area for reinforcements.",
    "After recovering three electro-spike launchers from a Tempus capture team, {APE}.replace => # crawled into a space behind the walls to give himself enough time to disassemble the weapons and rig traps. After setting up along the inside of the walls of a hallway, {APE}.replace => # saw some of the shield generator assault team running away from a cyborg. Calling the apes over to him, he led them easily to safety through the hallway. However, when the cyborg tried to follow, he was met with round after round of electro spike firing out from the walls. In his death throes, the cyborg reflexively squeezed off a round of fire and {APE}.replace => # lept in front of the shot to prevent one of his team members from being killed. Though seriously injured, {APE}.replace => # was able to continue securing the hallway.",
    "After suffering multiple head injuries while taking down Tempus agents, {APE}.replace => # experienced a full on psychotic break. From his perspective, the apes had won, and he was helping decorate for the celebration. When an agent walked in on him, the man dropped his weapon and ran. {APE}.replace => # didn’t remember ordering a clown, so he continued on working. At one point, he thought “maybe it was someone’s birthday and he just forgot he called a clown.” So he chased the performer back down to let him know he got the right house. By the time other apes found him, one threw up in the corner. Confused by their reaction, {APE}.replace => # scratched his head. Feeling a piece of shrapnel lodged in his skull, he pulled it out only to realize as the nanites mended him that he had spent the last forty five minutes cutting the faces off of agents and hanging them from wires around a hallway. Intestines had been strung up like streamers, and the words “Harpin Berkdan!” were scrawled on the walls with blood. The corpses of dozens of agents lay mutilated in the hallway next to a catatonic agent with a glasgow smile and a bloody nose.",
    "Crossing through the halls of the Tempus facility, {APE}.replace => # passed a doorway where he heard several agents gearing up to reinforce the facilitie’s defenses. Without thinking, {APE}.replace => # burst in to find a room full of agents donning armor and equipping weapons. As an agent drew a pistol to fire at him, {APE}.replace => # crouched and rolled, coming up in a handspring to grab the agent’s weapon with one foot, while grasping onto his chestplate with the other. As the agent pulled the trigger, {APE}.replace => # shoved the barrel of the gun under the man’s chin. Upon dispatching the first agent, {APE}.replace => # started ripping pins out of grenades before grabbing the agent’s firearm and turning it on the surprised reinforcements. They fired back, sending plasma rounds and hot lead toward the ape ,who barely escaped before the grenades triggered a chain explosion that blew the support wall out, collapsing the entire room and killing everyone inside.",
    "“Two-four-oh-one-niner, this is Monkey Business Command to two-four-oh-one-niner. Comeback.” {APE}.replace => # called over the radio after capturing an air control tower. Realizing their air traffic communications were compromised, agents rushed in, triggering a bomb wired to the back of the door as well as another in the stairwell leading to it. {APE}.replace => # called out over the radio again, saying “We have bats in the clocktower. Repeat. There are bats in the clocktower. Send your cows to the market. Over.” An ape sitting to his left, who was flying drones into helicopters looked over at him confused. {APE}.replace => # just shrugged, covering the mic with one hand before saying, “I know we aren’t monkeys,” and pressing buttons on the control panel, causing a high-pitched feedback to play over the Tempus radio.",
    "{APE}.replace => # ran to help recover fallen apes before taking drone fire that sent him sprawling. As he went to pick himself up, the drone exploded, fired on by another drone that floated overhead, defending his position long enough for him to recover and pull bodies to extraction before racing back in.",
    "After making his way to the air control tower, {APE}.replace => # climbed over bodies to hack into the drone control network and began flying them at high speeds into mechs and helicopters, further adding to the absolute chaos on the bridge. This absolute mayhem led to one of the helicopters firing rockets at his position, blowing out the windows. Deciding it was time to reposition, {APE}.replace => # relocated to pull glass out of his skin and mend his burn wounds.",
    "Racing across rooftops to draw enemy fire away from apes escaping to the extraction point, {APE}.replace => # fired volleys of plasma rounds down onto Tempus agents. Having done, perhaps, too good of a job drawing enemy fire, {APE}.replace => # was blasted off his feet by a semi-accurate shot from a mech wielding an electro-cannon that caused the roof to cave in under his feet, sending the ape tumbling inside.",
    "{APE}.replace => # then climbed to the top of the air control tower with a sniper rifle where he began picking off capture teams trying to set up along the bridge. He had racked up fourteen confirmed kills before a sniper began firing back on his position. In trying to locate the sniper’s position, {APE}.replace => # was shot through the shoulder before he could return fire. The next enemy sniper shot went through {APE}.replace => #‘s scope, narrowly grazing his skull and taking out a chunk of the ape’s ear. Deciding to cut his losses, {APE}.replace => # made his way down the air control tower with one arm, allowing his shoulder to slowly heal.",
    "As he walked through the facility, {APE}.replace => # encountered an agent holding another ape at riflepoint. In trying to sneak up on the guard, he stepped on a weapon that had been dropped in the hallway. Hearing the clatter, the agent wheeled around and fired, sending a plasma bolt straight through the side of {APE}.replace => #. Using the distraction, the ape held hostage bit down on the Tempus agent’s leg, causing his next shot to go wide. Falling prone, {APE}.replace => # used his foot to grab the weapon and fire on the agent, sending a round straight between the man’s eyes. The freed ape ran up and helped support {APE}.replace => # to a location that was safe enough to heal.",
    "Running to support the attack on the second shield generator, {APE}.replace => # arrived in time to see waves of Tempus agents swarm the apes. Launching grenades and scrambling to avoid fire, {APE}.replace => # managed to knock two apes clear of a flashbang. The impact burst his eardrums and momentarily blinded him. While stumbling around, ape felt 4 bullets tear through him before he was yanked to cover. Once his vision cleared, he managed to push through the pain and cause a distraction long enough for many of the apes to retreat.",
    "Upon opening the doors to a room inside the second shield generator building, {APE}.replace => # was confronted with a team of cyborgs who immediately opened fire on him. Though he suffered several gunshot wounds, {APE}.replace => # managed to scramble around the room long enough to plant several explosive charges before having to escape into an air duct. As bullets flew into the vents, {APE}.replace => # eventually made it to a location where he could safely heal. Hearing the death cries of his teammates as they were mowed down by enemy fire, {APE}.replace => # decided to go back in.",
    "Deciding to infiltrate the second shield generator by rappelling from holes in the ceiling, {APE}.replace => # noticed a team of cyborgs waiting for the apes to breach the doors. As he attempted to call it in, his radio screeched from the shield’s interference. This alerted the cyborgs to his position and he was forced to drop 40 feet onto the floor below to avoid being killed. As the cyborgs trained their weapons on him, the apes managed to breach the walls, giving {APE}.replace => # just enough time to sneak around behind cover in order to download the machine’s data so that the apes could repurpose it. After gathering the data, he ran back to extraction to deliver the information before heading back into the fight.",
    "After infiltrating an aircraft hanger, {APE}.replace => # sat and watched the Tempus agents as they prepped and loaded the helicopters for takeoff. The coolest looking thing he saw was this tank of compressed air that one of the agents would fill with air from a nearby compressor. Once the tank was filled, he would bring it over, put the lip of the device against a deflated helicopter tire around a metal wheel rim, and pull the lever, sending a blast of air into the tire and forcing it to fit into an airtight bead. {APE}.replace => # snuck over while the agent was filling the tank, dropped down, slammed the agent’s mouth down onto the lip of the device and pulled the lever with his foot. Instead of having the agent inflate like a balloon, as was the ape’s intention, there was a loud crash of air, followed by a croaking scream somewhere between a moan and a gasp. As the agent fell back, the ape saw that his eyes had gone fully bloodshot, bulging out of their sockets, and his face had turned blue. Attempting to grasp at the ape, the agent pleaded wordlessly as {APE}.replace => # backed away. This was a mistake. {APE}.replace => # thought it would be funny and he accidentally exploded a man’s lungs. {APE}.replace => # left back out the way he came.",
    "{APE}.replace => # then noticed a mech positioning itself to wipe out retreating apes. Climbing up onto a nearby tower, {APE}.replace => # launched himself onto the back of the mech, sinking a sword into its exposed powercell, sending a gout of flaming acid out, causing the tank to explode before he could get fully clear. Badly burned and unable to walk, {APE}.replace => # was forced to crawl under some rubble and play dead while the nanites tried to heal his wounds to the degree at which they were able. With his ligaments and muscle being stitched back together, {APE}.replace => # pressed on with burned fur and exposed bone as he ran back into the fight.",
    "While attempting to hack into the automated turrets, {APE}.replace => # triggered an alarm that launched mini-drones into the hallway. As he raced to correct his mistake, he was blasted with machine gun fire from the drones that shattered the control station he was working at. Running as fast as he was able, {APE}.replace => # slid into one of the air vents, avoiding being outright killed by the drones, but unfortunately making the hallway a much more dangerous place for his teammates.",
    "In an attempt to hack open the doors to the second temporal shield generator, {APE}.replace => # accidentally triggered an alarm that caused enemy turrets to fire on their location. Though he survived with minor injuries, other apes were not so lucky. In retaliation, {APE}.replace => # launched several grenades at the turrets, disabling them, but also damaging the door beyond function. They would have to find another way in.",
    "In wandering through the halls of the Tempus facility, {APE}.replace => # was attacked by a small team of machine gun drones. The weapons tore through his legs and abdomen, but he managed to escape into a server room, locking the doors behind him. While inside, {APE}.replace => # hacked into the computers and managed to reroute the drones, sending them after a team of cyborgs to assist in one ape teams retreat from the second temporal generator.",
    "With his ears ringing from explosions, {APE}.replace => # looked over to see the mech that had wiped out his team was exploding from a fellow ape having stabbed its power cell. Moving quickly before any capture teams could come up, {APE}.replace => # made several trips carrying bodies to the extraction point.",
    "Stumbling into one of the assault team’s makeshift infirmaries, {APE}.replace => # worked to help smuggle the grievously wounded to an extraction point. On his fifth trip, he returned to find that a cyborg had located the medical area and laid waste to the remaining survivors. Picking up a bone saw, {APE}.replace => # leapt onto the cyborgs back and began sawing vigorously. Though the cyborg tried breaking the ape’s leg to pull him off, as well as firing a full clip of pistol rounds into the ape’s side, {APE}.replace => # continued cutting until the cyborg’s legs gave out and it fell limply to the ground. After sawing the cyborg’s head completely off, {APE}.replace => # kept it as a trophy."
  ];

  var loseArr3 = [
    "Rounding a corner in an escape attempt, {APE}.replace => # tripped over a wire, triggering an explosion that cut him off at the knees. His weapon slid from his hand and a high heel stepped onto it. {APE}.replace => # looked up to see none other than Director Whiplash, the leader of the Tempus organization, staring down at him in disgust, flanked by three cyborgs. “Look at you,” she sneered. “You will make a fine addition to my collection.” She motioned for one of the apes to gather him up, and as an electric prod rendered him unconscious, the last thing he heard was, “load his body with the others.”",
    "After making a bet with a fellow ape over who could drop from the highest point without a parachute, {APE}.replace => # decided to portal back in from high in the air. Coming in like a rocket, {APE}.replace => # smashed through a hovercopter from above, snagged a rocket pod on the way down and dove straight into the cockpit of a mech pilot, incinerating them both in the process. Legend.",
    "Stationed on the dock, {APE}.replace => # shot a hole in the fuel tank of one of the boats as it sped around. Tempus agents nearby began firing on the underwater apes while {APE}.replace => # laid down covering fire against the water skimmer drones. When a Tempus agent caught him off guard, pointing a gun at his head and saying “Any last words?” {APE}.replace => # responded “Got a light?” before tossing his lit joint into the water, causing a river of fire to rapidly snake along the surface of the water until it reached the ruptured tank and ignited it. The explosion sent shrapnel flying, shredding the head and body of {APE}.replace => # with shrapnel. Mistakes were made.",
    "{APE}.replace => #, infuriated about drones shooting constantly at him as he battled a cyborg, attempted to utilize a rope like a sling to hurl a live plasma grenade at one of the metallic nuisances, but messed up the knot. The plasma grenade got hooked in the slingshot, catapulting it directly back around the ape, killing him instantly",
    "{APE}.replace => # made it to a rooftop where he began covering the airborne reinforcements before a squad of agents poured out of a nearby door. He ripped the jaw off of one agent, using it as a digging tool to scoop out the throat of another before a utility mech stepped out from around a corner, scooped {APE}.replace => # up by the neck with one loader claw and deftly pushed down on the ape’s shoulders with the other, separating {APE}.replace => # into two parts before casually tossing his lifeless head into the water below.",
    "Faced with an impossible choice, {APE}.replace => # doubled back to hold a door shut to give his squad members time to reach extraction. His body was blown apart by heavy weapons fire, but he held off the Tempus troops long enough to save the lives of twenty apes.",
    "Watching a cryo grenade land at the feet of his squad, {APE}.replace => # bravely sacrificed himself by leaping on top of the device and absorbing the blast of chemicals, which froze him completely solid. Glory to the apes who laid down their lives in service of protecting other apes.",
    "As he made his way to extraction, {APE}.replace => # entered an elevator only to feel a pain in his chest. As he looked down, he saw a strange device protruding from his rib cage, with nanites swarming him, changing him. As the chill of death crawled up his spine, his mind began to fill with a voice that wasn't his. “Establish connection: Left hand.” His left hand opened and closed. “Establish connection: Right hand.” His right hand opened and closed. His knees felt weak and he began to stumble forward. “Establish connection: Spine.” His body went numb and he watched as his right leg moved on its own to balance him. “Establish connection: Brainstem.” {APE}.replace => # was forced to watch in horror as his body righted itself and turned to face his attacker without his permission. Pulling the device from his back, Commander Whiplash grinned at him. “Leaving so soon? Stay a while. I’m sure you’ll come to see things my way.” As she turned to leave, {APE}.replace => # followed dutifully behind her.",
    "Throwing himself in the line of fire to protect another ape, a plasma round ripped through his head, killing {APE}.replace => # instantly.",
    "Seeing safety just ahead, {APE}.replace => # ran to the extraction area only to be crushed by a falling helicopter just before being able to portal out.",
    "Surrounded by a pile of Tempus bodies, {APE}.replace => # caught his breath. The fight was over. Wounded and stumbling, {APE}.replace => # staggered closer to the extraction zone before being suddenly impaled through the chest, neck and brainstem by an electrified trident, killing him instantly.",
    "Running to the extraction zone, {APE}.replace => # was stopped in his tracks by a cyborg as the creature plunged a fist through his stomach, grabbed his spine and tore the ape in half with a single jerk of its arm.",
    "Rounding the corner of the facility, {APE}.replace => # came face to face with a cyborg, who unsheathed a knife and tossed it to him hilt first, before dropping into a stance and waiting for the attack. {APE}.replace => # grabbed the knife and rushed forward, thrusting as the cyborg twisted impossibly to grip {APE}.replace => #'s back leg and tear him off the ground. With his other hand, the cyborg grips the knife {APE}.replace => # holds, pulling with supernatural strength, {APE}.replace => # feels a pop as his arm is torn from its socket. Tossing the arm away, the cyborg then whips his prey against the ground over and over and over before stepping on {APE}.replace => #'s skull, ending his life.",
    "{APE}.replace => # ran with a team through the facility towards the extraction point but they were cut off by a cyborg. Thinking quickly, the apes dropped smoke canisters, filling the hallway with obscuring fog. Unfortunately, this seemed to be more of a deterrent to the apes, as the cyborg dispatched the apes easily while making his way through the smoke, never missing a rifle shot. The last thing {APE}.replace => # saw was a hand reaching for him out of the smoke and pinning him against a wall before crushing his skull.",
    "Managing to get the drop on a cyborg, {APE}.replace => # leapt down from an air vent, plunging a knife into the creature’s neck as several of his fellow apes rushed in with spears. The cyborg lifted his light machine gun and fired quick bursts through the heads of each of his attackers before pulling {APE}.replace => # off of his back, slamming him to the ground and firing another quick burst through his temple. As he stepped over him, the cyborg pulled the knife out, dropping it on the floor and continuing on as the apes died in the hallway behind him.",
    "After tossing a grenade and blowing off the arm of a cyborg, {APE}.replace => # rushed in to finish the job. As he did, the cyborg dispassionately reached for a length of cable with his free arm and looped it over the apes neck before pulling it tight and hurling him over the bridge yanking back with his full force at the moment the line went taut. The resulting pressure pulled {APE}.replace => #‘s head free of his shoulders. The last thing he saw was the waterfall spinning around him before it all went dark.",
    "Cornered by a cyborg, {APE}.replace => # attempted to escape out an open window, but the cyborg brought the window down so fast, it acted as a guillotine, snapping {APE}.replace => #‘s head free of his body.",
    "Firing a gatling gun at a cyborg from the cabin of a helicopter, {APE}.replace => # watched as the cyborg tanked the first two hits before ripping a bay door from a downed helicopter and used it as a momentary shield before launching it so hard at the ape that it cut both {APE}.replace => # and his mounted weapon in half.",
    "Running with his team to escape to the extraction zone, {APE}.replace => # was crushed unceremioniously by the foot of a massive mech while scrambling for cover. War is brutal.",
    "Racing away from the temporal shield zone, {APE}.replace => # was shot through the torso by a plasma rifle, living just long enough to watch his heart and lungs blast free of his ribcage.",
    "Running with his team toward extraction, {APE}.replace => # was unlucky enough to be grabbed by a mech and flung in a hundred foot arc off the waterfall as the mech pilot used him for target practice, punching seventeen holes in him with .50 cal round until only scraps were left to hit the rocks below.",
    "As he attempted to use the nanobanana for extraction, {APE}.replace => # was shot through the chest with an electro spike fired by a Tempus agent. As {APE}.replace => # fell, he collided with a temporal beacon, triggering a short circuit and a temporal anomaly to erupt after the nanobanana was triggered. The resulting effect was {APE}.replace => # reliving being shot through the chest and crashing into the temporal beacon over and over and over. No matter what he did or how he reacted, he would collide with the beacon over and over again until time faded away and all memories of a life before this moment were forgotten. To any observer, he was merely gunned down and vanished after colliding with a temporal beacon. To {APE}.replace => #, there was only an infinite purgatory loop of the last few seconds of his life for eternity.",
    "Making his way across the rooftops, {APE}.replace => # heard a rumble from deep within the superstructure, and recognized that the first jumpshield generator had failed. Less than a second later, he heard the distinctive sound of a teleporter several times from immediately behind him. Before he could fully turn around, a reinforcement squad of cyborgs had torn him limb from limb.",
    "After repositioning himself to the outside of the gigantic facility, {APE}.replace => # barely had time to react as a Tempus hovercopter ported in, flying directly towards him while launching a barrage of missiles and strafing with its cannons. Realizing he had nowhere to run, {APE}.replace => # grabbed an enchanted axe from a deceased ape and waited until the aircraft was close enough that he could see the pilot. In the blink of an eye, {APE}.replace => # pulled the pin on his last plasma grenade, jumped off the wall beside him and rebounded high enough that he could bury the axe in the hovercopter, holding the explosive above him as a guarantee that the same incandescent explosion that took him out would also destroy the gunship before it hurt any more of his friends.",
    "When charging back to the extraction point, {APE}.replace => # was impacted by electro bolas that wrapped around his ankles and pinned his arms to his sides. Tempus agents then ran up, set up a teleporter beacon and in a flash, he was gone. That was the last time anyone saw {APE}.replace => # alive.",
    "When making a break from cover, {APE}.replace => # was rooted to the ground by a shock net as a grenade fell at his feet. When the net tightened, {APE}.replace => # frantically reached for the grenade, tearing free of the net while being electrocuted, only to have the grenade detonate in his hand before he was able to toss it back. His remains were recovered by Tempus agents.",
    "Sprinting away from a cyborg that wiped out his whole team, {APE}.replace => # rounded the corner and slammed directly into another cyborg. As he turned to scramble around him, the creature grabbed him by the leg, pulling him off the ground and slamming him into the roof of the facility’s ceiling. As {APE}.replace => # was pulled back to earth, the second cyborg’s knee came up, breaking {APE}.replace => # ‘s jaw and snapping his neck. The cyborgs continued to stomp him until there was no head left to heal.",
    "Still in pain from previous injuries, {APE}.replace => # was cornered by two cyborgs. Though he made a valiant attempt to flee, The first ape grabbed him by the arm, tossing him into the second cyborg who grappled him. As the cyborg not holding him began to punch into his abdomen, {APE}.replace => # saw another wounded ape rounding the corner. As the second punch hit him, he spat blood. Noticing the other wounded ape was unarmed, {APE}.replace => # screamed into the face of the cyborg. “Run! Don’t worry about me. I’m right behind you.” Those were his final words as the ape continued to pummel him. In a last act of defiance, {APE}.replace => # looked up, smiling, and spat blood at the cyborg’s face, knowing in his heart that he had saved at least one more of his brothers. He died with peace in his heart when the cyborg crushed his skull.",
    "After plunging into the river from an explosion that knocked him into the waters, {APE}.replace => # opened his eyes to see a robotic shark swimming directly at him. {APE}.replace => # tried to swim away and saw a harry hand plunge into the water after him. Grabbing hold of the arm, {APE}.replace => # was jerked out of the water by a cyborg on one of the Tempus boats. He was slammed onto the deck of the boat and forcibly handcuffed at the hands and feet. The last thing he felt was cold metal being placed to the back of his neck before unimaginable pain. This caused him to wake up. Ape looked around. It was all a dream. He was safe in his bed, away from any danger. Wiping the moisture from his brow, {APE}.replace => # walked outside to the river to go splash his face with water and look up at the night sky. The stars seemed different somehow. More connected. As {APE}.replace => # bent down to drink from the river, he noticed his reflection in the moonlight. His features were different. His eyes had been replaced with sensors. His muscles had been augmented with steel and wires. As a chill went through him, he realized what had happened. It was now that he truly awoke. He was not in a dream, or by a river. He was far away, locked in a Tempus storage facility. Awaiting deployment.",
    "In an effort to make it to extraction, {APE}.replace => # ran across the bridge in retreat from Tempus reinforcements. As he ran, a shockwave knocked him from his feet. He fell into a roll and when he did, he looked up to see an airship of Tempus reinforcements with drones, and agents deploying in swarms. {APE}.replace => # got to his feet and ran as fast as he could, but one of the drones was too fast for him; firing an electrified net that struck him so hard, it flung him clear of the bridge and into the waters below. As he crashed into the river, he barely even had time to sink beneath the waves before a skimmer drone launched a hook device; scooping him up and pulling him over to shore where a team of capture agents were waiting. Though he fought hard to escape the net, the electrocution set him on fire due to his waterlogged state and the agents began kicking him into unconsciousness to put the flames out. That was the last anyone ever saw of him.",
    "Rocked from his feet by the shockwave of an incoming Tempus airship as it ported in, {APE}.replace => # looked up to see agent reinforcements pouring in from everywhere. Rolling away from enemy fire, {APE}.replace => # grabbed one of the agent’s guns and started firing on anything that wasn’t an ape. As the magazine went dry, {APE}.replace => # watched a cyborg land directly in front of him from 90 feet in the air; hitting the concrete it made the ape’s knees buckle. As {APE}.replace => # turned to run, the cyborg reached out to him, firing multiple lassos from his abdomen. The looped cables landed around {APE}.replace => # ‘s hips, arms and neck. As the cords were pulled tight, {APE}.replace => # was slammed to the ground as Tempus agents surrounded him, firing nets and beating him with stun batons. The last thing {APE}.replace => # saw before blacking out was his brothers escaping to freedom.",
    "Making an effort to pull one last downed ape to safety, {APE}.replace => # was struck with an anti-nanite stake through the thigh. The force of it pinned his leg to the wall. In desperation, not bothering to look at where the attack came from, {APE}.replace => # ripped his leg free, causing excruciating pain. Staggering forward, {APE}.replace => # picked his fallen brother up, limping to the door outside of the facility. As he reached the doorway, he felt another spike slam through his back, protruding just out of his chest. As he felt the nanomachines boil inside him, {APE}.replace => # made it just outside of the door. Seeing the extraction team near the river below, {APE}.replace => # called out for their attention before throwing the unconscious ape into the river far below. As he did, a hand grabbed him from behind, turning him around and slamming the protruding stake through his chest. “Damn these things are strong. Looks like we missed that other one,” the agent called back to another. As the other agent came forward, {APE}.replace => # looked on, too tired to fight as the agent set up a teleporter beacon. “One will have to do. See you on the other side, friend,” the other agent said, backing away. In a flash of light, {APE}.replace => # was gone, appearing in a small white cage with no padding. As he looked around his cage he tried ineffectually to pull the spikes out of his body. As a human face looked at him through reinforced glass. “Prep this one for cyborg alterations. They did a number on him. He’s going to need a lot of work. Make sure the robotics team is on standby.” With that, gas began to flood the chamber and {APE}.replace => # blacked out, never to wake the same again.",
    "When he rounded through the doors to the bioengineering facility, {APE}.replace => # saw a bunch of Tempus scientists running for cover. Before he could do anything he was struck from behind, into a table filled with strange liquids and lab equipment. As he fell on the other side, he heard the stomp of a cyborg walking over to finish the job. When he looked around for something to defend himself with, {APE}.replace => # ‘s eyes noticed his nanites reacting to the chemicals, forming tendrils that seemed to react to and absorb the liquids independent of his own thought. When the cyborg rounded the table, he reached out to grab the ape. As he gripped {APE}.replace => # by the shoulder, a nanite sludge formed around the cyborg's hand before launching spikes through. This caused them both considerable pain, and {APE}.replace => # was forced to watch as the tendrils of nanites raced like snakes into the cyborg's arm, swimming in and out of the creature’s skin before bursting out of the cyborg’s neck and burrowing into its eyes, ears, nose, and mouth, causing stagnant blood and machine oil to spill out as the cyborg died. As the nanites continued to swarm out of him in all directions, {APE}.replace => # felt himself somehow downloading the memories of the cyborg as the nanites worked to rewire his brain. When his vision cleared, he was surrounded by the dead bodies of agents and apes alike. He was no longer ape and no longer identified with their cause. He was not a cyborg. He had become something more; a brutal and indiscriminate killing machine capable of transcending known consciousness. With his ability to reality-hop, there would be no stopping him. He could destroy entire worlds if he wanted to, and not Tempus, nor the apes would stand in his way.",
    "During {APE}.replace => #'s attempt to evacuate, a falling mech pinned {APE}.replace => # to the ground, slamming into him with an outstretched hand that pinned him to the floor of the bridge. As a Tempus capture team moved in, a shockwave cracked the air above as a Tempus airship ported in overhead. The capture team began celebrating as their backup arrived. As {APE}.replace => # struggled to escape, one of the agents looked him over, saying, “Jeez, how are we going to retrieve this one with all that shit on top of him?” Without missing a beat, the other agent walked up to him and said, “They don’t need to be living to be useful. Take him in parts.” And with that, piece by piece, {APE}.replace => # was dismembered on the battlefield with the agents not bothering to waste a bullet on putting him out of his misery before teleporting him to an undisclosed location for operation.",
    "In a last stand with Tempus reinforcements, {APE}.replace => # blocked a doorway, allowing apes to run to extraction until he was overrun. As a cyborg speared him through the chest with a knife, the last thing he felt was a cold pop at the back of his skull before waking up on an operating table with his limbs separated from his body and his chest being retrofitted with mechanical lungs. Unable to draw breath to scream, ape was forced to watch the horrors play out until a woman in a surgical mask looked over at him saying, “Are his eyes supposed to be blinking like that? I thought we hadn’t affixed the neural control unit yet.” In response, a man in a surgical mask looked over at a machine detailing records of some kind. “Oh, sorry about that, bud,” he said. “We forgot to sedate the little guy.” After a few seconds, {APE}.replace => # blacked out again. The next time he regained awareness, he had no control of his body, and could only watch and scream silently as his body sat motionless in some sort of containment unit; hovering in the center of his vision were the words “awaiting orders.”",
    "In a mad dash to make it to the extraction point, {APE}.replace => # missed a sniper hidden among the rubble. The sniper fired a shot at the back of his knee, blowing off the limb at the joint. Tripped out of the run, {APE}.replace => # tumbled, breaking his teeth on the concrete. As he struggled to crawl away, another shot went through his elbow, breaking it apart from his upper arm. As he lay there on the ground, one of the Tempus capture teams ran up, setting up a barrier as other apes launched a failed rescue attempt. With his free hand, {APE}.replace => # grabbed the pins on the grenades attached to the agent closest to him. “What was that?” another agent asked. “He swiped at me; tried to grab my grenades.” That was the last thing any of them ever said as the explosion ripped apart everyone around him. The ape went out on his own terms, and took a few bastards with him.",
    "After running out of weapons, {APE}.replace => # began searching through the facility for survivors. He walked down an empty hallway until he came to a diverting hall, where he noticed a familiar face sitting in a chair. It was Director Whiplash, the managing director responsible for his cursed existence and the reason for this entire war. She was dressed in a sensible suit with red lapels and matching heels with one leg crossed over the other with a trail of blood leading into the room. “Do you have a minute?” she asked. {APE}.replace => # didn’t stop to think. He bolted forward and, noticing razor wire placed at his neck height at the last second, he bent his knees into a power slide to avoid decapitating himself. “Smart.” she commented. As the ape stood to his feet and ran through the doorway, a shotgun blast went off. Standing behind the door was a cyborg laying in wait. The slug ripped through {APE}.replace => #'s heart and lungs and he crumpled immediately. “My dear lost toy, you can’t expect to believe I act without contingencies. Please. Give me some credit.” As the lights began to fade from his eyes, the last thing {APE}.replace => # heard was “Leave his brain intact. I want the labcoats to run a diagnostic on this one’s reaction time.”",
    "Running unarmed through the hallways, {APE}.replace => # pursued a terrified agent until he came to a hallway with a funny smell. The agent bolted to the end of the hallway and hit a button, closing the doors on both sides. {APE}.replace => # slowed to a stop, looking around to gauge his situation, he saw small vents overhead that didn’t appear connected to the rest of the air vents in the system. As he noticed them, the fents began to exposit a thick cloud of gas. {APE}.replace => # threw himself at the doors, as he tried to find some way to release them, he held his breath. The doors were jammed fast. Next, he tried frantically and in vain to search the hallway for something to block the vents with. After 6 minutes, {APE}.replace => # was forced to take a breath in the room that had filled with the strange gas. As he did, he began to cough. As he felt himself begin to lose consciousness, in a last act of rebellion, he clawed a warning into the floor in the hopes that it would be noticed by the next ape. {APE}.replace => # fell asleep and the next time he regained awareness, he had no control of his body, and could only watch and scream silently as his now-mechanical body sat motionless in some sort of containment unit; hovering in the center of his vision were the words “Awaiting orders.”",
    "While racing to escape the facility, {APE}.replace => # was ambushed by a Temus capture team. One of them fired a weapon as he ran past a hallway, sending a molten-hot cord tangling around his legs that burned through almost instantly, separating his legs into 5 separate pieces. Screaming in agony, {APE}.replace => # tried to crawl away, but a second agent ran up and fired two anti-nanite stakes into his chest, pinning him to the ground. Looking up, the agent standing over {APE}.replace => # called back to his squadmate, “Jeez, man. What kinda weapon is that? You sawed his legs off! They are in cauterized chunks.” As the other agent walked up, he secured a strange weapon. “Yeah, it’s called a laser bola. One of my friends in development hooked me up.” The agent holding the spike gun clicked his tongue. “I mean- Seeing it in action like this is- like when is enough, right? We could have blown his legs off with a claymore. This just seems needlessly-” “Needlessly... sweet as hell? Did you see that thing?” The agent with the bola cut him off. “Just kill me already.” {APE}.replace => # groaned. Shrugging, the agent with the spike gun sent another anti nanite round through the ape’s head; killing him instantly.",
    "Pushing through the firefight, {APE}.replace => # tried to break the defenses at the second temporal shield generator. As he did, He managed to injure one cyborg by crushing its shoulder before {APE}.replace => # was cut in half by multiple plasma rounds. As he lay bleeding out, the injured ape raised up a foot and silently brought it down on the ape, popping his head like a cherry tomato.",
    "{APE}.replace => # ran to launch a rescue at the massacre happening at the push for the second shield generator. Though he was able to rescue several injured apes and provide cover for others, a shockwave knocked him from his feet as a Tempus airship teleported in above them. As it did, Tempus reinforcements began raining down near the first shield generator. In the chaos, {APE}.replace => # was shot with an anti-nanite round that pierced his neck, killing him instantly.",
    "Rocked from his feet from a shockwave as he made an attempt to mount a rescue of an injured ape, {APE}.replace => # looked up to see a Tempus airship overhead. Racing forward on all fours, {APE}.replace => # managed to grab the injured ape and throw him clear to the extraction team before the ship overhead fired a sonic cannon. {APE}.replace => # was caught in the blast, and the resulting impact practically microwaved him instantly as every cell in his body was vibrating so hard the resulting friction caused him to explode in a burst of blood and bone.",
    "After being paralyzed from a cannon blast, {APE}.replace => # managed to crawl to safety under a downed mech as Tempus reinforcements cut off any chance to escape.. In searching the pilot he found four grenades and a lighter. {APE}.replace => # proceeded to make himself comfortable, going so far as to roll and smoke a joint while waiting for the agents to find him. When he was finally discovered by the capture team, the agents moved in, surrounding him with their weapons drawn. “Oh,” {APE}.replace => # said. “Hello, boys. You’ve got me. I surrender.” Holding up his hands, {APE}.replace => # dangled the pins from the grenades. By the time the agents realized their mistake, it was too late. The explosion took out {APE}.replace => # along with five extraction team agents.",
    "After being hit with an anti-nanite round, {APE}.replace => # makes a break to the sniper that shot him. Vaulting over barriers and dodging fire, {APE}.replace => # races toward the sniper’s cover as a Shockwave goes off, causing him to stagger just enough for another round to just miss his head. As his previous wounds begin to open back up, {APE}.replace => # lunged at the Tempus agent before it could escape. Breaking their arms and legs and tossing them over the bridge while retaining their rifle, the ape hunkered down to continue providing support fire to other apes running to the extraction zones. Finding a small supply of ammunition, {APE}.replace => # fired round after round as Tempus agents, drones, capture teams, and cyborgs all rushed to prevent the apes from leaving. With the last bullet remaining in his gun, he executed a cyborg as it attempted to intercept one ape carrying another to safety, {APE}.replace => # closed his eyes and died, succumbing to his many wounds.",
    "Completing what missions he was able, {APE}.replace => # raced to the extraction point where he was staggered by the shockwave of a Tempus airship teleporting into the airspace above the remains of the first shield generator. Before he was able to rejoin his team, {APE}.replace => # was toppled by an agent in power armor landing on him from the ship above. Feeling that his spine had been crushed, {APE}.replace => # tried to stagger to his feet. As he did, the Tempus agent clicked a button on a gauntleted hand, causing a hum of energy to project around his fist before uppercutting the ape. As the fist impacted, {APE}.replace => # felt the crushing force obliterate all the bones in his skull. Onlookers claim to have seen the ape get hit so hard his brain literally launched free of his gelatinized skull.",
    "Deciding to get naked as the day he was born, {APE}.replace => # leapt into the turret when the Tempus airship arrived with reinforcements. Smoking a few joints and humming Fortunate Son, {APE}.replace => # began blasting Tempus scum from the skies. This unfortunately caused the drones to swarm toward the turret. As their bullets ripped through the protective shielding and battered the ape, he squeezed one hand around the trigger, and used the other to light a free joint. After taking down thirty seven drones by himself, {APE}.replace => # was officially recognized as dead when 3 drones intentionally crash-landed into the tower. Muttering to himself with his last breath, {APE}.replace => # said “That’s a forty to one ratio by my count. New high score.”",
    "Fighting his way free of Tempus agents, {APE}.replace => # was shaken by the displacement shockwave of a Tempus airship porting in overhead. Before continuing his escape attempt, {APE}.replace => # was cut off by enemy reinforcements. {APE}.replace => # tried to double back but encountered multiple other enemies. Deciding on a last stand being the only option, {APE}.replace => # picked up a pair of plasma rifles and began firing at every foe in sight, sending molten rounds through agents left and right. {APE}.replace => # survived multiple rounds of return fire before having eight temporal grenades tossed at his feet, causing a gravity well that crushed him into paint on the concrete.",
    "Upon making it clear of the slaughter at the second shield reactor, {APE}.replace => # ran toward the extraction zone only to have a concussive blast knock him down as he ran. When he looked up, he saw a Tempus airship overhead, sending waves of reinforcements to cut off his position. “Well, isn’t that just my luck,” he said. Seconds later, dislodged by the shockwave, a concrete wall pulled free of its housing, falling over and crushing the ape instantly. Turns out *that* was just his luck.",
    "Fighting to maintain supremacy as apes got clear of the first shield generator, {APE}.replace => # stayed behind to ensure the Tempus defenses were crippled. After rigging up several charges, a squad of cyborgs converged on his position. One shot him through the spine with an anti-nanite round as he was setting up the detonator. Pressing the final wire in place, {APE}.replace => # took one last look at his oppressors before flipping them the bird and blowing them all straight to hell.",
    "As {APE}.replace => # ran further through the facility, after running out of weapons,he happened upon an empty hallway which came to a diverting hall. There, he noticed a familiar face sitting in a chair. It was Director Whiplash, the managing director responsible for his entire existence and the reason for this cursed war. She was dressed in a sensible suit with red lapels and matching heels with one leg crossed over the other with a trail of blood leading into the room. “I’ve been following your career for some time,” she said. {APE}.replace => # rushed at her. Seething with hatred and blinded by rage, he never saw the razor wire at neck level. Using all his strength and speed to launch himself at her, he sliced his own head clean away from his body. {APE}.replace => #'s head hit the floor, a cyborg with a shotgun came out from behind the doorway. Looking at the ape’s decapitated corpse, Whiplash said to the cyborg, “Drag that one out of the hallway and clean the wire. Let’s start a pile of new friends for you to play with.”"
  ];

  async function getWarData(TokenToQuery, Index) {
    console.log(TokenToQuery)
    console.log(Index)

    let url = `https://api.atsnft.io/getwardata/` + TokenToQuery;

    axios.get(url)
      .then(async function (response) {


        var replace1 = "{APE}.replace => #"
        var replace2 = "{APE}.replace => #'"

        if (response.data[Index].result === "won") {


          var wonPrompt1 = winArr1[response.data[Index].prompt1].replace(new RegExp(replace1 + '|' + replace2, 'g'), function (match) {
            return match === replace1 ? "#" + TokenToQuery : TokenToQuery + "'s";
          });



          var wonPrompt2 = winArr2[response.data[Index].prompt2].replace(new RegExp(replace1 + '|' + replace2, 'g'), function (match) {
            return match === replace1 ? "#" + TokenToQuery : TokenToQuery + "'s";
          });


          var wonPrompt3 = winArr3[response.data[Index].prompt3].replace(new RegExp(replace1 + '|' + replace2, 'g'), function (match) {
            return match === replace1 ? "#" + TokenToQuery : TokenToQuery + "'s";
          });

        }
        else {

          var lostPrompt1 = loseArr1[response.data[Index].prompt1].replace(new RegExp(replace1 + '|' + replace2, 'g'), function (match) {
            return match === replace1 ? "#" + TokenToQuery : TokenToQuery + "'s";
          });

          var lostPrompt2 = loseArr2[response.data[Index].prompt2].replace(new RegExp(replace1 + '|' + replace2, 'g'), function (match) {
            return match === replace1 ? "#" + TokenToQuery : TokenToQuery + "'s";
          });

          var lostPrompt3 = loseArr3[response.data[Index].prompt3].replace(new RegExp(replace1 + '|' + replace2, 'g'), function (match) {
            return match === replace1 ? "#" + TokenToQuery : TokenToQuery + "'s";
          });

        }


        if (response.data[Index].result === "won") {
          document.getElementById("outcome").innerHTML = "WON!"
          document.getElementById("outcome").style.color = "lightgreen";
          document.getElementById("report1").innerHTML = wonPrompt1;
          document.getElementById("report2").innerHTML = wonPrompt2;
          document.getElementById("report3").innerHTML = wonPrompt3;

          buildTwitter(TokenToQuery, "won")
        }
        else {
          document.getElementById("outcome").innerHTML = "LOST"
          document.getElementById("outcome").style.color = "red";
          document.getElementById("report1").innerHTML = lostPrompt1;
          document.getElementById("report2").innerHTML = lostPrompt2;
          document.getElementById("report3").innerHTML = lostPrompt3;

          buildTwitter(TokenToQuery, "lost")
        }


        if (response.data.length === 0) {
          document.getElementById("report-pagination").innerHTML = "ROTA #" + TokenToQuery + " has not gone to war"
        }

      })
      .catch(function (error) {
        console.log(error)
      });
  }


  function delay(n) {
    return new Promise(function (resolve) {
      setTimeout(resolve, n * 500);
    });
  }



  const back = () => {

  }





  return (
    <div className="page-wrapper"  >
      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a href="https://atsnft.io/" className="navbar-brand pe-3">
            <img src="ape.png" width={47} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a href="https://records.atsnft.io/" className="nav-link text-nowrap active" style={{ color: "#e9ca7a" }}>Records</a>
                </li>
                <li className="nav-item">
                  <a href="https://mutation.atsnft.io/" className="nav-link text-nowrap">Looking Glass</a>
                </li>
                <li className="nav-item">
                  <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
                </li>
                <li className="nav-item">
                  <a href="https://warbonds.atsnft.io/" className="nav-link text-nowrap">War Bonds</a>
                </li>
                <li className="nav-item">
                  <a href="https://war.atsnft.io/" className="nav-link text-nowrap">War</a>
                </li>
                <li className="nav-item">
                  <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
                </li>
                <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
                <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
              </ul>
            </div>
            <div className="offcanvas-header border-top">
              <ConnectWallet
                btnTitle="Log In"
                theme="dark"
              />
            </div>
          </div>
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <ConnectWallet
            btnTitle="Log In"
            theme="dark"
          />
        </div>
      </header>

      <div id="ape-pfp" style={{
        minHeight: "92vh", overflow: "hidden", backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}>

        <section className="container d-md-flex align-items-center justify-content-between pb-3 py-5"  >
          <nav className="container">
            <div className="d-flex justify-content-left pt-4 py-5" >
              <a id="back" >
                <button type="button" className="btn btn-success bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginRight: "10px" }} >Go Back</button>
              </a>
              <a target="_blank" id="twitter-share" >
                <button type="button" className="btn btn-info bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginRight: "10px" }} >Share on Twitter</button>
              </a>
            </div>

          </nav>

        </section>


        <div className="container" >
          <section className="container">
            <div style={{ backgroundColor: "#151822", borderRadius: "25px" }} className=" rounded-3 overflow-hidden">
              <div className="row align-items-center">
                <div className="pt-5 pb-3 pb-md-5 text-center text-md-start">
                  <p className="lead mb-3 px-3">ROTA <span className='active-tickets' id="ape-number"></span></p>
                  <h2 className="h1 pb-3 pb-sm-4 px-3">Report <span className='active-tickets' id="report-number"></span></h2>
                  <h2 className="h1 pb-3 pb-sm-4 px-3">Outcome: <span id="outcome"></span></h2>
                  <p className="px-3" id="report1"></p>
                  <p className="px-3" id="report2" ></p>
                  <p className="px-3" id="report3"></p>
                </div>


              </div>
            </div>
          </section>
        </div>

      </div>


    </div>

  );
}

export default App;