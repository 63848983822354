import '../App.css';

import {
  useEffect,
  useState,
  useRef
} from 'react';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';
import {
  ethers
} from 'ethers';

import {
  useAddress,
  ConnectWallet
} from "@thirdweb-dev/react";

import AWS from 'aws-sdk';


function App() {

  useEffect(() => {
    getWinners();
  }, []);

  const x = () => {
    var records = "https://www.records.atsnft.io/tickets"

    var message = "We've updated our ApeTickets prize for next week!\n\nCheck it out here: " + records;

    var encodedTweet = encodeURIComponent(message);
    var twitterUrl = "https://twitter.com/intent/tweet?text=" + encodedTweet;
    window.open(twitterUrl, "_blank");
  }

  const apiEndpoint = "https://api.atsnft.io/getraffledata";
  const [rewards, setRewards] = useState([]);
  async function getWinners() {
    const options = {
      method: "GET",
      url: apiEndpoint,
    };

    try {
      const response = await axios.request(options);

      if (response.status === 200) {
        const winners = response.data.AMOUNT_OF_WINNERS;
        const rewards = response.data.REWARDS;
        setRewards(rewards);
      } else {
        console.log("Could not retrieve data");
      }
    } catch (error) {
      console.error("Error retrieving data: ", error);
    }
  }

  const [addr, setaddr] = useState('')

  const address = useAddress();

  useEffect(() => {
    if (address) {
      setaddr(address);
    }
  }, [address]);


  const previousAddressRef = useRef();
  useEffect(() => {
    previousAddressRef.current = address;
  }, [address]);
  const previousAddress = previousAddressRef.current;

  useEffect(() => {
    if (previousAddress && !address) {
      window.location.reload();
    }
  }, [address, previousAddress]);


  function revealConfirmPassword(e) {
    setPassword(e);
    document.getElementById("password-button").classList.remove("disabled");
  }

  function updatePassword(e) {
    document.getElementById("password-button").classList.add("disabled");
    document.getElementById("password2").style.display = null;
  }

  function revealConfirmPassword2(e) {
    setPassword2(e);
    document.getElementById("password-button2").classList.remove("disabled");
  }

  function updatePassword2(e) {
    document.getElementById("password-button2").classList.add("disabled");
    document.getElementById("show-upload").style.display = null;
    document.getElementById("submit-all").style.display = null;
  }


  function revealConfirm(e) {
    setWinners(e);
    document.getElementById("confirm-button").classList.remove("disabled");
  }

  function updateWinners(e) {
    document.getElementById("confirm-button").classList.add("disabled");
    document.getElementById("show-winner").style.display = null;
    document.getElementById("show-winner").innerHTML = "Winners: " + winners;
    document.getElementById("password").style.display = null;

    setGroupNumber(winners);
    setImageNames(Array.from({ length: winners }, (_, i) => `Prize ${i + 1}`));

  }

  const winnerRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordRef2 = useRef(null);

  const [winners, setWinners] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [groupNumber, setGroupNumber] = useState(0);
  const [imageNames, setImageNames] = useState([]);
  const [uploadedLinks, setUploadedLinks] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);


  const handleImageNameChange = (index, eventOrValue) => {
    const newName = typeof eventOrValue === 'object' ? eventOrValue.target.value.toString() : eventOrValue.toString();
    setImageNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = newName;
      return updatedNames;
    });
  };


  AWS.config.update({
    accessKeyId: 'AKIAVCLFUPFZZKOGG2VU',
    secretAccessKey: password2.toString(),
    region: 'us-east-1',
  });

  const s3 = new AWS.S3();

  const handleUpload = async () => {
    document.getElementById("submit-all").classList.add("disbaled");
    document.getElementById("submit-all").innerHTML = `Submitting<span class="text-loading">...</span>`
    const allFieldsFilled = imageNames.every((name, index) => {
      const file = document.getElementById(`file-${index}`).files[0];
      return name && file;
    });

    if (allFieldsFilled) {
      try {
        const uploadedLinks = [];
        const updatedUploadStatus = Array(groupNumber).fill(false);

        for (let i = 0; i < groupNumber; i++) {
          const file = document.getElementById(`file-${i}`).files[0];
          const fileName = `${imageNames[i]}.${file.name.split('.').pop()}`;

          // Upload file to S3 bucket
          const uploadParams = {
            Bucket: 'tickets-public',
            Key: fileName,
            Body: file,
          };

          const response = await s3.upload(uploadParams).promise();

          uploadedLinks.push(response.Location);
          updatedUploadStatus[i] = true;
        }

        const rewards = uploadedLinks.map((link, index) => {
          return {
            name: imageNames[index],
            image: link
          };
        });

        console.log(rewards); // Log the rewards array with names and image URLs

        const requestBody = {
          passcode: password.toString(),
          winners: winners,
          rewards: rewards
        };

        try {
          const response = await axios.post('https://api.atsnft.io/setrewards', requestBody);
          console.log(response);
          if (response.data.success) {

            document.getElementById("submit-all").innerHTML = `Success!`
            document.getElementById("submit-all").classList.add("disabled")
            document.getElementById("submitted").style.display = "none";
            window.location.reload();
          }
          else {
            alert('Invalid Password!');
            window.location.reload();
          }

        } catch (error) {
          console.error('Error pushing images to endpoint: ', error);
        }


        // ... continue with the remaining code to send rewards to the endpoint using Axios
      } catch (error) {
        console.error('Error uploading images: ', error);
        alert('Invalid Phrase!');
        window.location.reload();
      }
    } else {
      alert('Please fill in all files and images.');
      document.getElementById("submit-all").classList.remove("disbaled");
      document.getElementById("submit-all").innerHTML = "Submit All";
    }
  };


  return (
    <div className="page-wrapper"  >
      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a href="https://atsnft.io/" className="navbar-brand pe-3">
            <img src="ape.png" width={47} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a href="#" className="nav-link text-nowrap active" style={{ color: "#e9ca7a" }}>Admin</a>
                </li>
                <li className="nav-item">
                  <a href="https://records.atsnft.io/" className="nav-link text-nowrap" >Records</a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://war.atsnft.io/"
                    className="nav-link text-nowrap"
                  >
                    War
                  </a>
                </li>
                <li className="nav-item">
                  <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
                </li>
                <li className="nav-item">
                  <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
                </li>
                <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
                <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
              </ul>
            </div>
            <div className="offcanvas-header border-top">
              <ConnectWallet
                btnTitle="Log In"
                theme="dark"
              />
            </div>
          </div>
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <ConnectWallet
            btnTitle="Log In"
            theme="dark"
          />
        </div>
      </header>

      <section className="container d-md-flex align-items-center justify-content-between pb-3 py-5"  >
        <nav className="container">
          <div className="d-flex justify-content-left pt-4 " >
            <a id="back" href="/tickets">
              <button type="button" className="btn btn-success bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginRight: "10px" }} >View ApeTickets</button>
            </a>
            <button type="button" className="btn btn-info bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginRight: "10px" }} onClick={x} >𝕏</button>
          </div>

        </nav>
      </section>


      <div className="container" >
        <section className="container">
          <div className=" rounded-3 overflow-hidden">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {rewards.map((reward, index) => (
                <div key={index} style={{ margin: "10px" }}>
                  <h4 className="lead mb-3" style={{ textAlign: "center", marginTop: "15px" }}>{reward.name}</h4>
                  <img
                    src={reward.image}
                    alt={reward.name}
                    style={{ maxHeight: "200px", marginBottom: "15px", borderRadius: "10px" }}
                  />
                </div>
              ))}
            </div>

            <h1 className="lead mb-3">Tickets Admin Dashboard</h1>
            <div className="row align-items-center">
              <div className="text-center text-md-start">

                <div id="submitted">
                  <form id="search-model" className="input-group mb-4" style={{ maxWidth: '500px' }} onSubmit={e => { updateWinners(e) }} >
                    <input id="winners" type="text" placeholder="Number of Winners" className="form-control rounded" ref={winnerRef} value={winners} onChange={e => revealConfirm(e.target.value)} />
                    <button id="confirm-button" type="button" className="btn btn-primary  btn-sm fs-sm rounded d-lg-inline-flex disabled" onClick={e => updateWinners(e)} style={{ marginRight: "20px" }} >Confirm</button>
                  </form>

                  <h1 className="lead mb-3" style={{ display: "none" }} id="show-winner"></h1>


                  <form id="password" className="input-group mb-4" style={{ maxWidth: '500px', display: "none" }} onSubmit={e => { updatePassword(e) }} >
                    <input id="passwords" type="text" placeholder="Insert Password" className="form-control rounded" ref={passwordRef} value={password} onChange={e => revealConfirmPassword(e.target.value)} />
                    <button id="password-button" type="button" className="btn btn-primary  btn-sm fs-sm rounded d-lg-inline-flex disabled" onClick={e => updatePassword(e)} style={{ marginRight: "20px" }} >Submit</button>
                  </form>

                  <form id="password2" className="input-group mb-4" style={{ maxWidth: '500px', display: "none" }} onSubmit={e => { updatePassword2(e) }} >
                    <input id="passwords2" type="text" placeholder="Insert Secret Phrase" className="form-control rounded" ref={passwordRef2} value={password2} onChange={e => revealConfirmPassword2(e.target.value)} />
                    <button id="password-button2" type="button" className="btn btn-primary  btn-sm fs-sm rounded d-lg-inline-flex disabled" onClick={e => updatePassword2(e)} style={{ marginRight: "20px" }} >Submit</button>
                  </form>

                  <div id="show-upload" style={{ display: "none" }}>
                    {imageNames.map((imageName, index) => (
                      <div key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                        <input
                          id={`file-${index}`}
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageNameChange(index, e)}
                        />
                        <input
                          type="text"
                          value={imageName}
                          onChange={(e) => handleImageNameChange(index, e.target.value)} // Pass the updated value
                          className="form-control rounded"
                          style={{ width: '100%', maxWidth: '200px' }}
                        />
                      </div>
                    ))}


                    {uploadedLinks.length > 0 && (
                      <div>
                        <h3>Uploaded Links:</h3>
                        {uploadedLinks.map((link, index) => (
                          <p key={index}>{link}</p>
                        ))}
                      </div>
                    )}

                  </div>

                  <br></br>
                </div>
                <button id="submit-all" onClick={handleUpload} className="btn btn-primary  btn-sm fs-sm rounded " style={{ display: "none" }} >Submit All</button>
                <br></br><br></br>

              </div>


            </div>
          </div>
        </section>
      </div>

    </div>




  );
}

export default App;