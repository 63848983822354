import "../App.css";
import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import atsTraits from "../traits/atsnftTraits.json"
import rotaTraits from "../traits/rotanftTraits.json"
import axios from "axios";
import { ethers } from "ethers";


import {
  useAddress,
  ConnectWallet
} from "@thirdweb-dev/react";

import Modal from 'react-modal';

import Confetti from "react-confetti";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createDocumentRegistry } from "typescript";


const customStyles = {
  content: {
    position: 'absolute',
    textAlign: 'center',
    top: '10%',
    left: '10%',
    // right: '20%',
    //bottom: '10%',
    //transform: 'translate(-50%, -50%)',
    maxWidth: '80vw', // Adjust the width as needed
    backgroundColor: 'black',
    border: '2px solid #fff',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.4)',
    padding: '.5rem',
  },
};

function App() {
  const apiEndpoint = "https://api.atsnft.io/getraffledata";
  const apiEndpointWinners = "https://api.atsnft.io/getraffle/";
  const displayLeaderboard = useRef(null);


  const [addr, setAddr] = useState(null);
  const [raffles, setRaffles] = useState(null);

  const [isConfettiPlaying, setIsConfettiPlaying] = useState(false);
  const [isConfettiPlayingPrize, setIsConfettiPlayingPrize] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  // const playConfetti = () => {
  //   setIsConfettiPlaying(true);
  //   setTimeout(() => {
  //     setIsConfettiPlaying(false);
  //   }, 10000);
  // };

  const playConfettiPrize = () => {
    setIsConfettiPlayingPrize(true);
    setTimeout(() => {
      setIsConfettiPlayingPrize(false);
    }, 10000);
  };


  var down = false;

  useEffect(() => {
    if (down) {
      document.getElementById("down").style.display = null
      document.getElementById("up").style.display = "none"
    }
    else {
      document.getElementById("up").style.display = null
      document.getElementById("down").style.display = "none"
      getDrawing();
      getWinners()
      myDirectory.current.innerHTML = "";
    }
  }, []);

  const [rewards, setRewards] = useState([]);
  const [rewardsLast, setRewardsLast] = useState([]);

  async function getWinners() {
    const options = {
      method: "GET",
      url: apiEndpoint,
    };

    try {
      const response = await axios.request(options);

      if (response.status === 200) {
        const winners = response.data.AMOUNT_OF_WINNERS;

        if (response.data.REWARDS.length === 0) {
          document.getElementById("current-winners").innerHTML = "&nbsp;(TBD)";
          document.getElementById("reveal-prize-button").classList.add("disabled-button")
        }
        else {

          if (winners === 1) {
            document.getElementById("current-winners").innerHTML = "&nbsp;(" + winners + ")";
          }
          else {
            document.getElementById("current-winners").innerHTML = "&nbsp;(" + winners + ")";

          }

        }

        const rewards = response.data.REWARDS;
        setRewards(rewards);
      } else {
        console.log("Could not retrieve data");
      }
    } catch (error) {
      console.error("Error retrieving data: ", error);
    }
  }


  async function getWinnersLast(x) {
    const options = {
      method: "GET",
      url: apiEndpointWinners + x,
    };

    try {
      const response = await axios.request(options);

      if (response.status === 200) {
        const winners = response.data.winners.length;

        if (winners === 1) {
          document.getElementById("current-winners-last").innerHTML = "&nbsp;(" + winners + ")";
        }
        else {
          document.getElementById("current-winners-last").innerHTML = "&nbsp;(" + winners + ")";

        }

        const rewards = response.data.rewards;
        setRewardsLast(rewards);
      } else {
        console.log("Could not retrieve data");
      }
    } catch (error) {
      console.error("Error retrieving data: ", error);
    }
  }

  var testnet = false;
  var testnetContract = "0x2F7a47a8048c3ba47Ce803B824Da3AA7D2a11177";

  var isConnected = 0;

  var admins = ["0x2349334b6c1ee1eaf11cbfad871570ccdf28440e", "0x6e02e27f03ae982a3de019bcdb01aef3ca8a91e0", "0xd96e4656f8906b215b2ca71a785ace94e1fa278b", "0xd667aae67acf222ac7cac6796f53b20510999b6c", "0x3978a70acce93153f524e8fcdcba1e3ace0ac05b", "0x2760ac85236795eca08e474fdc3e7736c4ddef2e"]


  const address = useAddress();


  useEffect(() => {
    if (address) {
      document.getElementById("myposition").style.display = null;
      document.getElementById("check-connect").style.display = "none";
      myDirectory.current.innerHTML = "";
      // document.getElementById("next-drawing").innerHTML = `<span class="text-loading">...</span>`;
      document.getElementById("show-buttons").style.display = null;
      document.getElementById("connect-wallet").style.display = "none";


      if (admins.indexOf(address.toLowerCase()) !== -1) {
        document.getElementById("admin").style.display = null;
      }

      if (isConnected == 0) {
        if (!testnet) {
          setAddr(address);
          startSearch(address);
          getDrawing(address);
        } else {
          setAddr(testnetContract);
          startSearch(testnetContract);
          getDrawing(testnetContract);
        }
      }
      isConnected = 1;
    }
  }, [address]);


  const previousAddressRef = useRef();
  useEffect(() => {
    previousAddressRef.current = address;
  }, [address]);
  const previousAddress = previousAddressRef.current;

  useEffect(() => {
    if (previousAddress && !address) {
      window.location.reload();
    }
  }, [address, previousAddress]);


  function delay(n) {
    return new Promise(function (resolve) {
      setTimeout(resolve, n * 500);
    });
  }

  const contract = {
    address: "0xDfB29501b42f63A947Ddc5249F185D6BcBE6986f",
    abi: [
      "function balanceOf(address account) external view returns (uint256)",
      "function ownerOf(uint256 tokenId) public view override returns (address)",
      "function tokenOfOwnerByIndex(address owner, uint256 index) external view returns (uint256 tokenId)",
    ],
  };

  var rotaTokens = [];

  //gets all tokens, including those from War. DEPRECIATED
  async function startSearch(addr) {
    const results = await axios.post(
      `https://api.thegraph.com/subgraphs/name/0x-g/rota-tracker`,
      {
        query:
          `{
            tokens(first:1000 orderBy: token, orderDirection: asc, where:{owner_contains_nocase:"` +
          addr +
          `"}) {
              tokenId
            }
          }`,
      }
    );

    for (var i = 0; i < results.data.data.tokens.length; i++) {
      rotaTokens.push(results.data.data.tokens[i].tokenId);
    }

    getMyNFTsGen(addr, rotaTokens);

    document.getElementById("show-apes").style.display = null;
  }

  async function getDrawing(addr) {
    const options = {
      method: "GET",
      url: apiEndpoint,
    };

    let response = await axios.request(options);

    if (response.status == 200) {
      if (Number(response.data.raffles_completed) > 0) {
        document.getElementById("reveal").style.display = null;
      }
      setRaffles(response.data.raffles_completed);
      getWinnersLast(response.data.raffles_completed)

      if (addr !== null) {
        getStats(addr);
      }

      var responseText = response.data.timeUntil;

      document.getElementById("next-drawing").innerHTML = "ApeTickets will return after the Grapes merge!";

      //document.getElementById("next-drawing").innerHTML = responseText;

      //       // Parse response string to extract days, hours, and minutes
      // const regex = /(\d+) days (\d+) hours and (\d+) minutes/;
      // const match = regex.exec(responseText);
      // const days = parseInt(match[1]);
      // const hours = parseInt(match[2]);
      // const minutes = parseInt(match[3]);

      // // Calculate total seconds remaining
      // const totalSeconds = (days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60);

      // // Start countdown timer
      // let secondsRemaining = totalSeconds;
      // const countdownInterval = setInterval(() => {
      //   secondsRemaining--;
      //   if (secondsRemaining < 0) {
      //     clearInterval(countdownInterval);
      //     document.getElementById("next-drawing").innerHTML = 'Now! Drawing Winner' + `<span class="text-loading">...</span>`;
      //   } else {
      //     const daysRemaining = Math.floor(secondsRemaining / (24 * 60 * 60));
      //     const hoursRemaining = Math.floor((secondsRemaining % (24 * 60 * 60)) / (60 * 60));
      //     const minutesRemaining = Math.floor((secondsRemaining % (60 * 60)) / 60);
      //     const secondsDisplay = secondsRemaining % 60;
      //     document.getElementById("next-drawing").innerHTML = `${daysRemaining} days ${hoursRemaining}:${minutesRemaining}:${secondsDisplay}`;
      //   }
      // }, 1000);
    } else {
      console.log("could not retrieve data");
    }
  }

  async function getLastWinner() {
    console.log(raffles);
    const options = {
      method: "GET",
      url: apiEndpointWinners + raffles,
    };

    let response = await axios.request(options);

    var results = response.data;

    document.getElementById("show-winners").innerHTML = "";

    if (response.status == 200) {
      for (var i = 0; i < results.winners.length; i++) {
        getPFP(results.winners[i]);
      }
    } else {
      console.log("could not retrieve data");
    }
  }

  async function getStats(addr) {
    const options = {
      method: "GET",
      url: apiEndpoint,
    };

    let response = await axios.request(options);

    var results = response.data;

    if (response.status == 200) {
      var raffleData = response.data.current_raffle;

      document.getElementById("total-tickets").innerHTML =
        raffleData[raffleData.length - 1].end.toLocaleString();

      // const find = raffleData.find(p => p.wallet === addr);

      // if (find) {
      //   var end = find.end;
      //   var start = find.start;
      //   var tickets = end - start + 1;
      //   document.getElementById("tickets").innerHTML = tickets;
      // } else {
      //   document.getElementById("tickets").innerHTML = "0" + "&nbsp;"
      // }

      // getLeaderboard(LastRaffleId);
    } else {
      console.log("could not retrieve data");
    }
  }

  async function getPFP(address) {
    document.getElementById("show-winners").style.display = null;

    document.getElementById("reveal").innerHTML = "Revealed";
    document.getElementById("reveal").classList.add("disabled-nav")

    var wallet =
      address.substring(0, 4).toUpperCase() +
      "..." +
      address.substring(address.length - 4).toUpperCase();
    document.getElementById("show-winners").innerHTML += `<a style="text-decoration:none" target="_blank" href="https://opensea.io/` + address + `"><div>` + wallet + `</div></a>`;


    return;
  }

  const gen = () => {
    myDirectory.current.innerHTML = "";
    document.getElementById("gen").classList.add("active-tickets");
    document.getElementById("rot").classList.remove("active-tickets");
    document.getElementById("gen-button").classList.add("active-button");
    document.getElementById("rot-button").classList.remove("active-button");
    getMyNFTsGen(addr);
  };

  const rot = () => {
    myDirectory.current.innerHTML = "";
    document.getElementById("gen").classList.remove("active-tickets");
    document.getElementById("rot").classList.add("active-tickets");
    document.getElementById("gen-button").classList.remove("active-button");
    document.getElementById("rot-button").classList.add("active-button");
    getMyNFTsRot(addr);
  };

  async function getMyNFTsOS(type, address) {
    let nftData = []
    console.log("Retrieving NFTS for " + type);
    //get all NFTs in a collection
    let ats_options = {
      method: 'GET',
      url: `https://api.opensea.io/api/v2/chain/ethereum/account/${address}/nfts`,
      params: {
        collection: type,
        limit: "200"
      },
      headers: { accept: 'application/json', 'X-API-KEY': 'e9626a90e3c442299716d603d7afb812' }
    };

    let ats_nfts = await axios.request(ats_options);

    nftData = ats_nfts.data.nfts;

    let nftTraits = type == "atsnft" ? atsTraits : rotaTraits

    for (let nft of nftData) {
      nft.traits = nftTraits.find(x => x.identifier === nft.identifier).traits;
    }

    return nftData
  }

  async function getMyListingsFromOS(type, nftData) {
    let listingData = []
    let ats_options = {
      method: 'GET',
      url: `https://api.opensea.io/api/v2/listings/collection/${type}/all`,
      params: {
        limit: '100',
        next: ""
      },
      headers: { accept: 'application/json', 'X-API-KEY': 'e9626a90e3c442299716d603d7afb812' }
    };
    let ats_listings = await axios.request(ats_options);

    while (ats_listings.data.next) {
      await delay(.5)
      ats_options = {
        method: 'GET',
        url: `https://api.opensea.io/api/v2/listings/collection/${type}/all`,
        params: {
          limit: '100',
          next: ats_listings.data.next,
        },
        headers: { accept: 'application/json', 'X-API-KEY': 'e9626a90e3c442299716d603d7afb812' }
      };

      let temp = await axios.request(ats_options);
      ats_listings.data.listings.concat(temp.data.listings)
      ats_listings.data.next = temp.data.next
    }
    for (let listing of ats_listings.data.listings) {
      listingData.push(listing.protocol_data.parameters.offer[0].identifierOrCriteria)
    }
    listingData = [...new Set(listingData)]

    for (let nft of nftData) {
      if (listingData.includes(nft.identifier)) {
        nft.listed = true
      } else {
        nft.listed = false
      }
    }

    return nftData
  }

  const myDirectory = useRef(null);

  var listedApes = 0;
  var totalApes = 0;

  async function getMyNFTsGen(addr) {
    totalApes = 0;
    listedApes = 0;

    var ticketsFromGen = 0;

    myDirectory.current.innerHTML = "";

    var asset_contract = "0x7cb90567a118cd9f6ca326067a0813b289bdcb54";
    var path = "genesis";
    var tickets = 3;

    let result = await getMyNFTsOS("atsnft", addr)
    result = await getMyListingsFromOS("atsnft", result)

    myDirectory.current.innerHTML = "";

    if (result.length === 0) {
      myDirectory.current.innerHTML = "You have no Genesis assets";
    }

    for (var i = 0; i < result.length; i++) {
      totalApes += 1;

      if (!result[i].listed) {
        var name = "N/A";
        var fur = "1:1";

        try {
          name = result[i].traits.find(
            (x) => x.trait_type === "Name"
          ).value;

          if (name.length > 0) {
            tickets = 15;
          } else {
            tickets = 3;
          }
        } catch (e) {
          tickets = 3;
        }

        try {
          fur =
            result[i].traits.find((x) => x.trait_type === "Fur").value +
            " Fur";

          if (fur === "Gold Fur") {
            tickets = 6;
          } else {
            if (tickets < 6) {
              tickets = 3;
            }
          }
        } catch (e) {
          if (tickets < 6) {
            tickets = 3;
          }
        }
      } else {
        tickets = 0;
        fur = "This Token is Listed";
        listedApes += 1;
      }

      ticketsFromGen += tickets;

      if (tickets === 0) {
        myDirectory.current.innerHTML +=
          `       <div class="listed container swiper-slide h-auto">
          <article class="card h-100 border-0 bg-transparent">
            <div class="position-relative">
              <a  target="_blank" href="` +
          path +
          `?token=` +
          result[i].identifier +
          `"><img src="` +
          result[i].image_url +
          `" class="rounded-3" alt="Ape"></a>
            </div>
            <div class="card-body pb-0 px-0">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
          tickets +
          `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>
  
                <span class="fs-sm text-muted">#` +
          result[i].identifier +
          `</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
          fur +
          `</span></div>
              <a class="fs-sm" target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
          result[i].identifier +
          `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
            </div>
            </div>
          </article>
        </div>`;
      } else {
        myDirectory.current.innerHTML +=
          `       <div class="container swiper-slide h-auto">
        <article class="card h-100 border-0 bg-transparent">
          <div class="position-relative">
            <a  target="_blank" href="` +
          path +
          `?token=` +
          result[i].identifier +
          `"><img src="` +
          result[i].image_url +
          `" class="rounded-3" alt="Ape"></a>
          </div>
          <div class="card-body pb-0 px-0">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
          tickets +
          `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

              <span class="fs-sm text-muted">#` +
          result[i].identifier +
          `</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
          fur +
          `</span></div>
            <a class="fs-sm" target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
          result[i].identifier +
          `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
          </div>
          </div>
        </article>
      </div>`;
      }
    }
    console.log(`Apes Listed: ${listedApes}. Total Apes: ${totalApes}`)

    getMyNFTsRotTotal(
      addr,
      ticketsFromGen,
      totalApes,
      listedApes
    );
  }

  async function getMyNFTsGenTotal(addr, total) {
    var asset_contract = "0x7cb90567a118cd9f6ca326067a0813b289bdcb54";
    var path = "genesis";
    var tickets = 3;

    let result = await getMyNFTsOS("atsnft", addr)
    result = await getMyListingsFromOS("atsnft", result)

    for (var i = 0; i < result.length; i++) {
      if (!result[i].listed) {
        var name = "N/A";
        var fur = "1:1";

        try {
          name = result[i].traits.find(
            (x) => x.trait_type === "Name"
          ).value;

          if (name.length > 0) {
            tickets = 15;
          } else {
            tickets = 3;
          }
        } catch (e) {
          tickets = 3;
        }

        try {
          fur =
            result[i].traits.find((x) => x.trait_type === "Fur").value +
            " Fur";

          if (fur === "Gold Fur") {
            tickets = 6;
          } else {
            if (tickets < 6) {
              tickets = 3;
            }
          }
        } catch (e) {
          if (tickets < 6) {
            tickets = 3;
          }
        }
      } else {
        tickets = 0;
        fur = "This Token is Listed";
        listedApes += 1;
      }

      total += tickets;
    }
  }

  async function buildRota(response) {
    var totalTickets = 0;
    myDirectory.current.innerHTML = "";
    var path = "rota";
    var tickets = 1;

    const result = response.data.assets;

    let nftTraits = JSON.parse(rotaTraits.toString())

    for (let nft of result) {
      nft.traits = nftTraits.find(x => x.identifier === nft.identifier).traits;
    }

    if (result.length === 0) {
      myDirectory.current.innerHTML = "You have no ROTA assets";
    }

    for (var i = 0; i < result.length; i++) {
      if (result[i].seaport_sell_orders === null) {
        var name = "N/A";
        var fur = "1:1";
        var tier = "White";

        try {
          name = result[i].traits.find((x) => x.trait_type === "Name").value;

          if (name.length > 0) {
            tickets = 10;
          } else {
            tickets = 1;
          }
        } catch (e) {
          tickets = 1;
        }

        try {
          fur =
            result[i].traits.find((x) => x.trait_type === "Fur").value + " Fur";

          if (fur === "Gold Fur") {
            tickets = 2;
          } else {
            if (tickets < 2) {
              tickets = 1;
            }
          }
        } catch (e) {
          if (tickets < 2) {
            tickets = 1;
          }
        }

        try {
          tier = result[i].traits.find((x) => x.trait_type === "Tier").value;
        } catch (e) {
          tier = "Not Revealed";
        }

        if (fur === "Gold Fur") {
          if (tier === "War Hero Tier") {
            if (tickets > 24) tickets = tickets;
            else tickets = 24;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 16) tickets = tickets;
            else tickets = 16;
          }

          if (tier === "Gold Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Silver Tier") {
            if (tickets > 6) tickets = tickets;
            else tickets = 6;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }
        } else {
          if (tier === "War Hero Tier") {
            if (tickets > 12) tickets = tickets;
            else tickets = 12;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Gold Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }

          if (tier === "Silver Tier") {
            if (tickets > 3) tickets = tickets;
            else tickets = 3;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 2) tickets = tickets;
            else tickets = 2;
          }

          if (tier === "Not Revealed") {
            if (tickets > 1) tickets = tickets;
            else tickets = 1;
          }
        }
      } else {
        console.log(result[i]);
        tickets = 0;
      }

      totalTickets += tickets;

      if (tickets === 0) {
        myDirectory.current.innerHTML +=
          `       <div class="container listed swiper-slide h-auto">
  <article class="card h-100 border-0 bg-transparent">
    <div class="position-relative">
      <a  target="_blank" href="` +
          path +
          `?token=` +
          result[i].token_id +
          `"><img src="` +
          result[i].image_url +
          `" class="rounded-3" alt="Ape"></a>
    </div>
    <div class="card-body pb-0 px-0">
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
          tickets +
          `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

        <span class="fs-sm text-muted">#` +
          result[i].token_id +
          `</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
          "This Token is Listed" +
          `</span></div>
      <a class="fs-sm"  target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
          result[i].token_id +
          `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
    </div>
    </div>
  </article>
</div>`;
      } else {
        if (tier === "Not Revealed") {
          myDirectory.current.innerHTML +=
            `       <div class="container swiper-slide h-auto">
  <article class="card h-100 border-0 bg-transparent">
    <div class="position-relative">
      <a  target="_blank" href="` +
            path +
            `?token=` +
            result[i].token_id +
            `"><img src="` +
            result[i].image_url +
            `" class="rounded-3" alt="Ape"></a>
    </div>
    <div class="card-body pb-0 px-0">
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
            tickets +
            `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

        <span class="fs-sm text-muted">#` +
            result[i].token_id +
            `</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
            tier +
            `</span></div>
      <a class="fs-sm"  target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
            result[i].token_id +
            `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
    </div>
    </div>
  </article>
</div>`;
        } else {
          myDirectory.current.innerHTML +=
            `       <div class="container swiper-slide h-auto">
<article class="card h-100 border-0 bg-transparent">
  <div class="position-relative">
    <a  target="_blank" href="` +
            path +
            `?token=` +
            result[i].token_id +
            `"><img src="` +
            result[i].image_url +
            `" class="rounded-3" alt="Ape"></a>
  </div>
  <div class="card-body pb-0 px-0">
    <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
            tickets +
            `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

      <span class="fs-sm text-muted">#` +
            result[i].token_id +
            `</span>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
            fur +
            " / " +
            tier +
            `</span></div>
    <a class="fs-sm"  target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
            result[i].token_id +
            `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
  </div>
  </div>
</article>
</div>`;
        }
      }
    }

    getMyNFTsGenTotal(addr, totalTickets);
  }

  async function getMyNFTsRot(addr) {
    var totalTickets = 0;
    myDirectory.current.innerHTML = "";
    var asset_contract = "0xdfb29501b42f63a947ddc5249f185d6bcbe6986f";
    var path = "rota";
    var tickets = 1;

    let result = await getMyNFTsOS("rotanft", addr)
    result = await getMyListingsFromOS("rotanft", result)
    console.log(result)
    if (result.length === 0) {
      myDirectory.current.innerHTML = "You have no ROTA assets";
    }

    for (var i = 0; i < result.length; i++) {
      if (!result[i].listed) {
        var name = "N/A";
        var fur = "1:1";
        var tier = "White";

        try {
          name = result[i].traits.find((x) => x.trait_type === "Name").value;

          if (name.length > 0) {
            tickets = 10;
          } else {
            tickets = 1;
          }
        } catch (e) {
          tickets = 1;
        }

        try {
          fur =
            result[i].traits.find((x) => x.trait_type === "Fur").value + " Fur";

          if (fur === "Gold Fur") {
            tickets = 2;
          } else {
            if (tickets < 2) {
              tickets = 1;
            }
          }
        } catch (e) {
          if (tickets < 2) {
            tickets = 1;
          }
        }

        try {
          tier = result[i].traits.find((x) => x.trait_type === "Tier").value;
        } catch (e) {
          tier = "Not Revealed";
        }

        if (fur === "Gold Fur") {
          if (tier === "War Hero Tier") {
            if (tickets > 24) tickets = tickets;
            else tickets = 24;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 16) tickets = tickets;
            else tickets = 16;
          }

          if (tier === "Gold Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Silver Tier") {
            if (tickets > 6) tickets = tickets;
            else tickets = 6;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }
        } else {
          if (tier === "War Hero Tier") {
            if (tickets > 12) tickets = tickets;
            else tickets = 12;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Gold Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }

          if (tier === "Silver Tier") {
            if (tickets > 3) tickets = tickets;
            else tickets = 3;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 2) tickets = tickets;
            else tickets = 2;
          }

          if (tier === "Not Revealed") {
            if (tickets > 1) tickets = tickets;
            else tickets = 1;
          }
        }
      } else {
        console.log(result[i]);
        tickets = 0;
      }

      totalTickets += tickets;

      if (tickets === 0) {
        myDirectory.current.innerHTML +=
          `       <div class="container listed swiper-slide h-auto">
  <article class="card h-100 border-0 bg-transparent">
    <div class="position-relative">
      <a  target="_blank" href="` +
          path +
          `?token=` +
          result[i].identifier +
          `"><img src="` +
          result[i].image_url +
          `" class="rounded-3" alt="Ape"></a>
    </div>
    <div class="card-body pb-0 px-0">
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
          tickets +
          `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

        <span class="fs-sm text-muted">#` +
          result[i].identifier +
          `</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
          "This Token is Listed" +
          `</span></div>
      <a class="fs-sm"  target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
          result[i].identifier +
          `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
    </div>
    </div>
  </article>
</div>`;
      } else {
        if (tier === "Not Revealed") {
          myDirectory.current.innerHTML +=
            `       <div class="container swiper-slide h-auto">
  <article class="card h-100 border-0 bg-transparent">
    <div class="position-relative">
      <a  target="_blank" href="` +
            path +
            `?token=` +
            result[i].identifier +
            `"><img src="` +
            result[i].image_url +
            `" class="rounded-3" alt="Ape"></a>
    </div>
    <div class="card-body pb-0 px-0">
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
            tickets +
            `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

        <span class="fs-sm text-muted">#` +
            result[i].identifier +
            `</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
            tier +
            `</span></div>
      <a class="fs-sm"  target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
            result[i].identifier +
            `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
    </div>
    </div>
  </article>
</div>`;
        } else {
          myDirectory.current.innerHTML +=
            `       <div class="container swiper-slide h-auto">
<article class="card h-100 border-0 bg-transparent">
  <div class="position-relative">
    <a  target="_blank" href="` +
            path +
            `?token=` +
            result[i].identifier +
            `"><img src="` +
            result[i].image_url +
            `" class="rounded-3" alt="Ape"></a>
  </div>
  <div class="card-body pb-0 px-0">
    <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="badge fs-sm text-nav bg-secondary text-decoration-none"><span class="active-tickets" style="font-size:18px">` +
            tickets +
            `</span>&nbsp;<img style="height:18px" src="tickets.png"/></div>

      <span class="fs-sm text-muted">#` +
            result[i].identifier +
            `</span>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="badge fs-sm text-nav  text-decoration-none"><span >` +
            fur +
            " / " +
            tier +
            `</span></div>
    <a class="fs-sm"  target="_blank" href="https://opensea.io/assets/ethereum/0x7cb90567a118cd9f6ca326067a0813b289bdcb54/` +
            result[i].identifier +
            `"><i className="bx"><img  style="height:20px" src="opensea.png"/></i></a>
  </div>
  </div>
</article>
</div>`;
        }
      }
    }

    getMyNFTsGenTotal(addr, totalTickets);

  }

  async function getMyNFTsRotTotal(
    addr,
    totalFromGen,
    total,
    listed
  ) {
    console.log(`Total Apes pre ROTA: ${total}`)
    var asset_contract = "0xdfb29501b42f63a947ddc5249f185d6bcbe6986f";
    var path = "rota";
    var tickets = 1;

    let result = await getMyNFTsOS("rotanft", addr);
    result = await getMyListingsFromOS("rotanft", result)


    for (var i = 0; i < result.length; i++) {
      total += 1;

      if (!result[i].listed) {
        var name = "N/A";
        var fur = "1:1";
        var tier = "White";

        try {
          name = result[i].traits.find((x) => x.trait_type === "Name").value;

          if (name.length > 0) {
            tickets = 10;
          } else {
            tickets = 1;
          }
        } catch (e) {
          tickets = 1;
        }

        try {
          fur =
            result[i].traits.find((x) => x.trait_type === "Fur").value +
            " Fur";

          if (fur === "Gold Fur") {
            tickets = 2;
          } else {
            if (tickets < 2) {
              tickets = 1;
            }
          }
        } catch (e) {
          if (tickets < 2) {
            tickets = 1;
          }
        }

        try {
          tier = result[i].traits.find((x) => x.trait_type === "Tier").value;
        } catch (e) {
          tier = "Not Revealed";
        }

        if (fur === "Gold Fur") {
          if (tier === "War Hero Tier") {
            if (tickets > 24) tickets = tickets;
            else tickets = 24;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 16) tickets = tickets;
            else tickets = 16;
          }

          if (tier === "Gold Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Silver Tier") {
            if (tickets > 6) tickets = tickets;
            else tickets = 6;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }
        } else {
          if (tier === "War Hero Tier") {
            if (tickets > 12) tickets = tickets;
            else tickets = 12;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Gold Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }

          if (tier === "Silver Tier") {
            if (tickets > 3) tickets = tickets;
            else tickets = 3;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 2) tickets = tickets;
            else tickets = 2;
          }

          if (tier === "Not Revealed") {
            if (tickets > 1) tickets = tickets;
            else tickets = 1;
          }
        }
      } else {
        tickets = 0;
        listed += 1;
      }
      totalFromGen += tickets;
    }
    if (listed > 0) {
      document.getElementById("tickets").innerHTML = totalFromGen;
      document.getElementById("boost").style.color = "#ef4444";
      document.getElementById("boost").innerHTML = "None";
      document.getElementById("total-apes").innerHTML = total;
      document.getElementById("listed-apes").innerHTML = listed;
    } else {
      document.getElementById("tickets").innerHTML = totalFromGen * 3;
      document.getElementById("boost").style.color = "#1ca44e";
      document.getElementById("boost").innerHTML = "Activated!";
      document.getElementById("boost-before-show").style.display = null;
      document.getElementById("boost-before").innerHTML = totalFromGen;
      document.getElementById("total-apes").innerHTML = total;
      document.getElementById("listed-apes").innerHTML = listed;
    }
  }

  async function buildRotaLoop(result) {
    var asset_contract = "0xdfb29501b42f63a947ddc5249f185d6bcbe6986f";
    var path = "rota";
    var tickets = 1;

    for (var i = 0; i < result.length; i++) {
      runningTotal += 1;

      if (result[i].seaport_sell_orders === null) {
        var name = "N/A";
        var fur = "1:1";
        var tier = "White";

        try {
          name = result[i].traits.find((x) => x.trait_type === "Name").value;

          if (name.length > 0) {
            tickets = 10;
          } else {
            tickets = 1;
          }
        } catch (e) {
          tickets = 1;
        }

        try {
          fur =
            result[i].traits.find((x) => x.trait_type === "Fur").value + " Fur";

          if (fur === "Gold Fur") {
            tickets = 2;
          } else {
            if (tickets < 2) {
              tickets = 1;
            }
          }
        } catch (e) {
          if (tickets < 2) {
            tickets = 1;
          }
        }

        try {
          tier = result[i].traits.find((x) => x.trait_type === "Tier").value;
        } catch (e) {
          tier = "Not Revealed";
        }

        if (fur === "Gold Fur") {
          if (tier === "War Hero Tier") {
            if (tickets > 24) tickets = tickets;
            else tickets = 24;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 16) tickets = tickets;
            else tickets = 16;
          }

          if (tier === "Gold Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Silver Tier") {
            if (tickets > 6) tickets = tickets;
            else tickets = 6;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }
        } else {
          if (tier === "War Hero Tier") {
            if (tickets > 12) tickets = tickets;
            else tickets = 12;
          }

          if (tier === "Legendary Tier") {
            if (tickets > 8) tickets = tickets;
            else tickets = 8;
          }

          if (tier === "Gold Tier") {
            if (tickets > 4) tickets = tickets;
            else tickets = 4;
          }

          if (tier === "Silver Tier") {
            if (tickets > 3) tickets = tickets;
            else tickets = 3;
          }

          if (tier === "Bronze Tier") {
            if (tickets > 2) tickets = tickets;
            else tickets = 2;
          }

          if (tier === "Not Revealed") {
            if (tickets > 1) tickets = tickets;
            else tickets = 1;
          }
        }
      } else {
        tickets = 0;
        runningListed += 1;
      }

      runningTotalGen += tickets;
    }
  }

  var runningTotalGen;
  var runningTotal;
  var runningListed;

  async function buildRotaTotal(
    response,
    totalFromGen,
    total,
    listed,
    multiple
  ) {
    runningTotalGen = totalFromGen;
    runningTotal = total;
    runningListed = listed;

    var asset_contract = "0xdfb29501b42f63a947ddc5249f185d6bcbe6986f";
    var path = "rota";
    var tickets = 1;

    let result;

    if (multiple) result = response;
    else result = response.data.assets;

    var ran = 0;

    if (multiple) {
      if (ran == 0) {
        for (var i = 0; i < response.length; i++) {
          await buildRotaLoop(response[i].data.assets);
        }

        document.getElementById("total-apes").innerHTML = runningTotal;
        document.getElementById("listed-apes").innerHTML = runningListed;

        if (runningListed > 0) {
          document.getElementById("tickets").innerHTML = runningTotalGen;
          document.getElementById("boost").style.color = "#ef4444";
          document.getElementById("boost").innerHTML = "None";
        } else {
          document.getElementById("tickets").innerHTML = runningTotalGen * 3;
          document.getElementById("boost").style.color = "#1ca44e";
          document.getElementById("boost").innerHTML = "Activated!";
          document.getElementById("boost-before-show").style.display = null;
          document.getElementById("boost-before").innerHTML = runningTotalGen;
        }
      }
      ran = 1;
    } else {
      for (var i = 0; i < result.length; i++) {
        total += 1;

        if (result[i].seaport_sell_orders === null) {
          var name = "N/A";
          var fur = "1:1";
          var tier = "White";

          try {
            name = result[i].traits.find((x) => x.trait_type === "Name").value;

            if (name.length > 0) {
              tickets = 10;
            } else {
              tickets = 1;
            }
          } catch (e) {
            tickets = 1;
          }

          try {
            fur =
              result[i].traits.find((x) => x.trait_type === "Fur").value +
              " Fur";

            if (fur === "Gold Fur") {
              tickets = 2;
            } else {
              if (tickets < 2) {
                tickets = 1;
              }
            }
          } catch (e) {
            if (tickets < 2) {
              tickets = 1;
            }
          }

          try {
            tier = result[i].traits.find((x) => x.trait_type === "Tier").value;
          } catch (e) {
            tier = "Not Revealed";
          }

          if (fur === "Gold Fur") {
            if (tier === "War Hero Tier") {
              if (tickets > 24) tickets = tickets;
              else tickets = 24;
            }

            if (tier === "Legendary Tier") {
              if (tickets > 16) tickets = tickets;
              else tickets = 16;
            }

            if (tier === "Gold Tier") {
              if (tickets > 8) tickets = tickets;
              else tickets = 8;
            }

            if (tier === "Silver Tier") {
              if (tickets > 6) tickets = tickets;
              else tickets = 6;
            }

            if (tier === "Bronze Tier") {
              if (tickets > 4) tickets = tickets;
              else tickets = 4;
            }
          } else {
            if (tier === "War Hero Tier") {
              if (tickets > 12) tickets = tickets;
              else tickets = 12;
            }

            if (tier === "Legendary Tier") {
              if (tickets > 8) tickets = tickets;
              else tickets = 8;
            }

            if (tier === "Gold Tier") {
              if (tickets > 4) tickets = tickets;
              else tickets = 4;
            }

            if (tier === "Silver Tier") {
              if (tickets > 3) tickets = tickets;
              else tickets = 3;
            }

            if (tier === "Bronze Tier") {
              if (tickets > 2) tickets = tickets;
              else tickets = 2;
            }

            if (tier === "Not Revealed") {
              if (tickets > 1) tickets = tickets;
              else tickets = 1;
            }
          }
        } else {
          tickets = 0;
          listed += 1;
        }

        totalFromGen += tickets;
      }

      document.getElementById("total-apes").innerHTML = total;
      document.getElementById("listed-apes").innerHTML = listed;


      if (listed > 0) {
        document.getElementById("tickets").innerHTML = totalFromGen;
        document.getElementById("boost").style.color = "#ef4444";
        document.getElementById("boost").innerHTML = "None";
      } else {
        document.getElementById("tickets").innerHTML = totalFromGen * 3;
        document.getElementById("boost").style.color = "#1ca44e";
        document.getElementById("boost").innerHTML = "Activated!";
        document.getElementById("boost-before-show").style.display = null;
        document.getElementById("boost-before").innerHTML = totalFromGen;
      }
    }
  }

  const reveal = () => {
    document.getElementById("show-tx").style.display = null;
    getLastWinner();
  };

  const revealPrize = () => {
    playConfettiPrize();
    document.getElementById("reveal-prize").style.display = "none";
    document.getElementById("reveal-prize-close").style.display = null;
    document.getElementById("reveal-image").style.display = null;

    document.getElementById("reveal-image-last").style.display = "none";
    document.getElementById("reveal-prize-last").style.display = null;
    document.getElementById("reveal-prize-close-last").style.display = "none";
  };

  const revealPrizeClose = () => {
    document.getElementById("reveal-image").style.display = "none";
    document.getElementById("reveal-prize").style.display = null;
    document.getElementById("reveal-prize-close").style.display = "none";
  };

  const revealPrizeLast = () => {
    document.getElementById("reveal-prize-last").style.display = "none";
    document.getElementById("reveal-prize-close-last").style.display = null;
    document.getElementById("reveal-image-last").style.display = null;

    document.getElementById("reveal-image").style.display = "none";
    document.getElementById("reveal-prize").style.display = null;
    document.getElementById("reveal-prize-close").style.display = "none";
  };

  const revealPrizeCloseLast = () => {
    document.getElementById("reveal-image-last").style.display = "none";
    document.getElementById("reveal-prize-last").style.display = null;
    document.getElementById("reveal-prize-close-last").style.display = "none";
  };

  return (
    <div className="page-wrapper">
      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a href="https://atsnft.io/" className="navbar-brand pe-3">
            <img src="ape.png" width={47} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item" id="admin" style={{ display: "none" }}>
                  <a
                    href="https://records.atsnft.io/admin"
                    className="nav-link text-nowra"
                  >
                    Admin
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://records.atsnft.io/"
                    className="nav-link text-nowra"
                  >
                    Records
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://marketplace.atsnft.io/"
                    target="_blank"
                    className="nav-link text-nowrap"
                  >
                    Marketplace
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://war.atsnft.io/"
                    className="nav-link text-nowrap"
                  >
                    War
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link text-nowrap active"
                    style={{ color: "#e9ca7a" }}
                  >
                    Tickets
                  </a>
                </li>
                <a
                  href="https://twitter.com/ATSNFT"
                  target="_blank"
                  className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"
                >
                  <i className="bx bxl-twitter"></i>
                </a>
                <a
                  href="https://discord.com/invite/ATSNFT"
                  target="_blank"
                  className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"
                >
                  <i className="bx bxl-discord"></i>
                </a>
              </ul>
            </div>
            <div className="offcanvas-header border-top">
              <ConnectWallet
                btnTitle="Log In"
                theme="dark"
              />
            </div>
          </div>
          <ConnectWallet
            btnTitle="Log In"
            theme="dark"
          />
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </header>
      {isConfettiPlayingPrize && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={1000}
          recycle={false}
        />
      )}
      <br></br>
      <br></br>

      <main id="down" style={{ display: "none" }}>
        <section className="container d-md-flex align-items-center justify-content-center">

          <h2 tyle={{ fontSize: "48px", textAlign: "center" }}>ApeTickets is currently down for maintenance</h2>
        </section>

        <section className="container text-align-center d-md-flex align-items-center justify-content-center">

          <p style={{ fontSize: "24px", textAlign: "center" }}>Please check socials for updates</p>

        </section>
        <section className="container text-align-center d-md-flex align-items-center justify-content-center">

          <p style={{ fontSize: "12px", textAlign: "center" }}>Down Since: 6:45pm EST 6/19/2023</p>

        </section>
        <section className="container text-align-center d-md-flex align-items-center justify-content-center">

          <img src="ape.png" width={300} alt="Logo" />

        </section>
      </main>

      <main id="up" >

        <section className="container d-md-flex align-items-center justify-content-between">

          <ul className="nav nav-tabs-alt flex-nowrap border-0">
            <li className="nav-item" id="check-connect">
              <ConnectWallet
                btnTitle="Log In"
                theme="dark"
              />
            </li>
            <li className="nav-item">
              <button
                id="myposition"
                type="button"
                className="btn btn-info bg-faded-primary btn-sm fs-sm rounded "
                style={{ display: "none" }}
              >
                Yours:&nbsp;
                <span className="active-tickets" id="tickets">
                  <span className="text-loading">...</span>
                </span>
                &nbsp;
                <img style={{ height: "15px" }} src="tickets.png" />
              </button>
              <button
                type="button"
                style={{ marginLeft: "15px" }}
                className="btn btn-info bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex "
              >
                Total:&nbsp;
                <span className="active-tickets" id="total-tickets">
                  0
                </span>
                &nbsp;
                <img style={{ height: "15px" }} src="tickets.png" />
              </button>
            </li>
          </ul>


          <ul className="nav nav-tabs-alt flex-nowrap border-0">
            <li className="nav-item">
              <button onClick={openModal} className="btn  btn-sm fs-sm rounded" style={{ backgroundColor: "white", color: "black" }}>What is ApeTickets?</button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div style={{
                  backgroundColor: "black"
                }}>

                  <div style={{ margin: "20px" }}>
                    <p >ApeTickets is a hold-and-enter reward system for holders of Apes Together Strong (ATS) and Rise of the Apes (ROTA).</p>
                    <p >Simply hold your delisted apes, and automatically earn tickets every week that enter you into raffles!</p>
                    <p style={{ margin: "0" }}>No paying for gas to stake.</p>
                    <p style={{ margin: "0" }}>No apes leaving your wallet to stake.</p>
                    <p>No accumulation of useless tokens.</p>
                    <p>The ApeTickets website (on ApeRecords) announces the winner and resets to a new prize every week on Monday at 4:20PM EST.</p>
                    <p>To check your ticket allocation, connect your wallet, and view your tickets at the top left.</p>

                    <a href="https://opensea.io/collection/atsnft" style={{ textDecoration: "none", cursor: "pointer", color: "white" }} target="_blank"><p style={{ fontWeight: "800", margin: "0" }}>Apes Together Strong (ATS) Tickets</p></a>
                    <p style={{ margin: "0" }}>🎟️ 15 Ticket(s) = OG ATS 1:1's</p>
                    <p style={{ margin: "0" }}>🎟️ 3 Ticket(s) = OG ATS</p>
                    <p >Gold Skins act as a 2x multiplier!</p>

                    <a href="https://opensea.io/collection/rotanft" style={{ textDecoration: "none", cursor: "pointer", color: "white" }} target="_blank"><p style={{ fontWeight: "800", margin: "0" }}>Rise of the Apes (ROTA) Tickets</p></a>
                    <p style={{ margin: "0" }}>🎟️ 12 Ticket(s) = War Hero Border</p>
                    <p style={{ margin: "0" }}>🎟️ 10 Ticket(s) = ROTA 1:1</p>
                    <p style={{ margin: "0" }}>🎟️ 8 Ticket(s) = Legendary Border</p>
                    <p style={{ margin: "0" }}>🎟️ 4 Ticket(s) = Gold Border</p>
                    <p style={{ margin: "0" }}>🎟️ 3 Ticket(s) = Silver Border</p>
                    <p style={{ margin: "0" }}>🎟️ 2 Ticket(s) = Bronze Border</p>
                    <p style={{ margin: "0" }}>🎟️ 1 Ticket(s) = White Border</p>
                    <p >Gold Skins act as a 2x multiplier!</p>

                    <p style={{ fontWeight: "800", margin: "0" }}>We've added some new features with V2 that include:</p>

                    <p style={{ margin: "0" }}>✨ 3x multiplier for wallets with zero listed apes!</p>

                    <p >✨ You can send your ROTA's to our "War on Apes" application, and still earn tickets!</p>

                    <p ><span style={{ fontWeight: "800" }}>NOTE:</span> Listing one or more apes does not blacklist an entire wallet from earning tickets. It restricts only those listed ape(s) from earning tickets. We don't sell shame or restrict holders from selling. Instead, we want to reward holders for not listing.
                    </p>
                    <button onClick={closeModal} className="btn btn-success bg-faded-primary btn-sm fs-sm rounded" style={{ marginBottom: "15px" }}>Close</button>
                    <br></br>
                  </div>
                </div>
              </Modal>

            </li>
          </ul>

        </section>

        <section className="container align-items-center justify-content-between">
          <ul className="nav nav-tabs-alt flex-nowrap border-0">
            <li className="nav-item">
              <a style={{ textDecoration: "none" }}>
                <p style={{ textAlign: "left" }}>
                  Next Drawing:&nbsp;
                  <span className="active-tickets" id="next-drawing">
                    <span className="text-loading">...</span>
                  </span>
                </p>
              </a>
              <div id="show-apes" style={{ display: "none" }}>
                <p>
                  Total Apes: <span id="total-apes">0</span>
                </p>
                <p>
                  Total Listed Apes: <span id="listed-apes">0</span>
                </p>
                <p>
                  No List Boost (3x): <span id="boost"></span>
                </p>
                <p id="boost-before-show" style={{ display: "none" }}>
                  Tickets Before Boost: <span id="boost-before"></span>
                </p>
              </div>
            </li>
          </ul>
        </section>

        <section className="container d-md-flex align-items-center justify-content-between">
          <ul className="nav nav-tabs-alt flex-nowrap border-0">
            <li className="nav-item">

              <button
                onClick={reveal}
                id="reveal"
                type="button"
                className="btn btn-success bg-faded-primary btn-sm fs-sm rounded"
                style={{ marginRight: "5px", display: "none", maxWidth: "90px" }}
              >
                Last Winner
              </button>
              <a id="reveal-prize-last" target="_blank">
                <button
                  onClick={revealPrizeLast}
                  id="reveal-prize-button-last"
                  type="button"
                  className="btn btn-success bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex "
                  style={{ marginRight: "5px", maxWidth: "105px" }}
                >
                  Last Prize <span id="current-winners-last"></span>
                </button>
              </a>
              <a
                id="reveal-prize-close-last"
                target="_blank"
                style={{ display: "none" }}
              >
                <button
                  onClick={revealPrizeCloseLast}
                  id="reveal-prize-button-last"
                  type="button"
                  className="btn btn-danger bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex "
                  style={{ marginRight: "5px", maxWidth: "105px" }}
                >
                  Close
                </button>
              </a>
              <a id="reveal-prize" target="_blank">
                <button
                  onClick={revealPrize}
                  id="reveal-prize-button"
                  type="button"
                  className="btn btn-success bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex "
                  style={{ maxWidth: "105px" }}
                >
                  Next Prize <span id="current-winners"></span>
                </button>
              </a>
              <a
                id="reveal-prize-close"
                target="_blank"
                style={{ display: "none" }}
              >
                <button
                  onClick={revealPrizeClose}
                  id="reveal-prize-button"
                  type="button"
                  className="btn btn-danger bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex "
                  style={{ maxWidth: "105px" }}
                >
                  Close
                </button>
              </a>
            </li>
          </ul>

        </section>

        <section
          className="container  align-items-center justify-content-between"
          style={{ display: "none" }}
          id="show-tx"
        >
          <ul
            className="nav nav-tabs-alt flex-nowrap border-0"
            style={{ marginTop: "-20px" }}
          >
            <li className="nav-item">
              <div
                id="show-winners"
                style={{ display: "none" }}
              >
              </div>
              {/* <a
              id="reveal-tx"
              target="_blank"
              href="https://etherscan.io/tx/0x1793667b37f4dae7fdb336b8e9810c026ed913e364f6f8b577ed1f0f198a0835"
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              Prize Tx Hash
            </a> */}
            </li>
          </ul>
        </section>

        <section
          id="connect-wallet"
          className="container align-items-center justify-content-between"
        ></section>

        <section id="reveal-image" className="container" style={{ display: "none", flexWrap: "wrap" }}>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {rewards.map((reward, index) => (
              <div key={index} style={{ margin: "10px" }}>
                <h3 className="lead mb-3" style={{ textAlign: "center", marginTop: "15px" }}>{reward.name}</h3>
                <img
                  src={reward.image}
                  alt={reward.name}
                  style={{ height: "350px", marginBottom: "15px", borderRadius: "10px" }}
                />
              </div>
            ))}
          </div>
        </section>

        <section id="reveal-image-last" className="container" style={{ display: "none", flexWrap: "wrap" }}>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {rewardsLast.map((reward, index) => (
              <div key={index} style={{ margin: "10px" }}>
                <h3 className="lead mb-3" style={{ textAlign: "center", marginTop: "15px" }}>{reward.name}</h3>
                <img
                  src={reward.image}
                  alt={reward.name}
                  style={{ height: "350px", marginBottom: "15px", borderRadius: "10px" }}
                />
              </div>
            ))}
          </div>
        </section>

        <section
          id="show-buttons"
          style={{ display: "none" }}
          className="align-items-center justify-content-between"
        >
          <nav className="container">
            <ul className="nav nav-tabs-alt flex-nowrap border-0">
              <li
                className="nav-item active-button"
                id="gen-button"
                style={{ marginRight: "15px" }}
              >
                <a
                  onClick={gen}
                  id="gen"
                  className="nav-link bg-secondary text-nowrap active-tickets tickets"
                >
                  <img
                    style={{ maxHeight: "30px", marginRight: "15px" }}
                    src="ape-white.png"
                  ></img>
                  Genesis
                </a>
              </li>
              <li className="nav-item" id="rot-button">
                <a
                  onClick={rot}
                  id="rot"
                  className="nav-link bg-secondary text-nowrap tickets"
                >
                  <img
                    style={{ maxHeight: "30px", marginRight: "15px" }}
                    src="ape.png"
                  ></img>
                  ROTA
                </a>
              </li>
            </ul>
          </nav>
        </section>

        <div className="container py-5">
          <section
            ref={myDirectory}
            className="row row-cols-lg-4 row-cols-sm-2 row-cols-1 gy-md-4 gy-2"
          ></section>
        </div>

      </main>

    </div>
  );
}

export default App;
