import '../App.css';

import {
  useEffect,
  useState,
  useRef
} from 'react';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';
import {
  ethers
} from 'ethers';

import {
  useAddress,
  ConnectWallet
} from "@thirdweb-dev/react";


function App() {

  const [addr, setaddr] = useState('')


  const address = useAddress();

  useEffect(() => {
    if (address) {
      setaddr(address);
    }
  }, [address]);


  const previousAddressRef = useRef();
  useEffect(() => {
    previousAddressRef.current = address;
  }, [address]);
  const previousAddress = previousAddressRef.current;

  useEffect(() => {
    if (previousAddress && !address) {
      window.location.reload();
    }
  }, [address, previousAddress]);


  return (
    <div className="page-wrapper"  >
      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a href="https://atsnft.io/" className="navbar-brand pe-3">
            <img src="ape.png" width={47} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a href="https://records.atsnft.io/" className="nav-link text-nowrap active" style={{ color: "#e9ca7a" }}>Records</a>
                </li>
                <li className="nav-item">
                  <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://war.atsnft.io/"
                    className="nav-link text-nowrap"
                  >
                    War
                  </a>
                </li>
                <li className="nav-item">
                  <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
                </li>
                <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
                <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
              </ul>
            </div>
            <div className="offcanvas-header border-top">
              <ConnectWallet
                btnTitle="Log In"
                theme="dark"
              />
            </div>
          </div>
          <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <ConnectWallet
            btnTitle="Log In"
            theme="dark"
          />
        </div>
      </header>

      <section className="container d-md-flex align-items-center justify-content-between pb-3 py-5"  >
        <nav className="container">
          <div className="d-flex justify-content-left pt-4 py-5" >
            <a id="back" href="/rota">
              <button type="button" className="btn btn-success bg-faded-primary btn-sm fs-sm rounded d-lg-inline-flex" style={{ marginRight: "10px" }} >Go Back</button>
            </a>
          </div>

        </nav>
      </section>


      <div className="container" >
        <section className="container">
          <div style={{ backgroundColor: "#151822", borderRadius: "25px" }} className=" rounded-3 overflow-hidden">
            <div className="row align-items-center">
              <div className="text-center text-md-start">
                <p className="lead mb-3 px-3">Success! <span className='active-tickets' id="ape-number"></span></p>
                <h2 className="h1 pb-3 pb-sm-4 px-3">You successfully submitted a story for your ROTA.</h2>
              </div>


            </div>
          </div>
        </section>
      </div>

    </div>




  );
}

export default App;